import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
// import { FaRegFilePdf } from 'react-icons/fa';
import './ModuleListPage.scss';
import AddNewModuleModal from "./AddNewModuleModal";
import UpdateModuleModal from "./UpdateModuleModal";
import DeleteModal from "../../../components/Modal/DeleteModal";
import toast from "react-hot-toast";

const activityURL = `${process.env.REACT_APP_API_BASE_URL}/activity`;
const moduleURL = `${process.env.REACT_APP_API_BASE_URL}/module`;

const ModuleListPage = () => {

    const [data, setData] = useState([]);
    const [activity, setActivity] = useState([]);
    const [modalOpen, setModalOpen] = useState('');
    const [selectedDataId, setSelectedDataId] = useState('');
    const [refresh, setRefresh] = useState(false);
    // console.log(data)

    const columns = [
        {
            key: 'name',
            text: 'Name',
            align: 'left',
            sortable: true,
        },
        {
            key: 'info',
            text: 'Info',
            className: 'info',
            align: 'left',
            sortable: true,
        },
        {
            key: 'activity',
            text: 'Activity',
            align: 'center',
            width: '20%',
            cell: data => {
                const filteredActivity = activity.filter(item => data.activities.some(ac => ac.id === item.id)).map(item => item.name).join(', ');

                // console.log(filteredActivity);
                return <span>{filteredActivity}</span>

            }
        },
        {
            key: 'status',
            text: 'Status',
            className: 'status',
            sortable: true,
            cell: (data) => {
                return <>{data?.status === 1 ? 'active' : 'inactive'}</>;
            },
        },
        {
            key: 'action',
            text: 'Action',
            className: 'action',
            width: 100,
            align: 'left',
            sortable: false,
            cell: (data) => {
                return (
                    <>
                        {/*<Toaster position='bottom-center' reverseOrder={false}/>*/}
                        <div className='col'>
                            {/* Button trigger modal */}
                            <button
                                type='button'
                                className='btn btn-primary btn-sm'
                                style={{marginRight: '5px'}}
                                onClick={() => {
                                    setSelectedDataId(data.id);
                                    setModalOpen('update');
                                }}
                            >
                                <i className='fa fa-edit'></i>
                            </button>

                        </div>
                        <div className='col'>
                            {/* Button trigger modal */}
                            <button
                                type='button'
                                className='btn btn-danger btn-sm'
                                style={{marginRight: '5px'}}
                                onClick={() => {
                                    setSelectedDataId(data.id);
                                    setModalOpen('delete');
                                }}
                            >
                                <i className='fa fa-trash'></i>
                            </button>

                        </div>
                    </>
                );
            },
        },
    ];

    const config = {
        page_size: 10,
        show_filter: true,
        show_length_menu: true,
        show_pagination: true,
        pagination: 'advance',
        length_menu: [10, 50, 100],
        button: {
            excel: true,
            print: true,
            extra: true,
        },
    };


    // delete modules
    const deleteData = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_BASE_URL}/module/${selectedDataId}`)
            .then((response) => {
                toast.success("Module deleted successfully");
                setModalOpen('');
                setRefresh(prevState => !prevState)
            })
            .catch((error) => console.log(error));

    };


    const fetchModule = () => {
        axios
            .get(moduleURL)
            .then((response) => {
                setData(response.data.data);
            })
            .catch((err) => console.log(err));
    };

    const fetchActivity = () => {
        axios
            .get(activityURL)
            .then((response) => setActivity(response.data.data))
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        fetchModule();
        fetchActivity();
        // console.log(form);
    }, [refresh]);

    const extraButtons = [
        // {
        //     className:"btn btn-primary buttons-pdf",
        //     title:"Export TEst",
        //     children:[
        //         <span>
        //         <FaRegFilePdf/>
        //         </span>
        //     ],
        //     onClick:(event)=>{
        //         console.log(event);
        //     },
        // },
        // {
        //     className:"btn btn-primary buttons-pdf",
        //     title:"Export TEst",
        //     children:[
        //         <span>
        //         <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
        //         </span>
        //     ],
        //     onClick:(event)=>{
        //         console.log(event);
        //     },
        //     onDoubleClick:(event)=>{
        //         console.log("doubleClick")
        //     }
        // }
    ];

    return (
        <div className='card'>
            <div className='card-body'>
                <div className='border p-3 rounded'>
                    <div className='card-box'>
                        <h6 className='mb-0 text-uppercase'>Module List</h6>
                        <div className='col'>
                            {/* Button trigger modal */}
                            <button
                                type='button'
                                className='btn btn-primary'
                                onClick={() => setModalOpen('create')}
                            >
                                <i className='fa-solid fa-plus'></i> Add New
                            </button>


                        </div>
                    </div>

                    <hr/>

                    <ReactDatatable
                        config={config}
                        records={data}
                        columns={columns}
                        extraButtons={extraButtons}
                    />
                </div>
            </div>

            {modalOpen === 'create'
                &&
                <
                    AddNewModuleModal
                    isOPen={modalOpen === 'create'}
                    onClose={() => setModalOpen('')}
                    activity={activity}
                    refresh={() => setRefresh(prevState => !prevState)}
                />
            }

            {modalOpen === 'update'
                &&
                <
                    UpdateModuleModal
                    isOPen={modalOpen === 'update'}
                    onClose={() => setModalOpen('')}
                    activity={activity}
                    selectedData={data.find(item => item.id === selectedDataId)}
                    refresh={() => setRefresh(prevState => !prevState)}
                />
            }

            {modalOpen === 'delete'
                &&
                <
                    DeleteModal
                    isOpen={modalOpen === 'delete'}
                    onClose={() => setModalOpen('')}
                    handleDelete={deleteData}
                />
            }

        </div>
    );
};

export default ModuleListPage;
