import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Tabs } from 'react-bootstrap';
import { Tab } from 'bootstrap';
import CardLayout from '../../components/Layout/CardLayout';
import useFetch from '../../hooks/useFetch';
import request from '../../request/request';
import LoaderLayout from '../../components/Loader/LoaderLayout';
import BranchDetailsData from '../../components/Branch/BranchDetailsData';
import FoodListByBranch from '../../Routers/FoodList/FoodListByBranch';
import BranchTransection from '../../components/Branch/BranchTransection';

const BranchDetails = () => {
    const { id } = useParams();

    const [activeTab, setActiveTab] = useState('branch_details');

    // fetch product details
    const { result, isLoading } = useFetch(() =>
        request.getOne(
            'branch-list',
            `${id}?include=user,city,state,country,foodCourt,table,restaurant`
        )
    );

    return (
        <CardLayout title='Branch Details'>
            <Tabs
                defaultActiveKey='branch_details'
                variant='tabs'
                onSelect={(name) => setActiveTab(name)}
            >
                <Tab
                    eventKey='branch_details'
                    title='Branch Details'
                    tabClassName={`${
                        activeTab === 'branch_details'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    <LoaderLayout isPending={isLoading}>
                        <BranchDetailsData detailsData={result} />
                    </LoaderLayout>
                </Tab>

                <Tab
                    eventKey='food_list'
                    title='Food List'
                    tabClassName={`${
                        activeTab === 'food_list'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    <FoodListByBranch />
                </Tab>

                <Tab
                    eventKey='table_list'
                    title='Table List'
                    tabClassName={`${
                        activeTab === 'table_list'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    {/* <BranchTableShow /> */}
                </Tab>

                <Tab
                    eventKey='order_list'
                    title='Order List'
                    tabClassName={`${
                        activeTab === 'order_list'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    {/* <BranchTableShow /> */}
                </Tab>

                <Tab
                    eventKey='transection'
                    title='Transection List'
                    tabClassName={`${
                        activeTab === 'transection'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    <BranchTransection />
                </Tab>
            </Tabs>
        </CardLayout>
    );
};

export default BranchDetails;
