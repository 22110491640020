import { FieldArray, useFormikContext } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import ReactTextEditor from '../../../Editors/ReactTextEditor';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import FormLabel from '../../../Text/FormLabel';
import IconListAdd from './IconListAdd';
import QuickLinkList from './QuickLinkForm';
import BottomBarForm from './BottomBarForm';

const FooterForm = () => {
    const { values, setFieldValue } = useFormikContext();
    return (
        <Row>
            <Col sm={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Logo' required />
                    <ReactInput name='logo' type='file' accept='image/*' />
                </Form.Group>
            </Col>

            <Col sm={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Email' required />
                    <ReactInput name='email' type='email' />
                </Form.Group>
            </Col>

            <Col sm={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Mobile' required />
                    <ReactInput name='mobile' />
                </Form.Group>
            </Col>

            <Col sm={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Address' required />
                    <ReactInput name='address' as='textarea' rows={3} />
                </Form.Group>
            </Col>

            <Col sm={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Description' required />
                    <ReactInput name='description' as='textarea' rows={3} />
                </Form.Group>
            </Col>

            <Col sm={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Latitude & Langtitude' />
                    <ReactInput name='latlng' placeholder='e.g. 23.7777, 90.3333' />
                </Form.Group>
            </Col>

            <Col sm={12} className='mt-3'>
                <FieldArray
                    name='iconData'
                    render={(arrayHelpers) => <IconListAdd arrayHelpers={arrayHelpers} />}
                />
            </Col>

            <Col sm={12} className='mt-3'>
                <FieldArray
                    name='quickLinkData'
                    render={(arrayHelpers) => <QuickLinkList arrayHelpers={arrayHelpers} />}
                />
            </Col>

            <Col sm={12}>
                <FormLabel title='Quick Contact Info' />
                <ReactTextEditor
                    defaultData={values.contact_info}
                    setEditorData={(val) => setFieldValue('contact_info', val)}
                />
            </Col>

            {/* copyright section  */}

            <hr className='my-3' />

            <Col sm={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Copyright' required />
                    <ReactTextEditor
                        defaultData={values.copyright}
                        setEditorData={(val) => setFieldValue('copyright', val)}
                    />

                    {/* <ReactInput name='copyright' as='textarea' rows={4} /> */}
                </Form.Group>
            </Col>

            <Col sm={6} className='mt-3'>
                <FieldArray
                    name='bottomData'
                    render={(arrayHelpers) => <BottomBarForm arrayHelpers={arrayHelpers} />}
                />
            </Col>
        </Row>
    );
};

export default FooterForm;
