import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import FormLabel from '../../Text/FormLabel';
import ReactInput from '../../InputList/ReactInput/ReactInput';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ReactSelect from '../../ReactSelect/ReactSelect';
import SelectCountry from '../../InputList/SelectableInput/SelectCountry';
import SelectState from '../../InputList/SelectableInput/SelectState';
import SelectCity from '../../InputList/SelectableInput/SelectCity';
import StatusCheckField from '../StatusCheckField';
import SelectUserByRole from '../../InputList/SelectableInput/SelectUserByRole';
import SelectFoodCourt from '../../InputList/SelectableInput/SelectFoodCourt';
import ReactDatePicker from '../../ReactDatePicker/ReactDatePicker';

const FoodCourtAndRestaurantForm = ({ form }) => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <Row>
            {/* {values.form !== 'foodCourt' && ( */}
            {/*     <Col sm={12} md={6} className='mt-3'> */}
            {/*         <Form.Group> */}
            {/*             <FormLabel title='Food Court' /> */}
            {/*             <SelectFoodCourt name='food_court_id' /> */}
            {/*         </Form.Group> */}
            {/*     </Col> */}
            {/* )} */}
            <Col sm={12} md={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Select User' required />
                    <SelectUserByRole
                        name='user_id'
                        role_id={values.form === 'foodCourt' ? '7' : '8'}
                    />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel
                        title={values.form === 'foodCourt' ? 'Food Court Name' : 'Restaurant Name'}
                        required
                    />
                    <ReactInput name='name' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Mobile Number' required />
                    <ReactInput name='mobile' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Contact Person' required />
                    <ReactInput name='contact_person' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Contact Number' />
                    <ReactInput name='contact_number' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Country' required />
                    <SelectCountry name='country_id' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='State' required />
                    <SelectState name='state_id' dependedCountryValueId='country_id' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='City' required />
                    <SelectCity name='city_id' dependedStateValueId='state_id' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel
                        title={values.form === 'foodCourt' ? 'Address' : 'Head Office address'}
                        required
                    />
                    <ReactInput as='textarea' row={3} name='address' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Logo ' />
                    <ReactInput type='file' name='logo' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Banner ' />
                    <ReactInput type='file' name='banner' />
                </Form.Group>
            </Col>
            {form === 'foodCourt' && (
                <>
                    <Col sm={12} md={6}>
                        <Form.Group className='mt-3'>
                            <FormLabel title='Opening Time ' />
                            <ReactDatePicker
                                showTimeSelectOnly
                                showTimeSelect
                                timeIntervals={15}
                                dateFormat=' h:mm aa'
                                value={values.opening_time}
                                onChange={(date) => setFieldValue('opening_time', date)}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={12} md={6}>
                        <Form.Group className='mt-3'>
                            <FormLabel title='Closing Time' />
                            <ReactDatePicker
                                showTimeSelectOnly
                                showTimeSelect
                                timeIntervals={15}
                                dateFormat=' h:mm aa'
                                value={values.closing_time}
                                onChange={(date) => setFieldValue('closing_time', date)}
                            />
                        </Form.Group>
                    </Col>
                </>
            )}

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <StatusCheckField />
                </Form.Group>
            </Col>
        </Row>
    );
};
export default FoodCourtAndRestaurantForm;
