import React from 'react';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import InputGroup from './InputGroup';
import ReactDatePicker from '../../ReactDatePicker/ReactDatePicker';

const UserAdditionalInfoForm = () => {
    const { values, setFieldValue } = useFormikContext();
    const InputFormData = [
        {
            id: 10,
            label: 'Profile Photo : ',
            name: 'image',
            type: 'file',
        },
        {
            id: 1,
            label: 'Date of Birth : ',
            name: 'date_of_birth',
            type: 'select',
            component: (
                <ReactDatePicker
                    value={new Date(values.date_of_birth)}
                    onChange={(val) => setFieldValue('date_of_birth', val)}
                />
            ),
        },
        {
            id: 2,
            label: 'NID No :',
            name: 'nid',
        },
        {
            id: 3,
            label: 'Remarks',
            name: 'remarks',
        },
        {
            id: 4,
            label: 'Creadit Limit : (BDT)',
            name: 'credit_limit',
        },
        {
            id: 5,
            label: 'Commistion Rate (%)',
            name: 'commission_rate',
        },
    ];

    return (
        <Row>
            <InputGroup InputData={InputFormData} />
        </Row>
    );
};

export default UserAdditionalInfoForm;
