import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useContextData from '../../../../hooks/useContextData';
import request from '../../../../request/request';
import CardLayout from '../../../../components/Layout/CardLayout';
import UserFormContainer from '../../../../components/Forms/Users/UserFormContainer';
import useFetch from '../../../../hooks/useFetch';

const AddUserByRole = () => {
    const { currentUser } = useContextData();
    const { role_id } = useParams();
    const navigate = useNavigate();

    const { result: role } = useFetch(() => request.getOne('roles/role', role_id), role_id);

    const createNewUserHandler = async (formValues) => {
        const { image, signature } = formValues;

        let pro_image = null;
        let pro_signature = null;

        // image upload
        if (image) {
            console.log(typeof image);
            const formData = new FormData();
            formData.append('image', image);

            console.log(formData);

            const { data, status } = await request.create('users/user/upload-image', formData);

            if (status === 'OK') {
                pro_image = data.filename;
            }

            console.log(data);
        }

        // signature upload

        if (signature) {
            const formData = new FormData();
            formData.append('image', signature);
            console.log(formData);

            const { data, status } = await request.create('users/user/upload-image', formData);

            if (status === 'OK') {
                pro_signature = data.filename;
            }
        }

        const uploadData = {
            ...formValues,
            image: pro_image,
            signature: pro_signature,
            password: '12345',
            created_by: currentUser?.id,
        };

        return request.create('user/signup', uploadData, () => {
            navigate(`/dashboard/user/roles/${role_id}`);
        });
    };

    return (
        <CardLayout title={`Add new ${role?.name || 'User'}`}>
            <UserFormContainer apiHandler={createNewUserHandler} />
        </CardLayout>
    );
};

export default AddUserByRole;
