import { Col, Form, Row } from "react-bootstrap";
import { useFormikContext } from "formik";
import FormLabel from "../../../Text/FormLabel";
import ReactInput from "../../../InputList/ReactInput/ReactInput";
import ReactTextEditor from "../../../Editors/ReactTextEditor";
import StatusCheck from "../../StatusCheck";

const AboutUsForm = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <Row>
      <Col sm={12} md={6}>
        <Form.Group className="mt-3">
          <FormLabel title="Title" required />
          <ReactInput name="title" />
        </Form.Group>
      </Col>
      <Col sm={12} md={6}>
        <Form.Group className="mt-3">
          <FormLabel title="Side Image" required />
          <ReactInput name="side_img" type="file" />
        </Form.Group>
      </Col>

      <Col sm={12} md={6}>
        <Form.Group className="mt-3">
          <FormLabel title="Middle Images" required />
          <ReactInput name="middle_img" type="file" />
        </Form.Group>
      </Col>

      <Col sm={12} md={6}>
        <Form.Group className="mt-3">
          <FormLabel title="Circle Images" required />
          <ReactInput name="circle_img" type="file" />
        </Form.Group>
      </Col>

      <Col sm={12} md={6}>
        <Form.Group className="mt-3">
          <FormLabel title="Button Title" required />
          <ReactInput name="button_title" />
        </Form.Group>
      </Col>

      <Col sm={12} md={6}>
        <Form.Group className="mt-3">
          <FormLabel title="Button Link" required />
          <ReactInput name="button_link" />
        </Form.Group>
      </Col>

      <Col sm={12}>
        <Form.Group className="mt-3">
          <FormLabel title="Title" required />
          <ReactTextEditor
            defaultData={values?.description}
            setEditorData={(val) => setFieldValue("description", val)}
          />
        </Form.Group>
      </Col>

      <div>
        <StatusCheck />
      </div>
    </Row>
  );
};

export default AboutUsForm;
