import { Form } from 'react-bootstrap';
import CrudFormLayout from '../../../Layout/CrudFormLayout';
import FormLabel from '../../../Text/FormLabel';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import StatusCheck from '../../StatusCheck';

const DestinationForm = ({ apiHandler, editData }) => {
    const initialValues = {
        country_name: editData?.country_name || '',
        country_img: editData?.country_img || '',
        success_count: editData?.success_count || '',
        description: editData?.description || '',
        status: editData ? editData?.status : 1,
    };

    const formHandler = (formValue) => {
        if (apiHandler) {
            return apiHandler(formValue);
        }

        console.log(formValue);
        return null;
    };
    return (
        <CrudFormLayout initialValues={initialValues} apiHandler={formHandler}>
            <Form.Group className='mt-3'>
                <FormLabel title='Country Name' required />

                <ReactInput name='country_name' />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Country Image' required />

                <ReactInput name='country_img' type='file' />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Success Count' required />

                <ReactInput name='success_count' type='number' />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Description' required />

                <ReactInput name='description' as='textarea' rows={6} />
            </Form.Group>

            <StatusCheck />
        </CrudFormLayout>
    );
};

export default DestinationForm;
