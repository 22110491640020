import * as Yup from 'yup';
import CrudFormLayout from '../../Layout/CrudFormLayout';
import CouponForm from './CouponForm';

const CouponFormContainer = ({ apiHandler, editData }) => {
    const couponFormSchema = Yup.object().shape({
        coupon_code: Yup.string().required('Coupon code is required'),
        coupon_type: Yup.string()
            .oneOf(['fixed', 'percentage'])
            .required('Coupon type is required'),
        value: Yup.number().required('Value is required'),
        food_court_ids: Yup.array().of(Yup.string()).nullable(),
        branch_ids: Yup.array().of(Yup.string()).nullable(),
        description: Yup.string().nullable(),
        status: Yup.number().oneOf([0, 1]).required('Status is required'),
    });

    const initialValues = {
        coupon_code: editData?.coupon_code || '',
        coupon_type: editData?.coupon_type || 'fixed',
        value: editData?.value || '',
        food_court_ids: editData?.food_court_ids || null,
        branch_ids: editData?.branch_ids || null,
        description: editData?.description || '',
        status: editData?.status === 0 ? 0 : 1 || 1,
        default_food_court_data:
            editData?.foodCourt?.map((item) => ({ label: item.name, value: item.food_court_id })) ||
            null,
        default_branch_data:
            editData?.branchList?.map((item) => ({ label: item.name, value: item.branch_id })) ||
            null,
    };
    const onSubmitHandler = async (values) => {
        try {
            const validateData = await couponFormSchema.validate(values, { stripUnknown: true });
            // console.log(validateData);
            if (apiHandler) apiHandler(values);
            return validateData;
        } catch (err) {
            console.log(err);
            return err;
        }
    };
    console.log(editData);
    return (
        <div>
            <CrudFormLayout
                initialValues={initialValues}
                buttonCenter
                validationSchema={couponFormSchema}
                apiHandler={onSubmitHandler}
            >
                <CouponForm />
            </CrudFormLayout>
        </div>
    );
};

export default CouponFormContainer;
