import * as yup from 'yup';
import CrudFormLayout from '../../../Layout/CrudFormLayout';
import CoursesForms from './CourseForms';

const CoursesFormContainer = ({ apiHandler, editData }) => {
    const numberTransform = (value) => (Number.isNaN(value) ? 0 : value);
    const courseSchema = yup.object({
        title: yup.string().trim().required('Title is required'),
        course_cat: yup
            .array()
            .of(
                yup.object({
                    label: yup.string().trim(),
                    value: yup.string().trim(),
                })
            )
            .required('Courses Category is required'),
        date: yup.date().default(new Date()),
        instructor_id: yup.number().transform(numberTransform),
        price: yup.number().optional().transform(numberTransform),
        discounted_price: yup.number().optional().transform(numberTransform),
        img: yup.mixed().optional(),
        description: yup.string().optional(),
        duration: yup.string().required('Duration is required'),
        features: yup.array().of(yup.string()).optional(),
        status: yup.number().oneOf([1, 0], 'Status must be 1 or 0'),
    });

    const initialValue = {
        title: editData?.title || '',
        course_cat:
            editData?.course_category?.map((item) => ({
                label: item.title,
                value: item.id,
            })) || [],
        instructor_id: editData?.instructor_id || '',
        price: editData?.price || '',
        discounted_price: editData?.discounted_price || '',
        date: editData?.date || new Date(),
        img: editData?.img || '',
        description: editData?.description || '',
        features: editData?.features?.split(',') || [],
        duration: editData?.duration || '',
        status: editData ? editData?.status : 1,
    };
    console.log(editData);

    const formHandler = async (formData) => {
        const validatedData = await courseSchema.validate(formData, {
            stripUnknown: true,
        });

        if (apiHandler) {
            validatedData.course_cat = validatedData?.course_cat?.map((item) => item.value);
            return apiHandler(validatedData);
        }
        return validatedData;
    };
    return (
        <CrudFormLayout
            initialValues={initialValue}
            apiHandler={formHandler}
            buttonCenter
            validationSchema={courseSchema}
        >
            <CoursesForms />
        </CrudFormLayout>
    );
};

export default CoursesFormContainer;
