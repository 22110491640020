import { Button, Card } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa6';

const CardLayout = ({ children, addButtonHandler, title, buttonTitle }) => (
    <Card className='border p-3 rounded'>
        <Card.Body>
            <div className='card-box d-flex justify-content-between align-items-center'>
                <Card.Title className='mb-0 text-uppercase'>{title}</Card.Title>
                {buttonTitle && (
                    <div className=''>
                        <Button onClick={addButtonHandler} variant='teal'>
                            <FaPlus /> {buttonTitle}
                        </Button>
                    </div>
                )}
            </div>
            <hr />
            {children}
        </Card.Body>
    </Card>
);

export default CardLayout;
