import CrudFormLayout from '../../../Layout/CrudFormLayout';
import FooterForm from './FooterForm';

const FooterFormContainer = ({ apiHandler, editData }) => {
    const initialValues = {
        logo: '',
        description: '',
        iconData: [],
        quickLinkData: [],
        bottomData: [],
        contactInfo: '',
        email: '',
        mobile: '',
        address: '',
        copyRight: '',
        terms_and_condition: '',
        privacy_policy: '',
        latlng: '',
    };

    const formHanler = (formValues) => {
        // console.log(formValues);
        if (apiHandler) {
            return apiHandler(formValues);
        }

        return null;
    };
    return (
        <CrudFormLayout
            initialValues={editData || initialValues}
            apiHandler={formHanler}
            buttonCenter
        >
            <FooterForm />
        </CrudFormLayout>
    );
};

export default FooterFormContainer;
