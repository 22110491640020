import React from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SelectCity from '../../InputList/SelectableInput/SelectCity';
import SelectCountry from '../../InputList/SelectableInput/SelectCountry';
import SelectState from '../../InputList/SelectableInput/SelectState';
import InputGroup from './InputGroup';
import SelectRoles from '../../InputList/SelectableInput/SelectRoles';

const UserBasicInfoForms = () => {
    const { role_id } = useParams();

    const InputData = [
        {
            id: 5435,
            label: 'Roles : ',
            name: 'role_id',
            type: 'select',
            component: <SelectRoles name='role_id' isDisabled={role_id} />,
            required: true,
        },
        {
            id: 1,
            label: 'First Name ',
            name: 'f_name',
            required: true,
        },
        {
            id: 2,
            label: 'Last Name ',
            name: 'l_name',
        },
        {
            id: 3,
            label: 'Email Address : ',
            required: true,
            name: 'email',
        },
        {
            id: 4,
            label: 'Phone : ',
            name: 'mobile',
        },
        {
            id: 5,
            label: 'Country : ',
            name: 'country_id',
            type: 'select',
            component: <SelectCountry name='user_details.country_id' />,
        },
        {
            id: 6,
            label: 'State : ',
            name: 'state_id',
            type: 'select',
            component: (
                <SelectState
                    name='user_details.state_id'
                    dependedCountryValueId='country_id'
                    nested='user_details'
                />
            ),
        },
        {
            id: 7,
            label: 'City : ',
            name: 'city_id',
            type: 'select',
            component: (
                <SelectCity
                    name='user_details.city_id'
                    dependedStateValueId='state_id'
                    nested='user_details'
                />
            ),
        },
        {
            id: 8,
            label: 'Address : ',
            name: 'address_1',
        },
        {
            id: 9,
            label: 'Contact Person',
            name: 'contact_person',
        },
    ];
    return (
        <Row>
            <InputGroup InputData={InputData} />
        </Row>
    );
};

export default UserBasicInfoForms;
