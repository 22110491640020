import { useNavigate, useParams } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import TeamMembersContainer from '../../../components/Forms/Websites/TeamMembers/TeamMembersContainer';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import LoaderLayout from '../../../components/Loader/LoaderLayout';
import { formDataConverter } from '../../../utils/necessaryFunctions';
import CeoMessageFormContainer from '../../../components/Forms/Websites/CeoMessage/CeoMessageContainer';

const EditCeoMessage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { result, isLoading } = useFetch(() => request.getOne('ceo-message', id), id);

    const updateMember = async (formValues) => {
        try {
            const formData = formDataConverter(formValues);
            return request.updateOne('ceo-message', result.id, formData, () => {
                navigate('/dashboard/website/ceo-message');
            });
        } catch (err) {
            return err;
        }
    };

    console.log(result);

    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Edit Team Member'>
                <CeoMessageFormContainer apiHandler={updateMember} editData={result} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default EditCeoMessage;
