import React from 'react';
import { Modal } from 'react-bootstrap';

const CoursesDetailsModal = ({ courseData, isOpen, onClose }) => (
    <Modal show={isOpen} onHide={onClose} size='lg'>
        <Modal.Body>
            <div>
                <h5> Full Description </h5>

                <div dangerouslySetInnerHTML={{ __html: courseData.description }} />
            </div>
        </Modal.Body>
    </Modal>
);

export default CoursesDetailsModal;
