import { Col, Form, Row } from 'react-bootstrap';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import FormLabel from '../../../Text/FormLabel';
import StatusCheck from '../../StatusCheck';

const MissionVisionMottoForm = () => {
    console.log('');

    return (
        <div>
            <Row>
                <Col sm={12} md={12} className='mt-3'>
                    <Form.Group>
                        <FormLabel title='Mission Message' />
                        <ReactInput as='textarea' rows={3} name='mission_message' />
                    </Form.Group>
                </Col>

                <Col sm={12} md={12} className='mt-3'>
                    <Form.Group>
                        <FormLabel title='Vision Message' />
                        <ReactInput name='vision_message' as='textarea' rows={3} />
                    </Form.Group>
                </Col>

                <Col sm={12} md={12} className='mt-3'>
                    <Form.Group>
                        <FormLabel title='Motto Message' />
                        <ReactInput name='motto_message' as='textarea' rows={3} />
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mt-3'>
                <StatusCheck />
            </Row>
        </div>
    );
};

export default MissionVisionMottoForm;
