import { useNavigate, useParams } from 'react-router-dom';
import AboutUsFormContainer from '../../../components/Forms/Websites/AboutUs/AboutUsFormContainer';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import useFetch from '../../../hooks/useFetch';
import LoaderLayout from '../../../components/Loader/LoaderLayout';

const UpdateAboutUs = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { result, isLoading } = useFetch(() => request.getOne('about-us', id));

    const UpdateAboutUsHandler = (formValue) => {
        const formData = new FormData();

        Object.keys(formValue).forEach((key) => {
            formData.append(key, formValue[key]);
        });

        return request.updateOne('about-us', id, formData, () => {
            navigate('/dashboard/website/about-us');
        });
    };
    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Update About Us'>
                <AboutUsFormContainer apiHandler={UpdateAboutUsHandler} editData={result} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default UpdateAboutUs;
