import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ReactTextEditor = ({ setEditorData, defaultData }) => (
    <CKEditor
        editor={ClassicEditor}
        data={defaultData || '  '} // You can set initial content here if needed.
        // onReady={(editor) => {
        //     // You can store the "editor" and use when it is needed.
        //     console.log('Editor is ready to use!', editor);
        // }}
        onChange={(event, editor) => {
            const data = editor.getData();
            // console.log({ event, editor, data });
            // console.log(editor.getData());
            setEditorData(data);
        }}
        config={{
            link: {
                decorators: {
                    openInNewTab: {
                        mode: 'manual',
                        label: 'Open in a new tab',
                        attributes: {
                            target: '_blank',
                            rel: 'noopener noreferrer',
                        },
                    },
                },
                downloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                        download: 'download',
                    },
                },
            },
            // toolbar: [
            //     'heading',
            //     '|',
            //     'bold',
            //     'italic',
            //     'link',
            //     'bulletedList',
            //     'numberedList',
            //     'blockQuote',
            //     '|',
            //     'imageUpload', // Add image upload button to toolbar
            //     'mediaEmbed', // Add media embed button to toolbar
            //     'table',
            // ],
        }}
        // onBlur={(event, editor) => {
        //     console.log('Blur.', editor);
        // }}
        // onFocus={(event, editor) => {
        //     console.log('Focus.', editor);
        // }}
    />
);

export default ReactTextEditor;
