import { useNavigate } from 'react-router-dom';
import BlogFormContainer from '../../../components/Forms/Websites/Blogs/BlogFormContainer';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import { formDataConverter } from '../../../utils/necessaryFunctions';

const AddBlog = () => {
    const navigate = useNavigate();
    const addBlogHandler = (formValues) => {
        const formData = formDataConverter(formValues);
        return request.create('blogs', formData, () => {
            navigate('/dashboard/website/blogs');
        });
    };

    return (
        <CardLayout title='Add New Blog'>
            <BlogFormContainer apiHandler={addBlogHandler} />
        </CardLayout>
    );
};

export default AddBlog;
