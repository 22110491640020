import React from 'react';
import { Button, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { FaCaretDown } from 'react-icons/fa6';

const OverlayDropdown = ({ dropdownConfig, data }) => {
    const dropdownMenu = (cellData) => (
        <Popover id='dropdown-popover'>
            <Popover.Body className='p-0'>
                <Dropdown>
                    {dropdownConfig.map((item) => (
                        <Dropdown.Item
                            key={item.id}
                            onClick={() => !!item.onClick && item.onClick(cellData)} // Pass cell data to item.onClick
                        >
                            <span className='me-1'> {item.icon}</span> {item.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown>
            </Popover.Body>
        </Popover>
    );
    return (
        <OverlayTrigger trigger='click' placement='bottom' overlay={dropdownMenu(data)} rootClose>
            <Button variant='teal' size='sm'>
                Action <FaCaretDown />
            </Button>
        </OverlayTrigger>
    );
};

export default OverlayDropdown;
