import React, { useState } from 'react';
import DeleteButton from '../Button/DeleteButton';
import DeleteModal from './DeleteModal';

const DeleteSingleItems = ({ handleDelete, iconSize, noButton, ModalShowHandler }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [deletedId, setDeletedId] = useState('');

    const deleteHandlerFunction = () => {
        if (handleDelete) {
            handleDelete();
        }
        setModalOpen(false);
    };

    function ModalShowHandler(id) {}

    return (
        <div>
            <DeleteButton onClick={() => setModalOpen(true)} iconSize={iconSize} />

            {isModalOpen && (
                <DeleteModal
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                    handleDelete={deleteHandlerFunction}
                />
            )}
        </div>
    );
};

export default DeleteSingleItems;
