import React, { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ReactSelect from '../../ReactSelect/ReactSelect';

const SelectCity = ({ dependedStateValueId, nested, ...props }) => {
    const [field, meta, helper] = useField(props);
    const { values } = useFormikContext();

    const { result: cityList, setRefresh } = useFetch(
        () =>
            request.getAll(
                `city/state/data/${
                    nested ? values[nested]?.[dependedStateValueId] : values[dependedStateValueId]
                }`
            ),
        nested ? values[nested]?.[dependedStateValueId] : values[dependedStateValueId]
    );
    const [isModalOpen, setModalOpen] = useState(false);

    return (
        <>
            <InputGroup>
                <ReactSelect
                    data={cityList}
                    value={field?.value}
                    valueName='id'
                    labelName='name'
                    onChange={(val) => helper.setValue(val)}
                />
            </InputGroup>

            <ErrorMessage name={props?.name} component='p' className='error' />
        </>
    );
};

export default SelectCity;
