import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import request from '../../request/request';
import useFetch from '../../hooks/useFetch';
import ReactDataTable from '../ReactDataTable/ReactDataTable';

function calculateTotalFoodCourtAmount(data) {
    return data.reduce((total, order) => {
        // Remove any leading or trailing spaces from branchAmount and check for a sign
        const foodCourtAmountString = order.foodCourtCommission.replace(/\s/g, '');
        const foodCourtAmount = parseFloat(foodCourtAmountString);

        // Add or subtract based on the sign in branchAmount
        if (foodCourtAmountString.startsWith('-')) {
            return total - Math.abs(foodCourtAmount);
        }
        if (foodCourtAmountString.startsWith('+')) {
            return total + Math.abs(foodCourtAmount);
        }
        return total + foodCourtAmount; // Default to adding if no sign is present
    }, 0);
}

const FoodCourtTransecton = () => {
    const { foodCourtId } = useParams();

    const { result, isLoading, error } = useFetch(() =>
        request.getAllById('transection/foodCourt', foodCourtId)
    );

    const column = [
        {
            name: 'OrderId',
            selector: (row) => row.orderId,
            wrap: true,
            center: true,
        },

        {
            name: 'Paid Amount',
            selector: (row) => row.paidAmount,
            wrap: true,
            center: true,
        },
        {
            name: 'Food Court Commission',
            selector: (row) => row.foodCourtCommission,
            wrap: true,
            center: true,
        },
    ];

    return (
        <section>
            <ReactDataTable columns={column} data={result} pending={isLoading} />

            <div className='text-end mt-3'>
                <h4>Total foodcourt Amount: {calculateTotalFoodCourtAmount(result)} TK</h4>
            </div>
        </section>
    );
};
export default FoodCourtTransecton;
