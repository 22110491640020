import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import CrudFormLayout from '../../Layout/CrudFormLayout';
import FoodForm from './FoodForm';
import { formDataConverter } from '../../../utils/necessaryFunctions';

const FoodFormContainer = ({ apiHandler, editData, branchList, foodCategory }) => {
    const { restaurantId } = useParams();
    const foodSchema = yup.object().shape({
        name: yup.string().required('Name is required.'),
        description: yup.string(),
        food_cat_id: yup.number().integer().required('Food category ID is required.'),
        restaurant_id: yup.string().required('Restaurant ID is required.'),
        image: yup.mixed(),
        price: yup
            .number()
            .required('Price is required.')
            .positive('Price must be a positive number.')
            .moreThan(0, 'Price must be greater than 0.'),
        discounted_price: yup
            .number()
            .required('Discounted price is required.')
            .positive('Discounted price must be a positive number.')
            .moreThan(0, 'Discounted price must be greater than 0.'),
        status: yup.number().integer().oneOf([0, 1]).default(1),
        branchFoodPrice: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.number(),
                    branch_id: yup.string(),
                    // name: yup.string().required('Branch name is required'),
                    price: yup.number().positive('Price must be positive'),
                    discounted_price: yup
                        .number()
                        .nullable()
                        .positive('Discounted price must be positive')
                        .notRequired(),
                    status: yup
                        .number()
                        .oneOf([0, 1], 'Branch status must be either 0 or 1')
                        .notRequired(),
                })
            )
            .optional(),
    });

    const initialValues = {
        restaurant_id: editData?.restaurant_id || restaurantId,
        food_cat_id: editData?.food_cat_id || '',
        name: editData?.name || '',
        description: editData?.description || '',
        price: editData?.price || '',
        discounted_price: editData?.discounted_price || '',
        image: editData?.image || '',
        status: editData?.status === 0 ? 0 : 1 || 1, // Correct handling of status
        branchFoodPrice:
            branchList?.map((item) => {
                if (editData) {
                    const hasInEditData = editData.branchFoodPrice?.find(
                        (i) => i.branch_id === item.branch_id
                    );
                    return {
                        id: hasInEditData?.id,
                        branch_id: item.branch_id,
                        name: item.name,
                        price: hasInEditData?.price || '',
                        discounted_price: hasInEditData?.discounted_price || '',
                        status: hasInEditData?.status === 1 ? 1 : 0 || 0,
                    };
                }
                return {
                    branch_id: item.branch_id,
                    name: item.name,
                    price: '',
                    discounted_price: '',
                    status: 0,
                };
            }) || [],
    };
    const onSubmitHandler = async (values) => {
        try {
            const { branchFoodPrice } = values;
            const filteredData = branchFoodPrice.filter((item) => item.price !== '');
            values.branchFoodPrice = filteredData;
            const validateData = await foodSchema.validate(values, { stripUnknown: true });
            console.log(validateData);
            // return null;
            if (apiHandler) apiHandler(formDataConverter(validateData));
            return validateData;
        } catch (err) {
            console.log(err);
            return err;
        }
    };
    console.log(editData);
    return (
        <div>
            <CrudFormLayout
                initialValues={initialValues}
                buttonCenter
                validationSchema={foodSchema}
                apiHandler={onSubmitHandler}
            >
                <FoodForm foodCategory={foodCategory} />
            </CrudFormLayout>
        </div>
    );
};

export default FoodFormContainer;
