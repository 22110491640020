import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import CollectionLabel from '../Pages/CollectionLabel/CollectionLabel';
import ForgetPasswordPage from '../Pages/ForgetPasswordPage/ForgetPasswordPage';
import OtpVerification from '../Pages/OtpVerification/OtpVerification';
import ProfilePage from '../Pages/ProfilePage/ProfilePage';
import ResetPasswordPage from '../Pages/ResetPasswordPage/ResetPasswordPage';
import City from '../Pages/Settings/City/City';
import Country from '../Pages/Settings/Country/Country';
import GenderPage from '../Pages/Settings/GenderPage/GenderPage';
import SettingsPage from '../Pages/Settings/SettingsPage/SettingsPage';
import State from '../Pages/Settings/State/State';
import StaticContentGroupPage from '../Pages/Settings/StaticContentGroupPage/StaticContentGroupPage';
import StaticContentPage from '../Pages/Settings/StaticContentPage/StaticContentPage';
import UOMPage from '../Pages/Settings/UOMPage/UOMPage';
import Signin from '../Pages/Signin/Signin';
import Signup from '../Pages/Signup/Signup';
import ActivityListPage from '../Pages/UserManagement/ActivityListPage/ActivityListPage';
import ModuleListPage from '../Pages/UserManagement/ModuleListPage/ModuleListPage';
import RoleListPage from '../Pages/UserManagement/RoleListPage/RoleListPage';
import RolePermissionPage from '../Pages/UserManagement/RolePermissionPage/RolePermissionPage';
import ServiceCategory from '../Pages/UserManagement/ServiceCategory/ServiceCategory';
import TermsConditions from '../Pages/UserManagement/TermsConditions/TermsConditions';
import UserListPage from '../Pages/UserManagement/UserListPage/AllUsers/UserListPage';
import DashboardLayout from '../components/Layout/DashboardLayout';
import PrivateRouter from './Private/PrivateRouter';
import UserByRoles from '../Pages/UserManagement/UserListPage/RoleWiseUsers/UserByRoles';
import InstrumentCategoryList from '../Pages/Settings/InstrumentCategoryList/InstrumentCategoryList';
import InstrumentsPage from '../Pages/Settings/InstrumentsPage/InstrumentsPage';
import HomePage from '../Pages/HomePage/HomePage';
import AboutUs from '../Pages/WebsitePannel/aboutUs/AboutUs';
import AddAboutUs from '../Pages/WebsitePannel/aboutUs/AddAboutUs';
import UpdateAboutUs from '../Pages/WebsitePannel/aboutUs/UpdateAboutUs';
import OurService from '../Pages/WebsitePannel/OurService/OurService';
import Destination from '../Pages/WebsitePannel/Destination/Destinations';
import BlogList from '../Pages/WebsitePannel/Blogs/BlogList';
import AddBlog from '../Pages/WebsitePannel/Blogs/AddBlog';
import UpdateBlogs from '../Pages/WebsitePannel/Blogs/UpdateBlogs';
import Awards from '../Pages/WebsitePannel/Awards/Awards';
import Certificate from '../Pages/WebsitePannel/Certificate/Certificate';
import Associates from '../Pages/WebsitePannel/Associates/Associates';
import PhotoGallery from '../Pages/WebsitePannel/PhotoGallery/PhotoGallery';
import VideoGallery from '../Pages/WebsitePannel/VideoGallery/VideoGallery';
import Footer from '../Pages/WebsitePannel/Footer/Footer';
import AddFooter from '../Pages/WebsitePannel/Footer/AddFooter';
import EditFooter from '../Pages/WebsitePannel/Footer/EditFooter';
import AddContact from '../Pages/WebsitePannel/ContactUs/AddContact';
import EditContact from '../Pages/WebsitePannel/ContactUs/EditContact';
import TeamMember from '../Pages/WebsitePannel/TeamMembers/TeamMembers';
import AddTeamMembers from '../Pages/WebsitePannel/TeamMembers/AddTeamMembers';
import EditTeamMember from '../Pages/WebsitePannel/TeamMembers/EditTeamMember';
import CeoMessage from '../Pages/WebsitePannel/CeoMessage/CeoMessage';
import AddCeoMessage from '../Pages/WebsitePannel/CeoMessage/AddCeoMessage';
import EditCeoMessage from '../Pages/WebsitePannel/CeoMessage/EditCeoMessage';
import MissionVisionMottoList from '../Pages/WebsitePannel/MissionVisionMotto/MissionVisionMotto';
import AddMissionVisionMotto from '../Pages/WebsitePannel/MissionVisionMotto/AddMissionVisionMotto';
import EditMissionVisionMotto from '../Pages/WebsitePannel/MissionVisionMotto/EditMissionVisionMotto';
import BlogCategory from '../Pages/WebsitePannel/BlogCategory/BlogCategoryList';
import AddPages from '../Pages/WebsitePannel/Pages/AddPages';
import PageList from '../Pages/WebsitePannel/Pages/PageList';
import EditPages from '../Pages/WebsitePannel/Pages/EditPages';
import PageDetails from '../Pages/WebsitePannel/Pages/PageDetails';
import AddNewUser from '../Pages/UserManagement/UserListPage/AllUsers/AddNewUser';
import EditUser from '../Pages/UserManagement/UserListPage/EditUser';
import AddUserByRole from '../Pages/UserManagement/UserListPage/RoleWiseUsers/AddUserByRole';
import CourseCategory from '../Pages/WebsitePannel/CourseCategory/CourseCategoy';
import CourseList from '../Pages/WebsitePannel/Courses/CourseList';
import AddCourse from '../Pages/WebsitePannel/Courses/AddCourse';
import UpdateCourses from '../Pages/WebsitePannel/Courses/UpdateCourse';
import ContactMsg from '../Pages/WebsitePannel/ContactUs/ContactMsg';
import Album from '../Pages/WebsitePannel/Albums/AlbumList';
import CourseRegistrationList from '../Pages/WebsitePannel/CourseRegistrationList/CourseRegistrationList';
import FoodCourtList from '../Pages/FoodCourt/FoodCourtList';
import AddFoodCourt from '../Pages/FoodCourt/AddFoodCourt';
import UpdateFoodCourt from '../Pages/FoodCourt/UpdateFoodCourt';
import FoodCourtDetails from '../Pages/FoodCourt/FoodCourtDetails';
import RestaurantList from '../Pages/Restaurant/RestaurantList';
import AddRestaurant from '../Pages/Restaurant/AddRestaurant';
import UpdateRestaurant from '../Pages/Restaurant/UpdateRestaurant';
import RestaurantDetails from '../Pages/Restaurant/RestaurantDetails';
import BranchList from '../Pages/Branch/BranchList';
import AddBranch from '../Pages/Branch/AddBranch';
import UpdateBranch from '../Pages/Branch/UpdateBranch';
import BranchDetails from '../Pages/Branch/BranchDetails';
import CouponList from '../Pages/Coupon/CouponList';
import AddCoupon from '../Pages/Coupon/AddCouponList';
import UpdateCoupon from '../Pages/Coupon/UpdateCoupon';
import FoodCategory from '../Pages/Category/CategoryList';

const router = createBrowserRouter([
    {
        index: true,
        path: '/',
        element: <Signin />,
    },
    {
        path: 'signin',
        element: <Signin />,
    },
    {
        path: 'signup',
        element: <Signup />,
    },
    {
        path: 'forget-password',
        element: <ForgetPasswordPage />,
    },
    {
        path: 'reset-password',
        element: <ResetPasswordPage />,
    },
    {
        path: 'otp-verification',
        element: <OtpVerification />,
    },
    {
        path: 'dashboard',
        element: <DashboardLayout />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            {
                path: 'profile',
                element: <ProfilePage />,
            },
            {
                path: 'users',
                element: (
                    <PrivateRouter props={5}>
                        <UserListPage />
                    </PrivateRouter>
                ),
            },

            {
                path: 'users/add-user',
                element: <AddNewUser />,
            },
            {
                path: 'users/edit-user/:user_id',
                element: <EditUser />,
            },

            {
                path: 'roles',
                element: (
                    <PrivateRouter props={4}>
                        <RoleListPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'user-management/role-permissions',
                element: (
                    <PrivateRouter props={6}>
                        <RolePermissionPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'user/roles/:role_id',
                element: <UserByRoles />,
            },
            {
                path: 'user/roles/add/:role_id',
                element: <AddUserByRole />,
            },
            {
                path: 'user-management/terms-conditions',
                element: <TermsConditions />,
            },
            {
                path: 'user-management/service-category',
                element: <ServiceCategory />,
            },
            {
                path: 'module',
                // element: <ModuleListPage />,
                element: (
                    <PrivateRouter props={3}>
                        <ModuleListPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'activity',
                element: (
                    <PrivateRouter props={2}>
                        <ActivityListPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'static-content-groups',
                element: (
                    <PrivateRouter props={7}>
                        <StaticContentGroupPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'static-content',
                element: (
                    <PrivateRouter props={8}>
                        <StaticContentPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/instruments-category',
                element: (
                    <PrivateRouter props={9}>
                        <InstrumentCategoryList />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/instruments',
                element: (
                    <PrivateRouter props={10}>
                        <InstrumentsPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/uom',
                element: (
                    <PrivateRouter props={15}>
                        <UOMPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/gender',
                element: (
                    <PrivateRouter props={14}>
                        <GenderPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/country',
                element: (
                    <PrivateRouter props={18}>
                        <Country />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/state',
                element: (
                    <PrivateRouter props={19}>
                        <State />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/city',
                element: (
                    <PrivateRouter props={20}>
                        <City />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/system-settings',
                element: (
                    <PrivateRouter props={1}>
                        <SettingsPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'collection-label',
                element: <CollectionLabel />,
            },
            // website
            {
                path: 'website/about-us',
                element: <AboutUs />,
            },
            {
                path: 'website/about-us/add',
                element: <AddAboutUs />,
            },
            {
                path: 'website/about-us/edit/:id',
                element: <UpdateAboutUs />,
            },
            {
                path: 'website/our-service',
                element: <OurService />,
            },
            {
                path: 'website/destination',
                element: <Destination />,
            },
            {
                path: 'website/blog-category',
                element: <BlogCategory />,
            },
            {
                path: 'website/blogs',
                element: <BlogList />,
            },
            {
                path: 'website/blogs/add',
                element: <AddBlog />,
            },
            {
                path: 'website/blogs/edit/:id',
                element: <UpdateBlogs />,
            },
            {
                path: 'website/awards',
                element: <Awards />,
            },
            {
                path: 'website/certificate',
                element: <Certificate />,
            },
            {
                path: 'website/associates',
                element: <Associates />,
            },
            {
                path: 'website/photo-gallery',
                element: <PhotoGallery />,
            },
            {
                path: 'website/video-gallery',
                element: <VideoGallery />,
            },
            {
                path: 'website/footer',
                element: <Footer />,
            },
            {
                path: 'website/footer/add',
                element: <AddFooter />,
            },
            {
                path: 'website/footer/edit/:id',
                element: <EditFooter />,
            },
            {
                path: 'website/contact-message',
                element: <ContactMsg />,
            },
            {
                path: 'website/contact-us/add',
                element: <AddContact />,
            },
            {
                path: 'website/contact-us/edit/:id',
                element: <EditContact />,
            },
            {
                path: 'website/team-members',
                element: <TeamMember />,
            },
            {
                path: 'website/team-members/add',
                element: <AddTeamMembers />,
            },
            {
                path: 'website/team-members/edit/:id',
                element: <EditTeamMember />,
            },
            {
                path: 'website/ceo-message',
                element: <CeoMessage />,
            },
            {
                path: 'website/ceo-message/add',
                element: <AddCeoMessage />,
            },
            {
                path: 'website/ceo-message/edit/:id',
                element: <EditCeoMessage />,
            },
            {
                path: 'website/mission-vision-motto',
                element: <MissionVisionMottoList />,
            },
            {
                path: 'website/mission-vision-motto/add',
                element: <AddMissionVisionMotto />,
            },
            {
                path: 'website/mission-vision-motto/edit/:id',
                element: <EditMissionVisionMotto />,
            },
            {
                path: 'website/pages',
                element: <PageList />,
            },
            {
                path: 'website/pages/add',
                element: <AddPages />,
            },
            {
                path: 'website/pages/edit/:id',
                element: <EditPages />,
            },
            {
                path: 'website/pages/details/:id',
                element: <PageDetails />,
            },

            {
                path: 'website/course-category',
                element: <CourseCategory />,
            },
            {
                path: 'website/courses',
                element: <CourseList />,
            },
            {
                path: 'website/courses/add',
                element: <AddCourse />,
            },
            {
                path: 'website/courses/edit/:id',
                element: <UpdateCourses />,
            },
            {
                path: 'website/courses-registration',
                element: <CourseRegistrationList />,
            },
            {
                path: 'website/album',
                element: <Album />,
            },
            {
                path: 'food-court',
                element: <FoodCourtList />,
            },
            {
                path: 'food-court/add',
                element: <AddFoodCourt />,
            },
            {
                path: 'food-court/edit/:foodCourtId',
                element: <UpdateFoodCourt />,
            },
            {
                path: 'food-court/details/:foodCourtId',
                element: <FoodCourtDetails />,
            },
            {
                path: 'restaurant',
                element: <RestaurantList />,
            },
            {
                path: 'restaurant/add',
                element: <AddRestaurant />,
            },
            {
                path: 'restaurant/edit/:restaurantId',
                element: <UpdateRestaurant />,
            },
            {
                path: 'restaurant/details/:restaurantId',
                element: <RestaurantDetails />,
            },
            {
                path: 'branch',
                element: <BranchList />,
            },
            {
                path: 'branch/add',
                element: <AddBranch />,
            },
            {
                path: 'branch/edit/:id',
                element: <UpdateBranch />,
            },
            {
                path: 'branch/details/:id',
                element: <BranchDetails />,
            },
            {
                path: 'coupon-list',
                element: <CouponList />,
            },
            {
                path: 'coupon-list/add',
                element: <AddCoupon />,
            },
            {
                path: 'coupon-list/edit/:id',
                element: <UpdateCoupon />,
            },
            {
                path: 'food-category',
                element: <FoodCategory />,
            },
        ],
    },
]);

export default router;
