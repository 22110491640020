import toast from 'react-hot-toast';

export default async function asyncFuncHandler(func, isNotify) {
    try {
        return await func();
    } catch (error) {
        console.error(error);
        if (isNotify) {
            toast.error(error.message);
        }
        return error;
    }
}
