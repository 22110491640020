import { Link } from 'react-router-dom';
import MenuItem from './MenuItem';

const websiteSections = [
    { title: 'About Us', path: '/dashboard/website/about-us' },
    { title: 'Our Service', path: '/dashboard/website/our-service' },
    { title: 'Blog Category List', path: '/dashboard/website/blog-category' },
    { title: 'Blog List', path: '/dashboard/website/blogs' },
    { title: 'Associates', path: '/dashboard/website/associates' },
    { title: 'Certificate', path: '/dashboard/website/certificate' },
    { title: 'Awards', path: '/dashboard/website/awards' },
    { title: 'Footer', path: '/dashboard/website/footer' },
    { title: 'Team Members List', path: '/dashboard/website/team-members' },
    { title: 'CEO Message List', path: '/dashboard/website/ceo-message' },
    {
        title: 'Mission, Vision & Motto',
        path: '/dashboard/website/mission-vision-motto',
    },
    { title: 'Pages', path: '/dashboard/website/pages' },
    {
        title: 'Course Category ',
        path: '/dashboard/website/course-category',
    },
    {
        title: 'Course List ',
        path: '/dashboard/website/courses',
    },
    {
        title: 'Course Registration List ',
        path: '/dashboard/website/courses-registration',
    },
];
const DashboardWebsiteSection = () => (
    <div>
        <MenuItem iconClass='bi bi-file-earmark-person' title='Websites'>
            {websiteSections.map((section) => (
                <li key={section.title}>
                    <Link to={section.path}>
                        <i className='bi bi-arrow-right-short' />
                        {section.title}
                    </Link>
                </li>
            ))}
        </MenuItem>
    </div>
);

export default DashboardWebsiteSection;
