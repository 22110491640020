import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FaEye } from 'react-icons/fa6';
import { Button } from 'react-bootstrap';
import CardLayout from '../../../components/Layout/CardLayout';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import EditButton from '../../../components/Button/EditButton';
import DeleteButton from '../../../components/Button/DeleteButton';
import DeleteModal from '../../../components/Modal/DeleteModal';

const PageList = () => {
    const navigate = useNavigate();
    const { result, isLoading, setRefresh } = useFetch(() => request.getAll('pages'));

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const columns = [
        {
            name: 'title',
            center: 'true',
            wrap: true,
            selector: (row) => row.title,
        },
        {
            name: 'Url',
            center: 'true',
            wrap: true,
            selector: (row) => row.url,
        },
        // {
        //     name: 'le',
        //     center: 'true',
        //     wrap: true,
        //     cell: (row) => <div dangerouslySetInnerHTML={{ __html: row.content }} />,
        // },

        {
            name: 'Action',
            center: 'true',
            wrap: true,
            cell: (row) => (
                <div>
                    <Button
                        size='sm'
                        variant='warning'
                        className='me-2'
                        onClick={() => navigate(`/dashboard/website/pages/details/${row?.id}`)}
                    >
                        <FaEye />
                    </Button>

                    <EditButton
                        onClick={() => navigate(`/dashboard/website/pages/edit/${row?.id}`)}
                    />
                    <DeleteButton
                        onClick={() => {
                            setModalOpen(true);
                            setSelectedItem(row);
                        }}
                    />
                </div>
            ),
        },
    ];

    const deletePagesHandler = () =>
        request.delete('pages', selectedItem.id, () => {
            setModalOpen(false);
            setSelectedItem(null);
            setRefresh((prev) => !prev);
        });

    return (
        <CardLayout
            title='Page List'
            buttonTitle='Add New Page'
            addButtonHandler={() => navigate('/dashboard/website/pages/add')}
        >
            <ReactDataTable data={result} loading={isLoading} columns={columns} />

            {isModalOpen && (
                <DeleteModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        setModalOpen(false);
                        setSelectedItem(null);
                    }}
                    handleDelete={deletePagesHandler}
                />
            )}
        </CardLayout>
    );
};

export default PageList;
