import React from 'react';

const TitleInfo = ({ title, description, className, normalTitle, boldDescription }) => (
    <p className={`mb-1 ${className}`}>
        {title && <strong>{title} : </strong>}

        {normalTitle && <span>{normalTitle} : </span>}

        {description && description}

        {boldDescription && <b>{boldDescription}</b>}
    </p>
);

export default TitleInfo;
