import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import FormLabel from '../../../Text/FormLabel';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import StatusCheck from '../../StatusCheck';
import ReactSelect from '../../../ReactSelect/ReactSelect';
import useFetch from '../../../../hooks/useFetch';
import request from '../../../../request/request';
import ErrorMsg from '../../../Text/ErrorMsg';

const MediaGalleryForm = ({ group, hasAlbum }) => {
    const { values, setFieldValue } = useFormikContext();

    const { result: albumData } = useFetch(() => request.getAll('album'));

    const asssociateGroup = [
        {
            value: 'associate',
            label: 'Associate',
        },
        {
            value: 'awards',
            label: 'Awards',
        },
        {
            value: 'certificate',
            label: 'Certificate',
        },
        {
            value: 'photo_gallery',
            label: 'Photo Gallery',
        },
        {
            value: 'video_gallery',
            label: 'Video Gallery',
        },
    ];
    return (
        <div>
            <Form.Group>
                <FormLabel title='Title' required />
                <ReactInput name='title' />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Image' required />
                <ReactInput name='image' type='file' />
            </Form.Group>

            {/* <Form.Group className='mt-3'> */}
            {/*     <FormLabel title='Associate Group' required /> */}
            {/*     <ReactSelect */}
            {/*         data={asssociateGroup} */}
            {/*         labelName='label' */}
            {/*         valueName='value' */}
            {/*         isDisabled={!!group} */}
            {/*         defaultValue={group && asssociateGroup?.find((item) => item.value === group)} */}
            {/*         value={values?.associate_group} */}
            {/*         onChange={(val) => setFieldValue('associate_group', val)} */}
            {/*     /> */}
            {/* </Form.Group> */}

            {hasAlbum && (
                <Form.Group className='mt-3'>
                    <FormLabel title='Album' required />
                    <ReactSelect
                        data={albumData}
                        labelName='title'
                        valueName='id'
                        value={values?.album_id}
                        onChange={(val) => setFieldValue('album_id', val)}
                    />
                    <ErrorMsg name='album' />
                </Form.Group>
            )}

            <Form.Group className='mt-3'>
                <FormLabel title='Description' />
                <ReactInput name='description' as='textarea' rows={3} />
            </Form.Group>

            <StatusCheck />
        </div>
    );
};

export default MediaGalleryForm;
