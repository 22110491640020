import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import FormLabel from '../../Text/FormLabel';
import ReactInput from '../../InputList/ReactInput/ReactInput';

const SignInForm = () => {
    const { values, setFieldValue } = useFormikContext();
    return (
        <div>
            <Form.Group className='mt-3'>
                <FormLabel title='Mobile No' required />
                <ReactInput name='mobile' />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Password' required />
                <ReactInput name='password' type='password' />
            </Form.Group>

            <Form.Group className='d-flex justify-content-between mt-3'>
                <Form.Check
                    type='switch'
                    label='Remember me'
                    value={values.rememberMe}
                    onChange={(val) => setFieldValue('rememberMe', val.target.checked)}
                />
                <Link to='/forgot-password'>Forgot Password ?</Link>
            </Form.Group>
            <div>
                <Button type='submit' variant='primary' className='w-100 mt-3'>
                    Submit
                </Button>
            </div>
        </div>
    );
};
export default SignInForm;
