import { useNavigate, useParams } from 'react-router-dom';
import request from '../../request/request';
import useFetch from '../../hooks/useFetch';
import LoaderLayout from '../../components/Loader/LoaderLayout';
import CardLayout from '../../components/Layout/CardLayout';
import { formDataConverter } from '../../utils/necessaryFunctions';
import CouponFormContainer from '../../components/Forms/Coupon/CouponFormContainer';

const UpdateCoupon = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { result, isLoading } = useFetch(
        () => request.getOne('coupon', `${id}?details=true`),
        id
    );
    // console.log(result);
    const UpdateFoodHandler = (values) =>
        request.updateOne('coupon', id, values, () => {
            navigate('/dashboard/coupon-list');
        });
    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Update Coupon'>
                <CouponFormContainer apiHandler={UpdateFoodHandler} editData={result} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default UpdateCoupon;
