import { useNavigate, useParams } from 'react-router-dom';
import request from '../../request/request';
import useFetch from '../../hooks/useFetch';
import LoaderLayout from '../../components/Loader/LoaderLayout';
import CardLayout from '../../components/Layout/CardLayout';
import { formDataConverter } from '../../utils/necessaryFunctions';
import BranchFormContainer from '../../components/Forms/Branch/BranchFormContainer';

const UpdateBranch = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { result, isLoading } = useFetch(() => request.getOne('branch-list', id), id);
    console.log(result);
    const UpdateFoodHandler = (values) =>
        request.updateOne('branch-list', id, formDataConverter(values), () => {
            navigate('/dashboard/branch');
        });
    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Update Branch'>
                <BranchFormContainer apiHandler={UpdateFoodHandler} editData={result} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default UpdateBranch;
