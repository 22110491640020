import { useFormikContext } from 'formik';
import { Form, Col, Row } from 'react-bootstrap';
import FormLabel from '../../../Text/FormLabel';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import ReactTextEditor from '../../../Editors/ReactTextEditor';

const PagesForm = () => {
    const { values, setFieldValue } = useFormikContext();
    return (
        <Row>
            <Col sm={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Title' required />
                    <ReactInput name='title' />
                </Form.Group>
            </Col>

            <Col sm={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='url' required />
                    <ReactInput name='url' />
                </Form.Group>
            </Col>

            <Col sm={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Page Banner' required />
                    <ReactInput name='page_banner' type='file' accept='.png, .jpg, .jpeg, ' />
                </Form.Group>
            </Col>

            <Col sm={12} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Page Content' required />
                    <ReactTextEditor
                        defaultData={values?.content}
                        setEditorData={(val) => setFieldValue('content', val)}
                    />
                </Form.Group>
            </Col>
        </Row>
    );
};

export default PagesForm;
