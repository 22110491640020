import { useState } from 'react';
import CardLayout from '../../../components/Layout/CardLayout';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ModalLayout from '../../../components/Layout/ModalLayout';
import OurServiceForm from '../../../components/Forms/OurServices/OurServiceForm';
import { formDataConverter } from '../../../utils/necessaryFunctions';
import EditButton from '../../../components/Button/EditButton';
import DeleteButton from '../../../components/Button/DeleteButton';
import DeleteModal from '../../../components/Modal/DeleteModal';

const OurService = () => {
    const [modalOpen, setModalOpen] = useState('');
    const [selectedItem, setSelectedItem] = useState('');

    const { result, isLoading, setRefresh } = useFetch(() => request.getAll('our-service'));

    const columns = [
        {
            name: 'Title',
            center: 'true',
            selector: (row) => row?.title,
        },
        {
            name: 'Icon',
            width: '100px',
            cell: (row) => (
                <div>
                    {row.icon_img && (
                        <img
                            style={{ width: '100%' }}
                            src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.icon_img}`}
                            alt='side Img'
                        />
                    )}
                </div>
            ),
        },

        {
            name: 'Icon Class',
            wrap: true,
            center: 'true',
            selector: (row) => <i className={row?.icon_class} style={{ fontSize: '30px' }} />,
        },
        {
            name: 'Description',
            wrap: true,
            center: 'true',
            selector: (row) => row?.description,
        },
        {
            name: 'Button Title',
            center: 'true',
            selector: (row) => row.button_title,
        },
        {
            name: 'Status',
            center: 'true',
            selector: (row) => (row?.status === 1 ? 'Active' : 'InActive'),
        },
        {
            name: 'Active',
            cell: (row) => (
                <div>
                    <EditButton
                        onClick={() => {
                            setModalOpen('update');
                            setSelectedItem(row);
                        }}
                    />
                    <DeleteButton
                        onClick={() => {
                            setModalOpen('delete');
                            setSelectedItem(row);
                        }}
                    />
                </div>
            ),
        },
    ];

    // create new function

    const createNewServiceHandler = (formValue) => {
        const formData = formDataConverter(formValue);

        return request.create('our-service', formData, () => {
            setModalOpen('');
            setRefresh((prev) => !prev);
        });
    };

    // update

    const updateServiceHandler = (formValue) => {
        const formData = formDataConverter(formValue);

        return request.updateOne('our-service', selectedItem?.id, formData, () => {
            setModalOpen('');
            setRefresh((prev) => !prev);
        });
    };

    // delete

    const deleteServiceHandler = () =>
        request.delete('our-service', selectedItem?.id, () => {
            setModalOpen('');
            setRefresh((prev) => !prev);
        });

    return (
        <CardLayout
            title='Our Service List'
            buttonTitle='Add new Service'
            addButtonHandler={() => setModalOpen('create')}
        >
            <ReactDataTable data={result} columns={columns} pending={isLoading} />
            {/*= ============== create ===================== */}

            {modalOpen === 'create' && (
                <ModalLayout
                    isOpen={modalOpen === 'create'}
                    onClose={() => setModalOpen('')}
                    title='Create New Service'
                    size='lg'
                >
                    <OurServiceForm apiHandler={createNewServiceHandler} />
                </ModalLayout>
            )}

            {/* ======================= update ========================== */}

            {modalOpen === 'update' && (
                <ModalLayout
                    isOpen={modalOpen === 'update'}
                    onClose={() => setModalOpen('')}
                    title='Update New Service'
                    size='lg'
                >
                    <OurServiceForm apiHandler={updateServiceHandler} editData={selectedItem} />
                </ModalLayout>
            )}

            {/* ======================= delete ========================== */}

            {modalOpen === 'delete' && (
                <DeleteModal
                    isOpen={modalOpen === 'delete'}
                    onClose={() => setModalOpen('')}
                    handleDelete={deleteServiceHandler}
                />
            )}
        </CardLayout>
    );
};

export default OurService;
