import * as Yup from 'yup';
import CrudFormLayout from '../../Layout/CrudFormLayout';
import FoodCourtForm from './FoodCourtAndRestaurantForm';

const FoodCourtAndRestaurantFormContainer = ({ apiHandler, editData, form }) => {
    const validationSchema = Yup.object().shape({
        user_id: Yup.string().required('User is required'),
        name: Yup.string().required('Name is required'),
        mobile: Yup.string()
            .matches(/^0[0-9]{10}$/, 'Mobile number must start with 0 and be 11 digits')
            .required('Mobile number is required'),
        contact_person: Yup.string().required('Contact person is required'),
        contact_number: Yup.string()
            .matches(/^0[0-9]{10}$/, 'Contact number must be start with 0 and be 11 digits')
            .required('Contact number is required'),
        country_id: Yup.number().required('Country is required'),
        state_id: Yup.number().required('State is required'),
        city_id: Yup.number().required('City is required'),
        address: Yup.string().required('Address is required'),
        logo: Yup.string(),
        banner: Yup.string(),
        closing_time: Yup.date().nullable(),
        opening_time: Yup.date().nullable(),
        status: Yup.number()
            .oneOf([1, 0], 'Invalid status')
            .required('Status is required')
            .default(1),
    });

    const initialValues = {
        user_id: editData?.user_id || '',
        name: editData?.name || '',
        mobile: editData?.mobile || '',
        contact_person: editData?.contact_person || '',
        contact_number: editData?.contact_number || '',
        country_id: editData?.country_id || '',
        state_id: editData?.state_id || '',
        city_id: editData?.city_id || '',
        address: editData?.address || '',
        logo: editData?.logo || '',
        banner: editData?.banner || '',
        status: editData?.status === 0 ? 0 : 1 || 1,
        opening_time: editData?.opening_time || new Date(),
        closing_time: editData?.closing_time || new Date(),
        form,
    };

    // if (form !== 'foodCourt') {
    //     validationSchema = validationSchema.shape({
    //         food_court_id: Yup.string().nullable(),
    //     });
    //     initialValues.food_court_id = null;
    // }

    const onSubmitHandler = async (values) => {
        if (apiHandler) apiHandler(values);
        return values;
    };
    return (
        <div>
            <CrudFormLayout
                initialValues={initialValues}
                buttonCenter
                validationSchema={validationSchema}
                apiHandler={onSubmitHandler}
            >
                <FoodCourtForm form={form} />
            </CrudFormLayout>
        </div>
    );
};

export default FoodCourtAndRestaurantFormContainer;
