import { useNavigate } from 'react-router-dom';
import PagesFormContainer from '../../../components/Forms/Websites/Pages/PagesFormContainer';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';

const AddPages = () => {
    const navigate = useNavigate();
    const addNewPageHandler = async (formValues) => {
        let imageData = null;
        if (formValues.page_banner) {
            const formData = new FormData();

            formData.append('page_banner', formValues.page_banner);

            imageData = await request.imageUpload('footer/website/image/upload', formData);
            console.log(imageData);
        }
        formValues.page_banner =
            imageData?.data?.find((file) => file?.fieldname === 'page_banner')?.filename || null;

        await request.create('pages', formValues, () => {
            navigate('/dashboard/website/pages');
        });
    };
    return (
        <CardLayout title='Add Pages'>
            <PagesFormContainer apiHandler={addNewPageHandler} />
        </CardLayout>
    );
};

export default AddPages;
