import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserTable from '../UserTable';
import CardLayout from '../../../../components/Layout/CardLayout';
import useFetch from '../../../../hooks/useFetch';
import request from '../../../../request/request';

const UserListPage = () => {
    const navigate = useNavigate();

    const {
        result: users,
        isLoading,
        setRefresh,
    } = useFetch(() => request.getAll('users/all-users'));
    // console.log(users);

    return (
        <div>
            <CardLayout
                title='User List'
                buttonTitle='Add New'
                addButtonHandler={() => navigate('/dashboard/users/add-user')}
            >
                <UserTable users={users} isLoading={isLoading} setRefresh={setRefresh} />
            </CardLayout>
        </div>
    );
};

export default UserListPage;
