import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import ModalLayout from '../Layout/ModalLayout';
import TitleInfo from '../Text/TitleInfo';
import ReactInput from '../InputList/ReactInput/ReactInput';
import CrudFormLayout from '../Layout/CrudFormLayout';
import request from '../../request/request';

const RestaurantCommissionModal = ({ isOpen, onClose, data, refresh }) => {
    const commistionSchema = Yup.object().shape({
        commission_rate: Yup.number().min(1).max(100).required('Commission is required'),
    });
    const initialValues = {
        commission_rate: data.commission_rate,
    };

    const onSubmtHandler = (values) =>
        request.updateOne('restaurants/commission', data.restaurant_id, values, () => {
            onClose();
            refresh();
        });

    return (
        <ModalLayout isOpen={isOpen} onClose={onClose} title='Food Court Commission' size='lg'>
            <CrudFormLayout
                initialValues={initialValues}
                validationSchema={commistionSchema}
                apiHandler={onSubmtHandler}
            >
                <Row>
                    <Col sm={12} md={6} className='mt-3'>
                        <TitleInfo title='Food Court Name' />
                    </Col>
                    <Col sm={12} md={6} className='mt-3'>
                        <TitleInfo description={data.name} />
                    </Col>

                    <Col sm={12} md={6} className='mt-3'>
                        <TitleInfo title='Mobile No ' />
                    </Col>
                    <Col sm={12} md={6} className='mt-3'>
                        <TitleInfo description={data.mobile} />
                    </Col>

                    <Col sm={12} md={6} className='mt-3'>
                        <TitleInfo title='Current Commission' />
                    </Col>
                    <Col sm={12} md={6} className='mt-3'>
                        <TitleInfo description={`${data?.commission_rate} %`} />
                    </Col>

                    <Col sm={12} md={6} className='mt-3'>
                        <TitleInfo title='Update Commission' />
                    </Col>
                    <Col sm={12} md={6} className='mt-3'>
                        <ReactInput name='commission_rate' type='number' />
                    </Col>
                </Row>
            </CrudFormLayout>
        </ModalLayout>
    );
};

export default RestaurantCommissionModal;
