import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import MissionVisionMottoFormContainer from '../../../components/Forms/Websites/MissionVissionMotto/MissionVisionMottoFormContainer';

const AddMissionVisionMotto = () => {
    const navigate = useNavigate();

    const addNewTeamMember = async (formValues) =>
        request.create('mission-vision-motto', formValues, () => {
            navigate('/dashboard/website/mission-vision-motto');
        });

    return (
        <CardLayout title='Add New Mission, Vision & Motto'>
            <MissionVisionMottoFormContainer apiHandler={addNewTeamMember} />
        </CardLayout>
    );
};

export default AddMissionVisionMotto;
