import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserFormContainer from '../../../components/Forms/Users/UserFormContainer';
import CardLayout from '../../../components/Layout/CardLayout';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import LoaderLayout from '../../../components/Loader/LoaderLayout';
import useContextData from '../../../hooks/useContextData';

const EditUser = () => {
    const { user_id } = useParams();

    const { currentUser } = useContextData();

    const navigate = useNavigate();

    const { result, isLoading } = useFetch(() => request.getOne('users/user', user_id), user_id);
    console.log(result, 'edit');

    const updateUserHandler = async (formValues) => {
        const { image, signature } = formValues;
        console.log(typeof image, typeof signature);

        // return null;

        let pro_image = result?.image;
        let pro_signature = result?.signature;

        // image upload
        if (typeof image === 'object') {
            const formData = new FormData();
            formData.append('image', image);

            console.log(formData);

            const { data, status } = await request.create('users/user/upload-image', formData);

            if (status === 'OK') {
                pro_image = data.filename;
            }
        }

        // signature upload

        if (typeof signature === 'object') {
            const formData = new FormData();
            formData.append('image', signature);

            const { data, status } = await request.create('users/user/upload-image', formData);

            if (status === 'OK') {
                pro_signature = data.filename;
            }
        }

        const uploadData = {
            ...formValues,
            image: pro_image,
            signature: pro_signature,
            updated_by: currentUser?.id,
        };

        await request.updateOne('users/user', user_id, uploadData, () => {
            // eslint-disable-next-line no-unused-expressions
            user_id
                ? navigate(`/dashboard/user/roles/${result?.role_id}`)
                : navigate('/dashboard/users');
        });

        console.log(uploadData);
        return null;
    };

    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Edit User'>
                <UserFormContainer editData={result} apiHandler={updateUserHandler} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default EditUser;
