import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Tabs } from 'react-bootstrap';
import { Tab } from 'bootstrap';
import CardLayout from '../../components/Layout/CardLayout';
import useFetch from '../../hooks/useFetch';
import request from '../../request/request';
import FoodCourtTableShow from '../../components/FoodCourt/FoodCourtTableShow';
import LoaderLayout from '../../components/Loader/LoaderLayout';
import FoodCourtAndRestaurantDetailsData from '../../components/FoodCourt/FoodCourtAndRestaurantDetailsData';
import FoodListByFoodCourt from '../../Routers/FoodList/FoodListByFoodCourt';
import BranchListShow from '../../components/FoodCourt/BranchListShow';
import FoodCourtTransecton from '../../components/FoodCourt/FoodCourtTransection';

const FoodCourtDetails = () => {
    const { foodCourtId } = useParams();

    const [activeTab, setActiveTab] = useState('food_court_details');

    // fetch product details
    const { result, isLoading } = useFetch(() => request.getOne('food-court/details', foodCourtId));

    return (
        <CardLayout title='Food Court Details'>
            <Tabs
                defaultActiveKey='food_court_details'
                variant='tabs'
                onSelect={(name) => setActiveTab(name)}
            >
                <Tab
                    eventKey='food_court_details'
                    title='Food Court Details'
                    tabClassName={`${
                        activeTab === 'food_court_details'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    <LoaderLayout isPending={isLoading}>
                        <FoodCourtAndRestaurantDetailsData detailsData={result} isFood />
                    </LoaderLayout>
                </Tab>

                <Tab
                    eventKey='resturant_list'
                    title='Resturant List'
                    tabClassName={`${
                        activeTab === 'resturant_list'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    <BranchListShow url={`branch-list/get-all/by-food-court/${foodCourtId}`} />
                </Tab>

                <Tab
                    eventKey='food_list'
                    title='Food List'
                    tabClassName={`${
                        activeTab === 'food_list'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    <FoodListByFoodCourt />
                </Tab>

                <Tab
                    eventKey='table_list'
                    title='Table List'
                    tabClassName={`${
                        activeTab === 'table_list'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    <FoodCourtTableShow />
                </Tab>

                <Tab
                    eventKey='transection'
                    title='Transection List'
                    tabClassName={`${
                        activeTab === 'transection'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    <FoodCourtTransecton />
                </Tab>
            </Tabs>
        </CardLayout>
    );
};

export default FoodCourtDetails;
