import { useFormikContext } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import FormLabel from '../../../Text/FormLabel';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import StatusCheck from '../../StatusCheck';

const ContactFroms = () => {
    const { values, setFieldValue } = useFormikContext();
    return (
        <Row>
            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Location' required />
                    <ReactInput name='location' as='textarea' rows={3} />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Email Address' required />
                    <ReactInput name='email_address' as='textarea' rows={3} />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Phone Numbers' required />
                    <ReactInput name='phone_numbers' as='textarea' rows={3} />
                </Form.Group>
            </Col>

            <Col sm={12} md={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Google Map Link' required />
                    <ReactInput name='google_map_link' as='textarea' rows={3} />
                </Form.Group>
            </Col>

            <StatusCheck />
        </Row>
    );
};

export default ContactFroms;
