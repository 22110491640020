import CardLayout from '../../../components/Layout/CardLayout';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';

const CourseRegistrationList = () => {
    const { result, isLoading } = useFetch(() => request.getAll('course-registration'));

    const columns = [
        {
            name: 'Course Name',
            wrap: true,
            center: 'true',
            selector: (row) => row?.course.title,
        },
        {
            name: 'User Name',
            wrap: true,
            center: 'true',
            selector: (row) => (
                <p>
                    {row?.f_name} {row?.l_name}{' '}
                </p>
            ),
        },
        {
            name: 'User Mobile',
            wrap: true,
            center: 'true',
            selector: (row) => row?.mobile,
        },
        {
            name: 'User Email',
            wrap: true,
            center: 'true',
            selector: (row) => row?.email,
        },
        {
            name: 'User Address',
            wrap: true,
            center: 'true',
            selector: (row) => row?.address_1,
        },
    ];
    return (
        <CardLayout title='Course Registration List'>
            <ReactDataTable columns={columns} data={result} pending={isLoading} />
        </CardLayout>
    );
};

export default CourseRegistrationList;
