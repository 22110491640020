export const tableConfig = {
    page_size: 10,
    show_filter: true,
    show_length_menu: true,
    show_pagination: true,
    pagination: 'advance',
    length_menu: [10, 50, 100],
    button: {
        excel: true,
        print: true,
        extra: true,
    },
};

export const tableCustomStyles = {
    headRow: {
        style: {
            fontSize: '15px', // Increase the font size of header titles
            // border: "1px solid #e0e0e0", // Add a border to the cells
            fontWeight: 'bold',
            backgroundColor: '#0e918a',
            color: 'white',
        },
    },
    headCells: {
        style: {
            borderLeft: '1px solid #e0e0e0',
            // border: "1px solid #e0e0e0", // Add a border to every cell of the head row
            overflowWrap: 'break-word', // Wrap and break text to the next line
        },
    },
    cells: {
        style: {
            fontSize: '14px', // Adjust the font size of cell content as needed
            borderLeft: '1px solid #e0e0e0', //
            padding: '4px',
            whiteSpace: 'pre-wrap', // Set white space to pre-wrap for all cells
        },
    },
    rows: {
        style: {
            borderRight: '1px solid #e0e0e0',
        },
    },
};
