import React, { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { ErrorMessage, useField } from 'formik';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ReactSelect from '../../ReactSelect/ReactSelect';

const SelectCountry = ({ ...props }) => {
    const { result: countryList, setRefresh } = useFetch(() => request.getAll('country'));
    const [field, meta, helper] = useField(props);

    return (
        <>
            <InputGroup>
                <ReactSelect
                    data={countryList}
                    value={field?.value}
                    valueName='id'
                    labelName='name'
                    onChange={(val) => helper.setValue(val)}
                />
            </InputGroup>

            <ErrorMessage name={props?.name} component='p' className='error' />
        </>
    );
};

export default SelectCountry;
