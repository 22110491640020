import { useNavigate, useParams } from 'react-router-dom';
import request from '../../request/request';
import useFetch from '../../hooks/useFetch';
import LoaderLayout from '../../components/Loader/LoaderLayout';
import CardLayout from '../../components/Layout/CardLayout';
import { formDataConverter } from '../../utils/necessaryFunctions';
import FoodCourtAndRestaurantFormContainer from '../../components/Forms/FoodCourtAndRestaurant/FoodCourtAndRestaurantFormContainer';

const UpdateRestaurant = () => {
    const navigate = useNavigate();
    const { restaurantId } = useParams();
    const { result, isLoading } = useFetch(
        () => request.getOne('restaurants', restaurantId),
        restaurantId
    );
    const updateRestaurantHandler = (values) =>
        request.updateOne('restaurants', restaurantId, formDataConverter(values), () => {
            navigate('/dashboard/restaurant');
        });
    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Update Restaurant'>
                <FoodCourtAndRestaurantFormContainer
                    apiHandler={updateRestaurantHandler}
                    editData={result}
                />
            </CardLayout>
        </LoaderLayout>
    );
};

export default UpdateRestaurant;
