import { Form, Table } from 'react-bootstrap';
import { FieldArray, useFormikContext } from 'formik';
import ReactInput from '../../InputList/ReactInput/ReactInput';

const BranchWiseFoodForm = () => {
    const { values, setFieldValue } = useFormikContext();
    return (
        <div>
            <Table striped bordered hover className='mt-3'>
                <thead>
                    <tr>
                        <th>Branch Name</th>
                        <th>Price</th>
                        <th>Discounted Price</th>
                    </tr>
                </thead>
                <tbody>
                    <FieldArray
                        name='branchFoodPrice'
                        render={() =>
                            values?.branchFoodPrice?.map((item, index) => (
                                <tr key={item?.branch_id}>
                                    <td>
                                        <Form.Check
                                            label={item?.name}
                                            checked={item?.status === 1}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    `branchFoodPrice.${index}.status`,
                                                    e.target.checked ? 1 : 0
                                                )
                                            }
                                            type='checkbox'
                                        />
                                    </td>
                                    <td>
                                        <ReactInput name={`branchFoodPrice.${index}.price`} />
                                    </td>
                                    <td>
                                        <ReactInput
                                            name={`branchFoodPrice.${index}.discounted_price`}
                                        />
                                    </td>
                                </tr>
                            ))
                        }
                    />
                </tbody>
            </Table>
        </div>
    );
};

export default BranchWiseFoodForm;
