import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import CardLayout from '../../../components/Layout/CardLayout';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import DeleteButton from '../../../components/Button/DeleteButton';
import EditButton from '../../../components/Button/EditButton';
import TitleInfo from '../../../components/Text/TitleInfo';
import DeleteModal from '../../../components/Modal/DeleteModal';

const Footer = () => {
    const navigate = useNavigate();
    const { result, isLoading, setRefresh } = useFetch(() => request.getAll('footer'));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    console.log(result);

    const columns = [
        {
            name: 'Logo',
            center: 'true',
            width: '200px',
            cell: (row) =>
                row?.logo && (
                    <img
                        style={{ width: '100%' }}
                        src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.logo}`}
                        alt='side Img'
                    />
                ),
        },
        {
            name: 'Description',
            wrap: true,
            center: 'true',
            selector: (row) => row?.description,
        },
        {
            name: 'Contact Info',
            cell: (row) => {
                const contact = DOMPurify.sanitize(row?.contact_info);
                return (
                    <div>
                        <TitleInfo title='Email' description={row?.email} className='mb-3' />
                        <TitleInfo title='Mobile' description={row?.mobile} className='mb-3' />
                        <TitleInfo title='Address' description={row?.address} className='mb-3' />
                        <TitleInfo
                            title='Latitude & Langtitude'
                            description={row?.latlng}
                            className='mb-3'
                        />
                        <div dangerouslySetInnerHTML={{ __html: contact }} />
                    </div>
                );
            },
        },
        {
            name: 'Social Icons',
            cell: (row) => (
                <div>
                    {row?.social_icons?.map((item) => (
                        <ul key={item?.id}>
                            <li>{item?.title}</li>
                        </ul>
                    ))}
                </div>
            ),
        },
        {
            name: 'Quick Links',
            cell: (row) => (
                <ul>
                    {row?.quick_links.map((item) => (
                        <li key={item?.id}>
                            <TitleInfo title='Title' description={item?.title} />
                            <TitleInfo title='Link' description={item?.link} />
                        </li>
                    ))}
                </ul>
            ),
        },
        {
            name: 'Bottom Bar ',
            cell: (row) => (
                <ul>
                    {row?.footer_bottom_bars?.map((item) => (
                        <li key={item?.id}>
                            <TitleInfo title='Title' description={item?.title} />
                            <TitleInfo title='Link' description={item?.link} />
                        </li>
                    ))}
                </ul>
            ),
        },
        {
            name: 'Action',
            center: 'true',
            width: '120px',
            cell: (row) => (
                <div>
                    <EditButton onClick={() => navigate(`edit/${row.id}`)} />
                    <DeleteButton
                        onClick={() => {
                            setIsModalOpen(true);
                            setSelectedItem(row);
                        }}
                    />
                </div>
            ),
        },
    ];

    const deleteFooterHandler = () =>
        request.delete('footer', selectedItem.id, () => {
            setIsModalOpen(false);
            setRefresh((prev) => !prev);
        });

    return (
        <CardLayout
            title='Footer List'
            buttonTitle='Add Footer'
            addButtonHandler={() => navigate('add')}
        >
            <ReactDataTable data={result} columns={columns} pending={isLoading} />

            {isModalOpen && (
                <DeleteModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    handleDelete={deleteFooterHandler}
                />
            )}
        </CardLayout>
    );
};

export default Footer;
