import MediaGalleryForm from '../../../components/Forms/Websites/MediaGalleryForm/MediaGalleryForm';
import CrudLayout from '../../../components/Layout/CrudLayout';

const VideoGallery = () => {
    const columns = [
        {
            name: 'Title',
            selector: (row) => row?.title,
            sortable: true,
        },
        {
            name: 'Image',
            center: 'true',
            wrap: true,
            width: '150px',
            cell: (row) => (
                <video
                    // controls
                    style={{ width: '100%' }}
                >
                    <source
                        src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.image}`}
                        type='video/mp4'
                    />
                    <track
                        src={`${process.env.REACT_APP_UPLOAD_URL}/website/captions_en.vtt`}
                        kind='captions'
                        srcLang='en'
                        label='English Captions'
                    />
                </video>
            ),
        },
        {
            name: 'Description',
            wrap: true,
            center: true,
            selector: (row) => row?.description,
        },
        {
            name: 'Status',
            selector: (row) => row?.status,
            sortable: 'true',
            center: 'true',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (data) => <span>{data.status === 1 ? 'Active' : 'Inactive'}</span>,
        },
    ];

    const initialValues = {
        title: '',
        image: '',
        associate_group: 'video_gallery',
        description: '',
        status: 1,
    };

    const config = {
        table_name: 'Video Gallery List',
        create_modal_title: 'Create New Video Gallery',
        update_modal_title: 'Update New Video Gallery',
        link: 'media-gallery',
        getLink: 'media-gallery?group=video_gallery',
        columns,
        form: <MediaGalleryForm hasAlbum />,
        initialValues,
        hasImage: true,
    };

    return <CrudLayout config={config} />;
};

export default VideoGallery;
