import React from 'react';
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const FormLabel = ({ title, required, htmlFor, className, isLoading, ...rest }) => (
    <Form.Label htmlFor={htmlFor} className={`fw-bold ${className} `} {...rest}>
        {title} {required && <span style={{ color: 'red' }}>* </span>}
        {isLoading && <Spinner animation='border' variant='primary' size='sm' />}
    </Form.Label>
);

export default FormLabel;
