import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import React, { useContext } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../Context/UserContextAPI';
import './Navbar.css';

const Navbar = () => {
    const { currentUser, accessPerm } = useContext(UserContext);
    const navigate = useNavigate();
    // console.log(currentUser);

    const handlerOnSubmit = (e) => {
        e.preventDefault();

        const form = e.target;
        const newPassword = form.newPassword.value;
        const confirmPassword = form.confirmPassword.value;

        if (newPassword && confirmPassword) {
            if (newPassword === confirmPassword) {
                const userInfo = {
                    mobile: currentUser?.mobile,
                    newPassword,
                    confirmPassword,
                };
                updatePassword(userInfo);
            } else {
                toast.error('Password Not Matched!!');
            }
        }
    };

    const updatePassword = async (userInfo) => {
        try {
            const res = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/user/password`,
                userInfo,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log(res);
            // const data = res.data;

            if (res.status === 200) {
                toast.success('Password Change Successfully!!');
            }
        } catch (error) {
            console.error(error);
            console.error(error.response.data.message);
            toast.error(error.response.data.message);
        }
    };
    // console.log(currentUser);

    return (
        <>
            <header className='top-header'>
                <nav className='navbar navbar-expand d-flex justify-content-between'>
                    <div className='mobile-toggle-icon d-xl-none'>
                        <i className='bi bi-list' />
                    </div>
                    <div className='top-navbar d-none d-xl-block'>
                        <ul className='navbar-nav align-items-center'>
                            <li className='nav-item'>
                                <Link className='nav-link' to='/dashboard'>
                                    Dashboard
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="search-toggle-icon d-xl-none ms-auto">
              <i className="bi bi-search" />
            </div> */}
                    {/* <form className="searchbar d-none d-xl-flex ms-auto">
              <div className="position-absolute top-50 translate-middle-y search-icon ms-3">
                <i className="bi bi-search" />
              </div>
              <input
                className="form-control"
                type="text"
                placeholder="Search by Mobile/Invoice/Registration No"
              />
              <div className="position-absolute top-50 translate-middle-y d-block d-xl-none search-close-icon">
                <i className="bi bi-x-lg" />
              </div>
            </form> */}
                    <div className='top-navbar-right ms-3'>
                        <ul className='navbar-nav align-items-center'>
                            <Dropdown className='nav-item dropdown-large  border-0' drop='down'>
                                <Dropdown.Toggle
                                    as='div'
                                    className='border-0 bg-transparent  nav-btn dropdown-toggle-nocaret '
                                >
                                    <div className='user-setting d-flex align-items-center gap-1'>
                                        {!currentUser || !currentUser?.image ? (
                                            <img
                                                className='user-img'
                                                src='/userimg.png'
                                                alt='Profile Pic'
                                            />
                                        ) : (
                                            <img
                                                className='user-img'
                                                src={`${process.env.REACT_APP_UPLOAD_URL}/users/${currentUser?.image}`}
                                                alt='Profile Pic'
                                            />
                                        )}

                                        {currentUser?.f_name && (
                                            <div className='user-name d-none d-sm-block'>
                                                {currentUser?.f_name} {currentUser?.l_name}
                                            </div>
                                        )}
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='mt-5'>
                                    <Dropdown.Item>
                                        <Link className='' to='/dashboard/profile'>
                                            <div className='d-flex align-items-center'>
                                                {!currentUser || !currentUser?.image ? (
                                                    <img
                                                        src='/userimg.png'
                                                        alt=''
                                                        className='rounded-circle'
                                                        width={60}
                                                        height={60}
                                                    />
                                                ) : (
                                                    <img
                                                        src={`${process.env.REACT_APP_UPLOAD_URL}/users/${currentUser?.image}`}
                                                        alt=''
                                                        className='rounded-circle'
                                                        width={60}
                                                        height={60}
                                                    />
                                                )}

                                                <div className='ms-3'>
                                                    <h6 className='mb-0 dropdown-user-name'>
                                                        {currentUser?.f_name} {currentUser?.l_name}
                                                    </h6>
                                                    <small className='mb-0 dropdown-user-designation text-secondary'>
                                                        {currentUser?.role?.name}
                                                    </small>
                                                </div>
                                            </div>
                                        </Link>
                                    </Dropdown.Item>
                                    <hr className='dropdown-divider' />

                                    <Dropdown.Item>
                                        {/* Change Password Triggered Modal Button */}
                                        <button
                                            type='button'
                                            className='dropdown-item'
                                            data-bs-toggle='modal'
                                            data-bs-target='#changeLoginUserPassword'
                                        >
                                            <div className='d-flex align-items-center'>
                                                <div className='setting-icon'>
                                                    <i className='bi bi-gear-fill' />
                                                </div>
                                                <div className='setting-text ms-3'>
                                                    <span>Change Password</span>
                                                </div>
                                            </div>
                                        </button>
                                    </Dropdown.Item>

                                    <hr className='dropdown-divider' />
                                    <Dropdown.Item>
                                        <button
                                            type='button'
                                            className='dropdown-item'
                                            onClick={() => {
                                                localStorage.removeItem('jwtToken');
                                                navigate('/signin');
                                            }}
                                        >
                                            <div className='d-flex align-items-center'>
                                                <div className='setting-icon'>
                                                    <i className='bi bi-lock-fill' />
                                                </div>
                                                <div className='setting-text ms-3'>
                                                    <span>Logout</span>
                                                </div>
                                            </div>
                                        </button>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown className='nav-item dropdown dropdown-large'>
                                <Dropdown.Toggle
                                    as='div'
                                    className='nav-link dropdown-toggle dropdown-toggle-nocaret'
                                >
                                    <div className='projects'>
                                        <i className='bi bi-grid-3x3-gap-fill' />
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dropdown-menu dropdown-menu-end'>
                                    <div className='row row-cols-3 gx-2'>
                                        {accessPerm(5, 4) && (
                                            <div className='col'>
                                                <Link to='/dashboard/users'>
                                                    <div className='apps p-2 radius-10 text-center'>
                                                        <div className='apps-icon-box mb-1 text-white bg-danger bg-gradient'>
                                                            <i className='bi bi-people-fill' />
                                                        </div>
                                                        <p className='mb-0 apps-name'>Users</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        )}

                                        <div className='col'>
                                            <Link to='/dashboard/profile'>
                                                <div className='apps p-2 radius-10 text-center'>
                                                    <div className='apps-icon-box mb-1 text-white bg-purple bg-gradient'>
                                                        <i className='bi bi-person-circle' />
                                                    </div>
                                                    <p className='mb-0 apps-name'>Account</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                    </div>
                </nav>
            </header>

            {/* Change Passsword Modal Body */}
            <div
                className='modal fade'
                id='changeLoginUserPassword'
                tabIndex='-1'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalLabel'>
                                Change Password
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            />
                        </div>
                        <div className='modal-body'>
                            <div className='card-body '>
                                <form className='form-body' onSubmit={(e) => handlerOnSubmit(e)}>
                                    <div className='row gap-3'>
                                        <div className='col-12'>
                                            <label
                                                htmlFor='inputNewPassword'
                                                className='form-label'
                                            >
                                                New Password
                                            </label>
                                            <div className='ms-auto position-relative'>
                                                <div className='position-absolute top-50 translate-middle-y search-icon px-3'>
                                                    <i className='bi bi-lock-fill' />
                                                </div>
                                                <input
                                                    type='password'
                                                    name='newPassword'
                                                    className='form-control radius-30 ps-5'
                                                    id='inputNewPassword'
                                                    placeholder='Enter New Password'
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <label
                                                htmlFor='inputConfirmPassword'
                                                className='form-label'
                                            >
                                                Confirm Password
                                            </label>
                                            <div className='ms-auto position-relative'>
                                                <div className='position-absolute top-50 translate-middle-y search-icon px-3'>
                                                    <i className='bi bi-lock-fill' />
                                                </div>
                                                <input
                                                    type='password'
                                                    name='confirmPassword'
                                                    className='form-control radius-30 ps-5'
                                                    id='inputConfirmPassword'
                                                    placeholder='Confirm Password'
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='d-grid gap-3'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary radius-30'
                                                    data-bs-dismiss='modal'
                                                >
                                                    Change Password
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
