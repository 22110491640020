import React, { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { ErrorMessage, useField } from 'formik';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ReactSelect from '../../ReactSelect/ReactSelect';

const SelectUserByRole = ({ role_id, ...props }) => {
    const [field, meta, helper] = useField(props);

    const { result: users } = useFetch(() => request.getAll(`users/role/${role_id}`), role_id);

    return (
        <>
            <InputGroup>
                <ReactSelect
                    data={users}
                    value={field?.value}
                    valueName='id'
                    labelName='f_name'
                    onChange={(val) => helper.setValue(val)}
                />
            </InputGroup>

            <ErrorMessage name={props?.name} component='p' className='error' />
        </>
    );
};

export default SelectUserByRole;
