import { useNavigate, useParams } from 'react-router-dom';
import request from '../../request/request';
import useFetch from '../../hooks/useFetch';
import LoaderLayout from '../../components/Loader/LoaderLayout';
import CardLayout from '../../components/Layout/CardLayout';
import { formDataConverter } from '../../utils/necessaryFunctions';
import FoodCourtAndRestaurantFormContainer from '../../components/Forms/FoodCourtAndRestaurant/FoodCourtAndRestaurantFormContainer';

const UpdateFoodCourt = () => {
    const navigate = useNavigate();
    const { foodCourtId } = useParams();
    const { result, isLoading } = useFetch(
        () => request.getOne('food-court', foodCourtId),
        foodCourtId
    );
    const UpdateFoodHandler = (values) => {
        console.log(values);
        values.opening_time = values.opening_time.toLocaleString();
        values.closing_time = values.closing_time.toLocaleString();
        return request.updateOne('food-court', foodCourtId, formDataConverter(values), () => {
            navigate('/dashboard/food-court');
        });
    };
    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Update Food Court'>
                <FoodCourtAndRestaurantFormContainer
                    form='foodCourt'
                    apiHandler={UpdateFoodHandler}
                    editData={result}
                />
            </CardLayout>
        </LoaderLayout>
    );
};

export default UpdateFoodCourt;
