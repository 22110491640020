import { useFormikContext } from 'formik';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa6';
import ReactDataTable from '../ReactDataTable/ReactDataTable';
import DeleteSingleItems from '../Modal/DeleteSingleItems';
import ReactInput from '../InputList/ReactInput/ReactInput';

const BranchTableAdd = ({ arrayHelpers }) => {
    const { values, initialValues } = useFormikContext();
    const columns = [
        {
            name: 'Table No',
            cell: (_, i) => (
                <ReactInput name={`tableData[${i}.table_no]`} placeholder='Table No :' />
            ),
        },

        {
            name: 'Capacity',
            cell: (_, i) => (
                <ReactInput
                    name={`tableData[${i}.capacity]`}
                    placeholder='Capacity :'
                    type='number'
                />
            ),
        },
        {
            name: 'Remove',
            center: 'true',
            cell: (_, i) => <DeleteSingleItems handleDelete={() => arrayHelpers.remove(i)} />,
        },
    ];

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <h5>Branch Table</h5>
                <Button
                    variant='teal'
                    onClick={() =>
                        arrayHelpers.push({
                            branch_id: initialValues?.branch_id,
                            table_no: '',
                            capacity: '',
                        })
                    }
                >
                    <FaPlus />
                </Button>
            </div>
            <ReactDataTable columns={columns} data={values?.tableData || []} />
        </div>
    );
};

export default BranchTableAdd;
