import React, { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ReactSelect from '../../ReactSelect/ReactSelect';

const SelectState = ({ dependedCountryValueId, nested, ...props }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [field, meta, helper] = useField(props);
    const { values } = useFormikContext();

    const { result: stateValue, setRefresh } = useFetch(
        () =>
            request.getAll(
                `city/state/data/${
                    nested
                        ? values[nested]?.[dependedCountryValueId]
                        : values[dependedCountryValueId]
                }`
            ),
        nested ? values[nested]?.[dependedCountryValueId] : values[dependedCountryValueId]
    ); // this will only till work two nested . like values.country_id or values[something].country_id

    return (
        <>
            <InputGroup>
                <ReactSelect
                    data={stateValue}
                    value={field?.value}
                    valueName='id'
                    labelName='name'
                    onChange={(val) => helper.setValue(val)}
                />
            </InputGroup>

            <ErrorMessage name={props?.name} component='p' className='error' />
        </>
    );
};

export default SelectState;
