import { useNavigate } from 'react-router-dom';
import request from '../../request/request';
import CardLayout from '../../components/Layout/CardLayout';
import CouponFormContainer from '../../components/Forms/Coupon/CouponFormContainer';

const AddCoupon = () => {
    const navigate = useNavigate();
    const addCouponHandler = (values) =>
        request.create('coupon', values, () => {
            navigate('/dashboard/coupon-list');
        });
    return (
        <CardLayout title='Add Coupon'>
            <CouponFormContainer apiHandler={addCouponHandler} />
        </CardLayout>
    );
};

export default AddCoupon;
