import { useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';

const StatusCheck = () => {
    const { values, setFieldValue } = useFormikContext();
    return (
        <Form.Group className='d-flex justify-content-end mt-3'>
            <Form.Check
                label='Active'
                checked={values?.status === 1}
                onChange={(e) => setFieldValue('status', e.target.checked ? 1 : 0)}
            />
        </Form.Group>
    );
};

export default StatusCheck;
