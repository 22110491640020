import { useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import ReactSelect from '../ReactSelect/ReactSelect';
import FormLabel from '../Text/FormLabel';
import { statusData } from '../../data/status';

const StatusCheckField = () => {
    const { values, setFieldValue } = useFormikContext();
    return (
        <Form.Group className=' mt-3'>
            <FormLabel title='Status' required />
            <ReactSelect
                data={statusData}
                labelName='title'
                valueName='id'
                value={values?.status}
                onChange={(val) => setFieldValue('status', val)}
            />
        </Form.Group>
    );
};

export default StatusCheckField;
