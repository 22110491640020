import { useParams } from 'react-router-dom';
import { useState } from 'react';
import FoodForm from '../Forms/FoodCourtAndRestaurant/FoodForm';
import CrudLayout from '../Layout/CrudLayout';
import useFetch from '../../hooks/useFetch';
import request from '../../request/request';
import CardLayout from '../Layout/CardLayout';
import ReactDataTable from '../ReactDataTable/ReactDataTable';
import ModalLayout from '../Layout/ModalLayout';
import FoodFormContainer from '../Forms/foodForm/FoodFormContainer';
import EditButton from '../Button/EditButton';
import DeleteButton from '../Button/DeleteButton';
import DeleteModal from '../Modal/DeleteModal';

const FoodList = () => {
    const { restaurantId } = useParams();
    const {
        result: foodList,
        isLoading,
        setRefresh,
    } = useFetch(() => request.getAll(`food-list?restaurantId=${restaurantId}`));

    const { result: foodCategory } = useFetch(() => request.getAll('food-category?status=active'));

    const { result: branchList } = useFetch(
        () => request.getAllById(`branch-list/restaurant`, restaurantId),
        restaurantId
    );

    const [modalShow, setModalShow] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);

    const columns = [
        {
            name: 'Food Name',
            selector: (row) => row?.name,
            wrap: true,
            center: 'true',
        },
        {
            name: 'Food Category',
            selector: (row) => row?.food_cat_id,
            wrap: true,
            center: 'true',
        },

        {
            name: 'Image',
            wrap: true,
            width: '150px',
            cell: (row) => (
                <img
                    style={{ width: '100%' }}
                    src={`${process.env.REACT_APP_UPLOAD_URL}/food/${row?.image}`}
                    alt='side Img'
                />
            ),
        },
        {
            name: 'Description',
            wrap: true,
            center: true,
            selector: (row) => row?.description,
        },
        {
            name: 'Status',
            selector: (row) => row?.status,
            sortable: 'true',
            center: 'true',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (data) => <span>{data.status === 1 ? 'Active' : 'Inactive'}</span>,
        },
        {
            name: 'Action',
            sortable: 'false',
            center: 'true',
            cell: (data) => (
                <>
                    <EditButton
                        onClick={() => {
                            setModalShow('update');
                            setSelectedItem(data);
                        }}
                    />
                    <DeleteButton
                        onClick={() => {
                            setModalShow('delete');
                            setSelectedItem(data);
                        }}
                    />
                </>
            ),
        },
    ];
    const onClose = () => {
        setModalShow('');
        setSelectedItem(null);
    };
    const createFoodHandler = (value) =>
        request.create('food-list', value, () => {
            onClose();
            setRefresh((prev) => !prev);
        });

    // update food

    const updateFoodHandler = (value) =>
        request.updateOne('food-list', selectedItem?.id, value, () => {
            onClose();
            setRefresh((prev) => !prev);
        });

    const handleDelete = () =>
        request.delete('food-list', selectedItem?.id, () => {
            onClose();
            setRefresh((prev) => !prev);
        });
    return (
        <CardLayout
            title='Food List'
            buttonTitle='Add new Food'
            addButtonHandler={() => setModalShow('create')}
        >
            <ReactDataTable columns={columns} data={foodList} pending={isLoading} />
            {modalShow === 'create' && (
                <ModalLayout
                    isOpen={modalShow === 'create'}
                    onClose={onClose}
                    size='lg'
                    title='Add new Food'
                >
                    <FoodFormContainer
                        branchList={branchList}
                        apiHandler={createFoodHandler}
                        foodCategory={foodCategory}
                    />
                </ModalLayout>
            )}

            {modalShow === 'update' && (
                <ModalLayout
                    isOpen={modalShow === 'update'}
                    onClose={onClose}
                    size='lg'
                    title='Update Food'
                >
                    <FoodFormContainer
                        branchList={branchList}
                        apiHandler={updateFoodHandler}
                        editData={selectedItem}
                        foodCategory={foodCategory}
                    />
                </ModalLayout>
            )}

            {/* deletel  */}

            {modalShow === 'delete' && (
                <DeleteModal
                    handleDelete={handleDelete}
                    isOpen={modalShow === 'delete'}
                    onClose={onClose}
                />
            )}
        </CardLayout>
    );
};

export default FoodList;
