import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa6';
import useFetch from '../../hooks/useFetch';
import request from '../../request/request';
import DeleteModal from '../Modal/DeleteModal';
import EditButton from '../Button/EditButton';
import DeleteButton from '../Button/DeleteButton';
import CardLayout from './CardLayout';
import ReactDataTable from '../ReactDataTable/ReactDataTable';
import ModalLayout from './ModalLayout';
import CrudFormLayout from './CrudFormLayout';
import { formDataConverter } from '../../utils/necessaryFunctions';

const CrudLayout = ({ config }) => {
    const [modalOpen, setModalOpen] = useState(''); // create, update, delete
    const [selectedItemId, setSelectedItemId] = useState('');
    const [initialValues, setInitialValues] = useState(config?.initialValues);

    // data fetch

    const { result, isLoading, isSuccess, setRefresh } = useFetch(() =>
        request.getAll(config?.getLink || config?.link)
    );

    const handlerOnDelete = async () => {
        await request.delete(config?.link, selectedItemId, () => {
            setRefresh((prevState) => !prevState);
            setModalOpen('');
        });
    };

    const handleEdit = (data) => {
        setSelectedItemId(data.id);
        setModalOpen('update');
        setInitialValues(data);
    };

    const actionSection = {
        name: 'Action',
        sortable: 'false',
        center: 'true',
        cell: (data) => (
            <>
                {/* Button trigger modal */}
                {config?.detailsButtonWithLink && (
                    <Link to={`/${config?.detailsButtonWithLink}/${data?.id}`}>
                        <Button variant='warning' size='sm' className='me-3'>
                            <FaEye size={18} />
                        </Button>
                    </Link>
                )}

                <EditButton onClick={() => handleEdit(data)} />
                <DeleteButton
                    onClick={() => {
                        setSelectedItemId(data.id);
                        setModalOpen('delete');
                    }}
                />
            </>
        ),
    };

    const modalClose = () => setModalOpen('');

    const apiRefresh = () => setRefresh((prevState) => !prevState);

    const updateHandler = async (formValues) => {
        let formData = formValues;
        console.log(formValues);
        if (config?.hasImage) {
            formData = formDataConverter(formValues);
        }
        await request.updateOne(config?.link, formValues?.id, formData, () => {
            modalClose();
            apiRefresh();
        });
    };

    const createNewCategory = async (formValues) => {
        let formData = formValues;
        if (config?.hasImage) {
            formData = formDataConverter(formValues);
        }
        console.log(formData);
        return request.create(config?.link, formData, () => {
            modalClose();
            apiRefresh();
        });
    };

    const apiHandler = async (values) => {
        if (modalOpen === 'create') {
            await createNewCategory(values);
        } else if (modalOpen === 'update') {
            await updateHandler(values);
        }
    };

    return (
        <section>
            {/* Content show */}

            <CardLayout
                title={config?.table_name}
                buttonTitle={config?.create_modal_title}
                addButtonHandler={() => {
                    setModalOpen('create');
                    setInitialValues(config?.initialValues);
                }}
            >
                <ReactDataTable
                    columns={[...config.columns, actionSection]}
                    data={isSuccess ? result : []}
                    pending={isLoading}
                />
            </CardLayout>

            {/* create modal */}
            {(modalOpen === 'create' || modalOpen === 'update') && (
                <ModalLayout
                    title={
                        modalOpen === 'create'
                            ? config?.create_modal_title
                            : config?.update_modal_title
                    }
                    onClose={modalClose}
                    isOpen={modalOpen === 'create' || modalOpen === 'update'}
                    size='lg'
                >
                    <CrudFormLayout
                        apiHandler={apiHandler}
                        onClose={modalClose}
                        initialValues={initialValues}
                    >
                        {config.form}
                    </CrudFormLayout>
                </ModalLayout>
            )}

            {/* delete modal */}
            {modalOpen === 'delete' && (
                <DeleteModal
                    isOpen={modalOpen === 'delete'}
                    onClose={modalClose}
                    handleDelete={handlerOnDelete}
                />
            )}
        </section>
    );
};

export default CrudLayout;
