import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MenuItem = ({ iconClass, title, children }) => {
    const [expanded, setExpanded] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggle = (event) => {
        event.preventDefault();
        setExpanded(!expanded);
    };

    return (
        <li>
            <Link
                to='#'
                className={`has-arrow ${expanded ? 'expanded' : ''}`}
                onClick={handleToggle}
            >
                <div className='parent-icon'>
                    <i className={iconClass} />
                </div>
                <div className='menu-title'>{title}</div>
            </Link>
            {expanded && <ul className='submenu-expanded'>{children}</ul>}
        </li>
    );
};
export default MenuItem;
