import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import FormLabel from '../../Text/FormLabel';
import ReactInput from '../../InputList/ReactInput/ReactInput';

const InputGroup = ({ InputData }) => (
    <div>
        {InputData.map((data) => (
            <Form.Group className='mt-3' key={data.id} as={Row}>
                <Col sm={12} md={4} lg={3}>
                    <FormLabel title={data.label} required={data.required} className='text-end' />
                </Col>
                <Col sm={12} md={8} lg={9}>
                    {data.type !== 'select' ? (
                        <ReactInput name={data.name} type={data.type} />
                    ) : (
                        data.component
                    )}
                </Col>
            </Form.Group>
        ))}
    </div>
);

export default InputGroup;
