import { useNavigate, useParams } from "react-router-dom";
import CardLayout from "../../../components/Layout/CardLayout";
import TeamMembersContainer from "../../../components/Forms/Websites/TeamMembers/TeamMembersContainer";
import useFetch from "../../../hooks/useFetch";
import request from "../../../request/request";
import LoaderLayout from "../../../components/Loader/LoaderLayout";

const EditTeamMember = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { result, isLoading } = useFetch(
    () => request.getOne("team-members", id),
    id,
  );

  const updateMember = async (formValues) => {
    try {
      let imageData = null;
      if (formValues.image?.name) {
        // only uploaded image has name
        const formData = new FormData();

        formData.append("image", formValues.image);

        imageData = await request.imageUpload(
          "footer/website/image/upload",
          formData,
        );
        console.log(imageData);
      }
      formValues.image =
        imageData?.data?.find((file) => file?.fieldname === "image")
          ?.filename || null;

      return request.updateOne("team-members", result.id, formValues, () => {
        navigate("/dashboard/website/team-members");
      });
    } catch (err) {
      return err;
    }
  };

  const editDataSorting = {
    ...result,
    iconData: result?.social_icons,
  };

  return (
    <LoaderLayout isPending={isLoading}>
      <CardLayout title="Edit Team Member">
        <TeamMembersContainer
          apiHandler={updateMember}
          editData={editDataSorting}
        />
      </CardLayout>
    </LoaderLayout>
  );
};

export default EditTeamMember;
