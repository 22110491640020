import CrudFormLayout from '../../../Layout/CrudFormLayout';
import MissionVisionMottoForm from './MissionVisionMottoForm';

const MissionVisionMottoFormContainer = ({ apiHandler, editData }) => {
    const initialValues = {
        mission_message: '',
        vision_message: '',
        motto_message: '',
        status: 1,
    };

    const formHandler = (formVales) => {
        if (apiHandler) {
            return apiHandler(formVales);
        }

        console.log(formVales);

        return null;
    };

    return (
        <CrudFormLayout
            initialValues={editData || initialValues}
            apiHandler={formHandler}
            buttonCenter
        >
            <MissionVisionMottoForm />
        </CrudFormLayout>
    );
};

export default MissionVisionMottoFormContainer;
