import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import FormLabel from '../../Text/FormLabel';
import ReactInput from '../../InputList/ReactInput/ReactInput';
import SelectCountry from '../../InputList/SelectableInput/SelectCountry';
import SelectState from '../../InputList/SelectableInput/SelectState';
import SelectCity from '../../InputList/SelectableInput/SelectCity';
import StatusCheckField from '../StatusCheckField';
import SelectUserByRole from '../../InputList/SelectableInput/SelectUserByRole';
import SelectFoodCourt from '../../InputList/SelectableInput/SelectFoodCourt';
import SelectRestaurant from '../../InputList/SelectableInput/SelectRestaurant';
import ReactDatePicker from '../../ReactDatePicker/ReactDatePicker';
import ReactSelect from '../../ReactSelect/ReactSelect';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';

const BranchForm = () => {
    const { values, setFieldValue } = useFormikContext();

    const { result: restaurantsData } = useFetch(() => request.getAll('restaurants?status=active'));

    const selectRestaurantHandler = (val) => {
        const selectedRestaurant = restaurantsData.find((item) => item.restaurant_id === val);
        setFieldValue('restaurant_id', val);
        setFieldValue('contact_person', selectedRestaurant.contact_person);
        setFieldValue('contact_number', selectedRestaurant.contact_number);
    };

    return (
        <Row>
            <Col sm={12} md={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Select FoodCourt' />
                    <SelectFoodCourt name='food_court_id' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Select Restaurant' required />
                    <ReactSelect
                        data={restaurantsData}
                        value={values.restaurant_id}
                        valueName='restaurant_id'
                        labelName='name'
                        onChange={selectRestaurantHandler}
                    />
                </Form.Group>
            </Col>

            <Col sm={12} md={6} className='mt-3'>
                <Form.Group>
                    <FormLabel title='Branch User' required />
                    <SelectUserByRole name='user_id' role_id={9} />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Branch Name' required />
                    <ReactInput name='name' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Mobile Number' required />
                    <ReactInput name='mobile' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Contact Person' required />
                    <ReactInput name='contact_person' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Contact Number' />
                    <ReactInput name='contact_number' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Country' required />
                    <SelectCountry name='country_id' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='State' required />
                    <SelectState name='state_id' dependedCountryValueId='country_id' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='City' required />
                    <SelectCity name='city_id' dependedStateValueId='state_id' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Address' required />
                    <ReactInput as='textarea' row={3} name='address' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Opening Time ' />
                    <ReactDatePicker
                        showTimeSelectOnly
                        showTimeSelect
                        timeIntervals={15}
                        dateFormat=' h:mm aa'
                        value={values.opening_time}
                        onChange={(date) => setFieldValue('opening_time', date)}
                    />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Closing Time' />
                    <ReactDatePicker
                        showTimeSelectOnly
                        showTimeSelect
                        timeIntervals={15}
                        dateFormat=' h:mm aa'
                        value={values.closing_time}
                        onChange={(date) => setFieldValue('closing_time', date)}
                    />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <StatusCheckField />
                </Form.Group>
            </Col>
        </Row>
    );
};
export default BranchForm;
