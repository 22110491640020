import { Col, Form, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import FormLabel from '../../../Text/FormLabel';
import StatusCheck from '../../StatusCheck';
import ReactTextEditor from '../../../Editors/ReactTextEditor';

const CeoMessageForm = () => {
    const { values, setFieldValue } = useFormikContext();

    console.log(values?.message);

    return (
        <div>
            <Row>
                <Col sm={12} md={6} className='mt-3'>
                    <Form.Group>
                        <FormLabel title='Name' />
                        <ReactInput name='name' />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <Form.Group>
                        <FormLabel title='Title' />
                        <ReactInput name='title' />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <Form.Group>
                        <FormLabel title='Image' />
                        <ReactInput name='image' type='file' />
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col sm={12} className='mt-3'>
                    <FormLabel title='Message' />
                    <ReactTextEditor
                        defaultData={values?.message}
                        setEditorData={(v) => setFieldValue('message', v)}
                    />
                </Col>

                <StatusCheck />
            </Row>
        </div>
    );
};

export default CeoMessageForm;
