import CrudFormLayout from "../../../Layout/CrudFormLayout";
import AboutUsForm from "./AboutUsForm";

const AboutUsFormContainer = ({ apiHandler, editData }) => {
  const initialValue = {
    title: editData?.title || "",
    side_img: editData?.side_img || "",
    middle_img: editData?.bottom_img || "",
    circle_imgimg: editData?.circle_img || "",
    button_title: editData?.button_title || "",
    description: editData?.description || "",
    status: editData ? editData?.status : 1,
  };

  const formHandler = async (formData) => {
    if (apiHandler) {
      return apiHandler(formData);
    }
    return null;
  };
  return (
    <CrudFormLayout
      initialValues={initialValue}
      apiHandler={formHandler}
      buttonCenter
    >
      <AboutUsForm />
    </CrudFormLayout>
  );
};

export default AboutUsFormContainer;
