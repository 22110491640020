import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import CardLayout from '../../../components/Layout/CardLayout';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import EditButton from '../../../components/Button/EditButton';
import DeleteButton from '../../../components/Button/DeleteButton';
import DeleteModal from '../../../components/Modal/DeleteModal';
import DetailsButton from '../../../components/Button/DetailsButton';
import BlogsDetailsModal from '../../../components/Blogs/BlogsDetailsModal';

const BlogList = () => {
    const { result, isLoading, setRefresh } = useFetch(() => request.getAll('blogs'));
    const navigate = useNavigate();

    console.log(result);

    const [modalOpen, setModalOpen] = useState('');
    const [selectedItem, setSelectedItem] = useState('');

    const columns = [
        {
            name: 'Title',
            wrap: true,
            center: 'true',
            selector: (row) => row?.title,
        },
        {
            name: 'Blog Category',
            wrap: true,
            center: 'true',
            selector: (row) => row?.blog_category?.map((item) => item?.title).join(', '),
        },
        {
            name: 'Blog Images',
            wrap: true,
            width: '150px',
            cell: (row) => (
                <img
                    style={{ width: '100%' }}
                    src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.blog_img}`}
                    alt='side Img'
                />
            ),
        },

        // {
        //   name: "Sort Description",
        //   wrap: true,
        //   center: "true",
        //   selector: (row) => row?.sort_description,
        // },
        // {
        //   name: "Description",
        //   wrap: true,
        //   center: "true",
        //   cell: (row) => (
        //     <div dangerouslySetInnerHTML={{ __html: row?.long_description }} />
        //   ),
        // },

        {
            name: 'Status',
            center: 'true',
            selector: (row) => (row?.status === 1 ? 'Active' : 'InActive'),
        },
        {
            name: 'Action',
            center: 'true',
            cell: (row) => (
                <div>
                    <DetailsButton
                        onClick={() => {
                            setModalOpen('details');
                            setSelectedItem(row);
                        }}
                    />
                    <EditButton
                        onClick={() => navigate(`/dashboard/website/blogs/edit/${row?.id}`)}
                    />
                    <DeleteButton
                        onClick={() => {
                            setModalOpen('delete');
                            setSelectedItem(row);
                        }}
                    />
                </div>
            ),
        },
    ];

    // delete function

    const abotUsDeleteHandler = () =>
        request.delete('blogs', selectedItem?.id, () => {
            setModalOpen('');
            setRefresh((prev) => !prev);
        });

    return (
        <CardLayout
            title='Blog List'
            buttonTitle='Add New Blog'
            addButtonHandler={() => navigate('/dashboard/website/blogs/add')}
        >
            <ReactDataTable columns={columns} data={result} pending={isLoading} />

            {/* delete about us */}

            {modalOpen === 'delete' && (
                <DeleteModal
                    isOpen={modalOpen === 'delete'}
                    onClose={() => setModalOpen('')}
                    handleDelete={abotUsDeleteHandler}
                />
            )}

            {/* blog details modal  */}

            {modalOpen === 'details' && (
                <BlogsDetailsModal
                    isOpen={modalOpen === 'details'}
                    onClose={() => {
                        setModalOpen('');
                        setSelectedItem('');
                    }}
                    blogData={selectedItem}
                />
            )}
        </CardLayout>
    );
};

export default BlogList;
