import React from "react";
import { Modal } from "react-bootstrap";

const BlogsDetailsModal = ({ blogData, isOpen, onClose }) => (
  <Modal show={isOpen} onHide={onClose} size="lg">
    <Modal.Body>
      <div>
        <h5>Short Description :</h5>

        <p> {blogData.short_description} </p>
      </div>
      <div>
        <h5> Full Description </h5>

        <div dangerouslySetInnerHTML={{ __html: blogData.long_description }} />
      </div>
    </Modal.Body>
  </Modal>
);

export default BlogsDetailsModal;
