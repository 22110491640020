import { useNavigate, useParams } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import useFetch from '../../../hooks/useFetch';
import LoaderLayout from '../../../components/Loader/LoaderLayout';
import CourseFormContainer from '../../../components/Forms/Websites/Courses/CourseFormContainer';
import { formDataConverter } from '../../../utils/necessaryFunctions';

const UpdateCourses = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { result, isLoading } = useFetch(() => request.getOne('courses', id));

    const UpdateAboutUsHandler = (formValue) => {
        const formData = formDataConverter(formValue);

        return request.updateOne('courses', id, formData, () => {
            navigate('/dashboard/website/courses');
        });
    };
    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Update Courses'>
                <CourseFormContainer apiHandler={UpdateAboutUsHandler} editData={result} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default UpdateCourses;
