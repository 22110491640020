import { FieldArray } from 'formik';
import * as Yup from 'yup';
import CrudFormLayout from '../Layout/CrudFormLayout';
import ModalLayout from '../Layout/ModalLayout';
import BranchTableAdd from './BranchTableAdd';
import request from '../../request/request';

const BranchTableAddModal = ({ isOpen, onClose, branchData, refresh }) => {
    const initalValues = {
        branch_id: branchData.branch_id,
        tableData:
            branchData.tableData ||
            [
                // {
                //     table_no: 23,
                //     capacity: 33,
                // },
            ],
    };
    const tableSchema = Yup.object().shape({
        branch_id: Yup.string().required('Branch is required'),
        tableData: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number().optional().nullable(),
                    branch_id: Yup.string().required('Branch Id is required'),
                    table_no: Yup.string().required('Table no is required'),
                    capacity: Yup.number().required('Capacity is required'),
                })
            )
            .required('Table data is required'),
    });

    const submitHandler = async (values) =>
        request.create(`table-list/branch/${branchData.branch_id}`, values.tableData, () => {
            onClose();
            refresh();
        });

    return (
        <ModalLayout isOpen={isOpen} onClose={onClose} size='lg'>
            <CrudFormLayout
                initialValues={initalValues}
                apiHandler={submitHandler}
                validationSchema={tableSchema}
            >
                <FieldArray
                    name='tableData'
                    render={(arrayHelpers) => <BranchTableAdd arrayHelpers={arrayHelpers} />}
                />
            </CrudFormLayout>
        </ModalLayout>
    );
};

export default BranchTableAddModal;
