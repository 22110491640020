function isValidDate(dateString) {
    // Attempt to create a Date object from the dateString
    const date = new Date(dateString);

    // Check if the date is valid
    // Date.parse() returns NaN for invalid dates
    // Also, invalid dates can have a time value of NaN
    return !Number.isNaN(date.getTime());
}

export default isValidDate;
