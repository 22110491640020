import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalLayout = ({ isOpen, onClose, children, title, ...rest }) => (
    <Modal show={isOpen} onHide={onClose} {...rest}>
        <Modal.Header closeButton>
            <h6>{title}</h6>
        </Modal.Header>

        <Modal.Body>{children}</Modal.Body>
    </Modal>
);

export default ModalLayout;
