import { useNavigate, useParams } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import LoaderLayout from '../../../components/Loader/LoaderLayout';
import ContactAddFromContainer from '../../../components/Forms/Websites/Contact/ContactAddFromContainer';

const EditContact = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { result, isLoading } = useFetch(() => request.getOne('contact', id), id);

    const updateContact = (formValue) =>
        request.updateOne('contact', id, formValue, () => {
            navigate('/dashboard/website/contact');
        });

    console.log(result);

    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Edit Contact'>
                <ContactAddFromContainer apiHandler={updateContact} editData={result} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default EditContact;
