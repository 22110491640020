import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { UserContext } from '../../Context/UserContextAPI';
import CrudFormLayout from '../../components/Layout/CrudFormLayout';
import SignInForm from '../../components/Forms/Auth/SignInForm';

const Signin = () => {
    const { methodSignin, settingData } = useContext(UserContext);
    const navigate = useNavigate();

    const signinSchema = Yup.object().shape({
        mobile: Yup.string().required('Please enter your phone number'),
        password: Yup.string().required('Please enter your password'),
        rememberMe: Yup.boolean().optional(),
    });

    const initialValues = {
        mobile: '',
        password: '',
        rememberMe: false,
    };

    const onSubmitHandler = async (values) => {
        console.log(values);

        const res = await methodSignin(values);
        console.log('res', res);
        if (res.status === 'OK') {
            navigate('/dashboard');
        }
        return res;
    };

    return (
        <>
            {/* Signin Page Container */}
            <div className='wrapper'>
                {/* <!--start content--> */}
                <div className='container-fluid'>
                    <div className='vh-100 d-flex align-items-center justify-content-center'>
                        <div
                            className='card mb-0 shadow d-flex flex-row align-items-center justify-content-center overflow-hidden p-2'
                            style={{ width: '70%', height: '75%' }}
                        >
                            <div className='col-lg-6 d-flex align-items-center justify-content-center'>
                                <img src='/img/erp-banner.png' className='img-fluid' alt='' />
                            </div>
                            <div className='col-lg-6'>
                                <div className='card-body p-4 p-sm-5'>
                                    <div className='w-100'>
                                        <h5 className='card-title'>Sign In</h5>
                                        <p className='card-text'>
                                            Please enter a valid Phone number and password.
                                        </p>
                                    </div>

                                    <CrudFormLayout
                                        initialValues={initialValues}
                                        validationSchema={signinSchema}
                                        apiHandler={onSubmitHandler}
                                        noButton
                                    >
                                        <div className='login-separater text-center'>
                                            <span>SIGN IN WITH Phone Number</span>
                                            <hr />
                                        </div>
                                        <SignInForm />
                                    </CrudFormLayout>
                                </div>
                                <img
                                    src={`${process.env.REACT_APP_UPLOAD_URL}/${settingData?.logo_image}`}
                                    width='150px'
                                    height='150px'
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        width: '120px',
                                        objectFit: 'contain',
                                        marginTop: '0px' /* Adjust margin top as needed */,
                                    }}
                                    alt=''
                                    className=''
                                />
                                <p className='text-end'>
                                    Powered By{' '}
                                    <Link to='https://dhakaapps.com/' target='_blank'>
                                        DHAKA APPS{' '}
                                    </Link>{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signin;
