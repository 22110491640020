import { Form, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import FormLabel from '../../Text/FormLabel';
import ReactSelect from '../../ReactSelect/ReactSelect';
import ReactInput from '../../InputList/ReactInput/ReactInput';
import StatusCheckField from '../StatusCheckField';
import BranchWiseFoodForm from './BranchWiseFoodPriceForm';

const FoodForm = ({ foodCategory }) => {
    const { values, setFieldValue } = useFormikContext();
    // console.log(values);
    return (
        <Row>
            <Form.Group>
                <FormLabel title='Category' required />
                <ReactSelect
                    data={foodCategory}
                    labelName='title'
                    valueName='id'
                    value={values.food_cat_id}
                    onChange={(value) => setFieldValue('food_cat_id', value)}
                />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Food Name' required />
                <ReactInput name='name' />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Food Description' required />
                <ReactInput name='description' as='textarea' rows={3} />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Food Price' required />
                <ReactInput name='price' />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Discounted Price' required />
                <ReactInput name='discounted_price' />
            </Form.Group>

            <BranchWiseFoodForm />

            <Form.Group className='mt-3'>
                <FormLabel title='Food Image' />
                <ReactInput name='image' type='file' />
            </Form.Group>

            <StatusCheckField />
        </Row>
    );
};
export default FoodForm;
