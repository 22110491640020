import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import CeoMessageFormContainer from '../../../components/Forms/Websites/CeoMessage/CeoMessageContainer';
import { formDataConverter } from '../../../utils/necessaryFunctions';

const AddCeoMessage = () => {
    const navigate = useNavigate();

    const addNewTeamMember = async (formValues) => {
        const formData = formDataConverter(formValues);
        return request.create('ceo-message', formData, () => {
            navigate('/dashboard/website/ceo-message');
        });
    };

    return (
        <CardLayout title='Add New CEO Message'>
            <CeoMessageFormContainer apiHandler={addNewTeamMember} />
        </CardLayout>
    );
};

export default AddCeoMessage;
