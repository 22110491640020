import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Tabs } from 'react-bootstrap';
import { Tab } from 'bootstrap';
import CardLayout from '../../components/Layout/CardLayout';
import useFetch from '../../hooks/useFetch';
import request from '../../request/request';
import LoaderLayout from '../../components/Loader/LoaderLayout';
import FoodCourtAndRestaurantDetailsData from '../../components/FoodCourt/FoodCourtAndRestaurantDetailsData';
import FoodList from '../../components/Restaurant/FoodList';
import BranchListShow from '../../components/FoodCourt/BranchListShow';

const RestaurantDetails = () => {
    const { restaurantId } = useParams();

    const [activeTab, setActiveTab] = useState('restaurant_details');

    // fetch product details
    const { result, isLoading } = useFetch(() =>
        request.getOne('restaurants/details', restaurantId)
    );

    return (
        <CardLayout title='Restaurant Details'>
            <Tabs
                defaultActiveKey='restaurant_details'
                variant='tabs'
                onSelect={(name) => setActiveTab(name)}
            >
                <Tab
                    eventKey='restaurant_details'
                    title='Restaurant Details'
                    tabClassName={`${
                        activeTab === 'restaurant_details'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    <LoaderLayout isPending={isLoading}>
                        <FoodCourtAndRestaurantDetailsData detailsData={result} />
                    </LoaderLayout>
                </Tab>

                <Tab
                    eventKey='branches'
                    title='Branches List'
                    tabClassName={`${
                        activeTab === 'branches'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    <BranchListShow
                        url={`branch-list?restaurant_ids=${restaurantId}&status=active`}
                    />
                </Tab>

                <Tab
                    eventKey='food_list'
                    title='Food List'
                    tabClassName={`${
                        activeTab === 'food_list'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    <FoodList />
                </Tab>

                <Tab
                    eventKey='order_list'
                    title='Order List'
                    tabClassName={`${
                        activeTab === 'order_list'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                />

                <Tab
                    eventKey='transection'
                    title='Transection List'
                    tabClassName={`${
                        activeTab === 'transection'
                            ? 'text-primary border-primary  border-bottom-0  '
                            : ''
                    }`}
                >
                    Transection List
                </Tab>
            </Tabs>
        </CardLayout>
    );
};

export default RestaurantDetails;
