import { useNavigate } from 'react-router-dom';
import { IoEyeOutline } from 'react-icons/io5';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useState } from 'react';
import { format } from 'date-fns/format';
import CardLayout from '../../components/Layout/CardLayout';
import ReactDataTable from '../../components/ReactDataTable/ReactDataTable';
import useFetch from '../../hooks/useFetch';
import request from '../../request/request';
import OverlayDropdown from '../../components/dropdown/OverlayDropdown';
import DeleteModal from '../../components/Modal/DeleteModal';
import { statusData } from '../../data/status';
import isValidDate from '../../utils/isValidDate';
import BranchCommissionModal from '../../components/Branch/BranchCommissionModal';
import BranchTableAddModal from '../../components/Branch/BranchTableModal';
import BranchTableDataSearch from './BranchTableDataSearch';

const BranchList = () => {
    const navigate = useNavigate();
    const { result, isLoading, setRefresh } = useFetch(() => request.getAll('branch-list'));

    // modal state

    const [modalState, setModalState] = useState({
        modalName: '',
        selectedItem: '',
    });

    const dropdownItems = [
        {
            id: 1,
            label: 'View',
            icon: <IoEyeOutline color='green' />,
            onClick: (data) => navigate(`/dashboard/branch/details/${data?.branch_id}`),
        },
        {
            id: 3,
            label: 'Edit',
            icon: <FaEdit color='#32bfff' className='me-1' />,
            onClick: (data) => navigate(`/dashboard/branch/edit/${data?.branch_id}`),
        },
        {
            id: 5,
            label: 'Delete',
            icon: <FaTrash color='#e72e2e' className='me-1' />,
            onClick: (data) => setModalState({ modalName: 'delete', selectedItem: data }),
        },
        {
            id: 6,
            label: 'Commission',
            icon: <FaEdit color='#32bfff' className='me-1' />,
            onClick: (data) => setModalState({ modalName: 'commission', selectedItem: data }),
        },
        {
            id: 7,
            label: 'Table',
            icon: <FaEdit color='#32bfff' className='me-1' />,
            onClick: (data) => setModalState({ modalName: 'table', selectedItem: data }),
        },
        {
            id: 8,
            label: 'Payment',
            icon: <FaEdit color='#32bfff' className='me-1' />,
            onClick: (data) => navigate(`/dashboard/mrr-list/edit/${data?.mrr_no}`),
        },
    ];

    const columns = [
        {
            name: 'Srl',
            selector: (row) => row.branch_id,
            wrap: true,
            center: true,
        },
        {
            name: 'Branch Name',
            selector: (row) => row?.name,
            wrap: true,
            center: true,
        },
        {
            name: 'Mobile Number',
            selector: (row) => row?.mobile,
            wrap: true,
            center: true,
        },
        {
            name: 'Contact Person',
            selector: (row) => row?.contact_person,
            wrap: true,
            center: true,
        },
        {
            name: 'Contact Number',
            selector: (row) => row?.contact_number,
            wrap: true,
            center: true,
        },
        // {
        //     name: 'Username',
        //     selector: (row) => row?.user?.f_name,
        //     wrap: true,
        //     center: true,
        // },
        {
            name: 'Commission %',
            cell: (row) => `${row?.commission_rate} %`,
            wrap: true,
            center: true,
        },
        {
            name: 'Wallet',
            selector: (row) => row?.wallet,
            wrap: true,
            center: true,
        },
        {
            name: 'Status',
            selector: (row) => statusData.find((s) => s.id === row.status).title,
            wrap: true,
            center: true,
        },
        {
            name: 'Registered Date',
            selector: (row) =>
                isValidDate(row?.createdAt) && format(row?.createdAt, 'MMMM dd, yyyy'), // Replace with actual date field in your data
            wrap: true,
            center: true,
        },
        {
            name: 'Action',
            className: 'action',
            width: 100,
            align: 'left',
            sortable: false,
            cell: (row) => <OverlayDropdown data={row} dropdownConfig={dropdownItems} />,
        },
    ];

    const handleDelete = async () => {
        await request.delete('branch-list', modalState.selectedItem?.id, () => {
            setRefresh((prevState) => !prevState);
            setModalState({ modalName: '', selectedItem: '' });
        });
    };

    return (
        <CardLayout
            title='Branch List'
            buttonTitle='Add Branch'
            addButtonHandler={() => navigate('/dashboard/branch/add')}
        >
            {/* <BranchTableDataSearch /> */}

            <ReactDataTable columns={columns} data={result} pending={isLoading} />

            {modalState.modalName === 'delete' && (
                <DeleteModal
                    handleDelete={handleDelete}
                    isOpen={modalState.modalName === 'delete'}
                    onClose={() => setModalState({ modalName: '', selectedItem: '' })}
                />
            )}

            {modalState.modalName === 'commission' && (
                <BranchCommissionModal
                    data={modalState?.selectedItem}
                    isOpen={modalState.modalName === 'commission'}
                    onClose={() => setModalState({ modalName: '', selectedItem: '' })}
                    refresh={() => setRefresh((prevState) => !prevState)}
                />
            )}

            {/* branch table  */}

            {modalState.modalName === 'table' && (
                <BranchTableAddModal
                    branchData={modalState?.selectedItem}
                    isOpen={modalState.modalName === 'table'}
                    onClose={() => setModalState({ modalName: '', selectedItem: '' })}
                    refresh={() => setRefresh((prevState) => !prevState)}
                />
            )}
        </CardLayout>
    );
};

export default BranchList;
