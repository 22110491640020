import { FaTrash } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { useState } from 'react';
import CardLayout from '../../components/Layout/CardLayout';
import ReactDataTable from '../../components/ReactDataTable/ReactDataTable';
import useFetch from '../../hooks/useFetch';
import request from '../../request/request';
import DeleteModal from '../../components/Modal/DeleteModal';
import OverlayDropdown from '../../components/dropdown/OverlayDropdown';
import TitleInfo from '../../components/Text/TitleInfo';

const CouponList = () => {
    const navigate = useNavigate();

    const {
        result: couponData,
        isLoading,
        setRefresh,
    } = useFetch(() => request.getAll('coupon?details=true'));
    console.log(couponData);

    // modal state

    const [modalState, setModalState] = useState({
        modalName: '',
        selectedItem: '',
    });

    const dropdownItems = [
        {
            id: 3,
            label: 'Edit',
            icon: <FaEdit color='#32bfff' className='me-1' />,
            onClick: (data) => navigate(`/dashboard/coupon-list/edit/${data?.id}`),
        },
        {
            id: 5,
            label: 'Delete',
            icon: <FaTrash color='#e72e2e' className='me-1' />,
            onClick: (data) => setModalState({ modalName: 'delete', selectedItem: data }),
        },
        {
            id: 8,
            label: 'Applied List',
            icon: <FaEdit color='#32bfff' className='me-1' />,
            // onClick: (data) => navigate(`/dashboard/mrr-list/edit/${data?.mrr_no}`),
        },
    ];

    const columns = [
        {
            name: 'Coupon Code',
            selector: (row) => row?.coupon_code,
            wrap: true,
            center: 'true',
        },
        {
            name: 'Coupon Type',
            selector: (row) => row?.coupon_type,
            wrap: true,
            center: 'true',
        },

        {
            name: 'Coupon value',
            selector: (row) =>
                row?.coupon_type === 'fixed' ? `${row?.value} TK` : `${row?.value}%`,
            wrap: true,
            center: 'true',
        },

        {
            name: 'Applicable for',
            wrap: true,
            // center: 'true',
            cell: (row) => (
                <div>
                    {row?.foodCourt?.length > 0 && (
                        <>
                            <TitleInfo title='Food Court' />
                            <ul>
                                {row.foodCourt?.map((item) => (
                                    <li key={item.id}>{item.name}</li>
                                ))}
                            </ul>
                        </>
                    )}

                    {row?.branchList?.length > 0 && (
                        <>
                            <TitleInfo title='Branch' />
                            <ul>
                                {row.branchList?.map((item) => (
                                    <li key={item.id}>{item.name}</li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            ),
        },

        {
            name: 'Description',
            wrap: true,
            center: true,
            selector: (row) => row?.description,
        },
        {
            name: 'Status',
            selector: (row) => row?.status,
            sortable: 'true',
            center: 'true',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (data) => <span>{data.status === 1 ? 'Active' : 'Inactive'}</span>,
        },
        {
            name: 'Action',
            className: 'action',
            width: 100,
            align: 'left',
            sortable: false,
            cell: (row) => <OverlayDropdown data={row} dropdownConfig={dropdownItems} />,
        },
    ];

    const handleDelete = async () => {
        await request.delete('coupon', modalState.selectedItem?.id, () => {
            setRefresh((prevState) => !prevState);
            setModalState({ modalName: '', selectedItem: '' });
        });
    };

    return (
        <CardLayout
            title='Coupon List'
            buttonTitle='Add Coupon'
            addButtonHandler={() => navigate('/dashboard/coupon-list/add')}
        >
            <ReactDataTable columns={columns} data={couponData} pending={isLoading} />

            {modalState.modalName === 'delete' && (
                <DeleteModal
                    handleDelete={handleDelete}
                    isOpen={modalState.modalName === 'delete'}
                    onClose={() => setModalState({ modalName: '', selectedItem: '' })}
                />
            )}
        </CardLayout>
    );
};

export default CouponList;
