import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import LoaderLayout from '../../../components/Loader/LoaderLayout';
import FooterFormContainer from '../../../components/Forms/Websites/Footer/FooterFormContainer';

const EditFooter = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { result, isLoading } = useFetch(() => request.getOne('footer', id));
    console.log(result);

    const editDataSorting = {
        ...result,
        iconData: result?.social_icons,
        quickLinkData: result?.quick_links,
        bottomData: result.footer_bottom_bars,
    };

    const updateFooter = async (formValues) => {
        try {
            let imageData = null;
            if (formValues.logo?.name) {
                // only uploaded image has name
                const formData = new FormData();

                formData.append('logo', formValues.logo);

                imageData = await request.imageUpload('footer/website/image/upload', formData);
                console.log(imageData);
            }
            formValues.logo =
                imageData?.data?.find((file) => file?.fieldname === 'logo')?.filename || null;

            return request.updateOne('footer', result.id, formValues, () => {
                navigate('/dashboard/website/footer');
            });
        } catch (err) {
            return err;
        }
    };

    return (
        <LoaderLayout isPending={isLoading}>
            <FooterFormContainer apiHandler={updateFooter} editData={editDataSorting} />
        </LoaderLayout>
    );
};

export default EditFooter;
