import MediaGalleryForm from '../../../components/Forms/Websites/MediaGalleryForm/MediaGalleryForm';
import CrudLayout from '../../../components/Layout/CrudLayout';

const PhotoGallery = () => {
    const columns = [
        {
            name: 'Title',
            selector: (row) => row?.title,
            sortable: true,
        },
        {
            name: 'Image',
            wrap: true,
            width: '150px',
            cell: (row) => (
                <img
                    style={{ width: '100%' }}
                    src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.image}`}
                    alt='side Img'
                />
            ),
        },
        {
            name: 'Description',
            wrap: true,
            center: true,
            selector: (row) => row?.description,
        },
        {
            name: 'Status',
            selector: (row) => row?.status,
            sortable: 'true',
            center: 'true',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (data) => <span>{data.status === 1 ? 'Active' : 'Inactive'}</span>,
        },
    ];

    const initialValues = {
        title: '',
        image: '',
        associate_group: 'photo_gallery',
        album_id: '',
        description: '',
        status: 1,
    };

    const config = {
        table_name: 'Photo Gallery List',
        create_modal_title: 'Create New PhotoGallery',
        update_modal_title: 'Update New PhotoGallery',
        link: 'media-gallery',
        getLink: 'media-gallery?group=photo_gallery',
        columns,
        form: <MediaGalleryForm hasAlbum group='photo_gallery' />,
        initialValues,
        hasImage: true,
    };

    return <CrudLayout config={config} />;
};

export default PhotoGallery;
