import React from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-hot-toast';
import { tableCustomStyles, tableConfig } from './config';
import Loader from '../Loader/Loader';

const ReactDataTable = ({ columns, data, pending, ...rest }) => {
    const isArray = Array.isArray(data);

    // if (!isArray) {
    //     toast.error('Your Provided Data is not na Array');
    // }

    return (
        <DataTable
            columns={columns}
            data={isArray ? data : []}
            striped
            highlightOnHover
            responsive
            pagination
            config={tableConfig}
            dense
            progressPending={pending}
            customStyles={tableCustomStyles}
            progressComponent={<Loader />}
            persistTableHead
            {...rest}
        />
    );
};

export default ReactDataTable;
