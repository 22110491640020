import { useNavigate } from 'react-router-dom';
import request from '../../request/request';
import CardLayout from '../../components/Layout/CardLayout';
import BranchFormContainer from '../../components/Forms/Branch/BranchFormContainer';

const AddBranch = () => {
    const navigate = useNavigate();
    const addBranchHandler = (values) =>
        request.create('branch-list', values, () => {
            navigate('/dashboard/branch');
        });
    return (
        <CardLayout title='Add Branch'>
            <BranchFormContainer apiHandler={addBranchHandler} />
        </CardLayout>
    );
};

export default AddBranch;
