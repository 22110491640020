import { RouterProvider } from 'react-router-dom';
import router from './Routers/Router';
import './index.css';
import './index.scss';
import './style/custom.scss';
import 'react-datepicker/dist/react-datepicker.css';

const App = () => (
    <div className='App'>
        <RouterProvider router={router} />
    </div>
);

export default App;
