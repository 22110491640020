import { useFormikContext } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa6';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import DeleteSingleItems from '../../../Modal/DeleteSingleItems';
import ReactDataTable from '../../../ReactDataTable/ReactDataTable';

const BottomBarForm = ({ arrayHelpers }) => {
    const { values, setFieldValue } = useFormikContext();
    const columns = [
        {
            name: 'Title',
            cell: (_, i) => <ReactInput name={`bottomData[${i}.title]`} placeholder='Title' />,
        },

        {
            name: 'Link',
            cell: (_, i) => <ReactInput name={`bottomData[${i}.link]`} placeholder='Link' />,
        },
        {
            name: 'Status',
            center: 'true',
            cell: (row, i) => (
                <Form.Check
                    checked={row?.status === 1}
                    onChange={(e) =>
                        setFieldValue(`bottomData[${i}].status`, e.target.checked ? 1 : 0)
                    }
                />
            ),
        },
        {
            name: 'Remove',
            center: 'true',
            cell: (_, i) => <DeleteSingleItems handleDelete={() => arrayHelpers.remove(i)} />,
        },
    ];

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <h5>Buttom Bar List</h5>
                <Button
                    variant='teal'
                    onClick={() =>
                        arrayHelpers.push({
                            title: '',
                            link: '',
                            status: 1,
                        })
                    }
                >
                    <FaPlus />
                </Button>
            </div>
            <ReactDataTable columns={columns} data={values.bottomData} />
        </div>
    );
};

export default BottomBarForm;
