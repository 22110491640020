import * as yup from 'yup';
import CrudFormLayout from '../../../Layout/CrudFormLayout';
import BlogForms from './BlogForms';

const BlogFormContainer = ({ apiHandler, editData }) => {
    const blogSchema = yup.object({
        title: yup.string().trim().required('Title is required'),
        blog_cat: yup
            .array()
            .of(
                yup.object({
                    label: yup.string().trim(),
                    value: yup.string().trim(),
                })
            )
            .required('Blog Category is required'),
        blog_date: yup.date().default(new Date()),
        blog_img: yup.mixed().optional(),
        short_description: yup
            .string()
            .trim()
            .max(255, 'Sort Description must be less than 256 characters')
            .optional(),
        long_description: yup.string().optional(),
        status: yup.number().oneOf([1, 0], 'Status must be 1 or 0'),
    });

    const initialValue = {
        title: editData?.title || '',
        blog_cat:
            editData?.blog_category?.map((item) => ({
                label: item.title,
                value: item.id,
            })) || [],
        blog_date: editData?.blog_date || new Date(),
        blog_img: editData?.blog_img || '',
        short_description: editData?.short_description || '',
        long_description: editData?.long_description || '',
        status: editData ? editData?.status : 1,
    };
    console.log(editData);

    const formHandler = async (formData) => {
        const validatedData = await blogSchema.validate(formData, {
            stripUnknown: true,
        });
        if (apiHandler) {
            validatedData.blog_cat = validatedData?.blog_cat?.map((item) => item.value);
            return apiHandler(validatedData);
        }
        return validatedData;
    };
    return (
        <CrudFormLayout
            initialValues={initialValue}
            apiHandler={formHandler}
            buttonCenter
            validationSchema={blogSchema}
        >
            <BlogForms />
        </CrudFormLayout>
    );
};

export default BlogFormContainer;
