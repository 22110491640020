import { FieldArray } from 'formik';
import * as Yup from 'yup';
import CrudFormLayout from '../Layout/CrudFormLayout';
import ModalLayout from '../Layout/ModalLayout';
import FoodCourtTableAdd from './FoodCourtTableAdd';
import request from '../../request/request';

const FoodCourtTableAddModal = ({ isOpen, onClose, foodCourtData, refresh }) => {
    console.log(foodCourtData);
    const initalValues = {
        food_court_id: foodCourtData.food_court_id,
        tableData:
            foodCourtData.tableData ||
            [
                // {
                //     table_no: 23,
                //     capacity: 33,
                // },
            ],
    };
    const tableSchema = Yup.object().shape({
        food_court_id: Yup.string().required('Food court id is required'),
        tableData: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number().optional().nullable(),
                    food_court_id: Yup.string().required('Food court id is required'),
                    table_no: Yup.string().required('Table no is required'),
                    capacity: Yup.number().required('Capacity is required'),
                })
            )
            .required('Table data is required'),
    });

    const submitHandler = async (values) =>
        request.create(
            `table-list/food-court/${foodCourtData.food_court_id}`,
            values.tableData,
            () => {
                onClose();
                refresh();
            }
        );

    return (
        <ModalLayout isOpen={isOpen} onClose={onClose} size='lg'>
            <CrudFormLayout
                initialValues={initalValues}
                apiHandler={submitHandler}
                validationSchema={tableSchema}
            >
                <FieldArray
                    name='tableData'
                    render={(arrayHelpers) => <FoodCourtTableAdd arrayHelpers={arrayHelpers} />}
                />
            </CrudFormLayout>
        </ModalLayout>
    );
};

export default FoodCourtTableAddModal;
