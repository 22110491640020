import { FieldArray, useFormikContext } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import FormLabel from '../../../Text/FormLabel';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import ReactTextEditor from '../../../Editors/ReactTextEditor';
import StatusCheck from '../../StatusCheck';
import ReactDatePicker from '../../../ReactDatePicker/ReactDatePicker';
import useFetch from '../../../../hooks/useFetch';
import request from '../../../../request/request';
import ReactMultiSelect from '../../../ReactSelect/ReactMultiSelect';
import ErrorMsg from '../../../Text/ErrorMsg';
import CourseFeaturesForm from './CourseFeaturesForm';
import ReactSelect from '../../../ReactSelect/ReactSelect';

const CourseForms = () => {
    const { values, setFieldValue } = useFormikContext();
    const { result } = useFetch(() => request.getAll('course-category/getData/active'));

    const { result: instructors } = useFetch(() => request.getAll('users/role/3'));

    // console.log(values);
    return (
        <Row>
            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Title' required />
                    <ReactInput name='title' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Course Category' required />
                    <ReactMultiSelect
                        data={result}
                        labelName='title'
                        valueName='id'
                        value={values?.course_cat}
                        defaultValue={values?.course_cat}
                        onChange={(val) =>
                            setFieldValue(
                                'course_cat',
                                val?.map((item) => item)
                            )
                        }
                        // onChange={(val) => console.log(val)}
                        isMulti
                    />
                </Form.Group>
                <ErrorMsg name='course_cat' />
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Instructor' required />
                    <ReactMultiSelect
                        data={instructors}
                        labelName='f_name'
                        valueName='id'
                        value={values?.instructor_id}
                        onChange={(val) => setFieldValue('instructor_id', val?.value)}
                    />
                </Form.Group>
                <ErrorMsg name='instructor_id' />
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Date' required />
                    <ReactDatePicker
                        value={values?.course_date}
                        onChange={(val) => setFieldValue('course_date', val)}
                    />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Duration' required />
                    <ReactInput name='duration' placeholder='ex: days/weeks/months' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Course Image' required />
                    <ReactInput name='img' type='file' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Price' required />
                    <ReactInput name='price' type='number' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Discounted Price' required />
                    <ReactInput name='discounted_price' type='number' />
                </Form.Group>
            </Col>

            <Col sm={12} md={12}>
                <Form.Group className='mt-3'>
                    <FieldArray
                        name='features'
                        render={(arrayHelpers) => (
                            <CourseFeaturesForm arrayHelpers={arrayHelpers} />
                        )}
                    />
                </Form.Group>
            </Col>

            <Col sm={12}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Description' required />
                    <ReactTextEditor
                        defaultData={values?.description}
                        setEditorData={(val) => setFieldValue('description', val)}
                    />
                </Form.Group>
            </Col>

            <div>
                <StatusCheck />
            </div>
        </Row>
    );
};

export default CourseForms;
