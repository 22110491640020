import { useNavigate } from "react-router-dom";
import AboutUsFormContainer from "../../../components/Forms/Websites/AboutUs/AboutUsFormContainer";
import CardLayout from "../../../components/Layout/CardLayout";
import request from "../../../request/request";
import { formDataConverter } from "../../../utils/necessaryFunctions";

const AddAboutUs = () => {
  const navigate = useNavigate();
  const createAboutUs = (formValue) => {
    const formData = formDataConverter(formValue);
    return request.create("about-us", formData, () => {
      navigate("/dashboard/website/about-us");
    });
  };
  return (
    <CardLayout title="Add New About Us ">
      <AboutUsFormContainer apiHandler={createAboutUs} />
    </CardLayout>
  );
};

export default AddAboutUs;
