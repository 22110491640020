import useFetch from '../../hooks/useFetch';
import request from '../../request/request';
import ReactDataTable from '../ReactDataTable/ReactDataTable';

const BranchListShow = ({ url }) => {
    const { result, isLoading } = useFetch(() => request.getAll(url));
    const columns = [
        // {
        //     name: 'Srl',
        //     selector: (row) => row.branch_id,
        //     wrap: true,
        //     center: true,
        // },
        {
            name: 'Name',
            selector: (row) => row?.name,
            wrap: true,
            center: true,
        },
        {
            name: 'Mobile Number',
            selector: (row) => row?.mobile,
            wrap: true,
            center: true,
        },
        {
            name: 'Contact Person',
            selector: (row) => row?.contact_person,
            wrap: true,
            center: true,
        },
        {
            name: 'Contact Number',
            selector: (row) => row?.contact_number,
            wrap: true,
            center: true,
        },
        {
            name: 'Main Restaurant Name',
            selector: (row) => row?.restaurant?.name,
            wrap: true,
            center: true,
        },
    ];

    return (
        <div className='mt-3'>
            <ReactDataTable columns={columns} data={result} pending={isLoading} />
        </div>
    );
};

export default BranchListShow;
