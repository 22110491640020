import { useFormikContext } from 'formik';
import { Row, Button, Col } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa6';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import DeleteSingleItems from '../../../Modal/DeleteSingleItems';
import ReactDataTable from '../../../ReactDataTable/ReactDataTable';

const PageLeftSideContentForm = ({ remove, push }) => {
    const { values } = useFormikContext();
    const columns = [
        {
            name: 'Name',
            center: 'true',
            cell: (_, i) => <ReactInput name={`left_menu[${i}].name`} />,
        },
        {
            name: 'Url',
            center: 'true',
            cell: (_, i) => <ReactInput name={`left_menu[${i}].url`} />,
        },
        {
            name: 'Action',
            center: 'true',
            cell: (_, i) => <DeleteSingleItems handleDelete={() => remove(i)} />,
        },
    ];
    return (
        <div>
            <Row className='my-3'>
                <Col sm={3}>
                    <p>Page Left Menu</p>
                    <Button onClick={() => push({ name: '', url: '' })}>
                        <FaPlus /> Add Menu
                    </Button>
                </Col>
            </Row>
            {values?.left_menu?.length > 0 && (
                <ReactDataTable data={values?.left_menu} columns={columns} />
            )}
        </div>
    );
};

export default PageLeftSideContentForm;
