import { Form } from 'react-bootstrap';
import CrudFormLayout from '../../Layout/CrudFormLayout';
import FormLabel from '../../Text/FormLabel';
import ReactInput from '../../InputList/ReactInput/ReactInput';
import StatusCheck from '../StatusCheck';

const OurServiceForm = ({ apiHandler, editData }) => {
    const initialValues = {
        title: editData?.title || '',
        icon_img: editData?.icon_img || '',
        icon_class: editData?.icon_class || '',
        description: editData?.description || '',
        button_title: editData?.button_title || '',
        status: editData ? editData?.status : 1,
    };

    const formHandler = (formValue) => {
        if (apiHandler) {
            return apiHandler(formValue);
        }

        console.log(formValue);
        return null;
    };
    return (
        <CrudFormLayout initialValues={initialValues} apiHandler={formHandler}>
            <Form.Group className='mt-3'>
                <FormLabel title='Title' required />

                <ReactInput name='title' />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Icon Image' required />

                <ReactInput name='icon_img' type='file' />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Icon class' required />

                <ReactInput name='icon_class' />
            </Form.Group>
            {/**/}
            {/* <Form.Group className='mt-3'> */}
            {/*     <FormLabel title='Button Title' /> */}
            {/**/}
            {/*     <ReactInput name='button_title' /> */}
            {/* </Form.Group> */}

            <Form.Group className='mt-3'>
                <FormLabel title='Description' required />

                <ReactInput name='description' as='textarea' rows={6} />
            </Form.Group>

            <StatusCheck />
        </CrudFormLayout>
    );
};

export default OurServiceForm;
