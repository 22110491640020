import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import useFetch from '../../hooks/useFetch';
import request from '../../request/request';
import LoaderLayout from '../Loader/LoaderLayout';

const FoodCourtTableShow = () => {
    const { foodCourtId } = useParams();

    const { result, isLoading } = useFetch(() =>
        request.getAllById('table-list/food-court', foodCourtId)
    );
    return (
        <LoaderLayout isPending={isLoading}>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Table No</th>
                        <th>Capacity</th>
                    </tr>
                </thead>
                <tbody>
                    {result?.map((item) => (
                        <tr key={item.id}>
                            <td>{item.table_no}</td>
                            <td>{item.capacity}</td>
                            <td style={{ textAlign: 'center', width: '30%' }}>
                                <QRCodeSVG value={item.token} />,
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </LoaderLayout>
    );
};

export default FoodCourtTableShow;
