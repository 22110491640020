import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import CardLayout from '../../../components/Layout/CardLayout';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import EditButton from '../../../components/Button/EditButton';
import DeleteButton from '../../../components/Button/DeleteButton';
import DeleteModal from '../../../components/Modal/DeleteModal';

const ContactMsg = () => {
    const navigate = useNavigate();

    const { result, isLoading, setRefresh } = useFetch(() => request.getAll('contact-message'));

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const columns = [
        {
            name: 'Name',
            wrap: true,
            center: 'true',
            selector: (row) => row?.name,
        },
        {
            name: 'Email Address',
            wrap: true,
            center: 'true',
            selector: (row) => row?.email,
        },
        {
            name: 'Phone Numbers',
            wrap: true,
            center: 'true',
            selector: (row) => row?.phone,
        },
        {
            name: 'Subject',
            wrap: true,
            center: 'true',
            selector: (row) => row?.subject,
        },
        {
            name: 'Message',
            wrap: true,
            center: 'true',
            selector: (row) => row?.message,
        },
        {
            name: 'Action',
            wrap: true,
            center: 'true',
            cell: (row) => (
                <div>
                    {/* <EditButton */}
                    {/*     onClick={() => navigate(`/dashboard/website/contact-us/edit/${row.id}`)} */}
                    {/* /> */}
                    <DeleteButton
                        onClick={() => {
                            setSelectedRow(row);
                            setIsModalOpen(true);
                        }}
                    />
                </div>
            ),
        },
    ];

    const contactDeleteHandler = () =>
        request.delete('contact-message', selectedRow?.id, () => {
            setIsModalOpen(false);
            setSelectedRow(null);
            setRefresh((prev) => !prev);
        });

    return (
        <CardLayout title='Contact Message List'>
            <ReactDataTable columns={columns} data={result} pending={isLoading} />

            {isModalOpen && (
                <DeleteModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                        setSelectedRow(null);
                    }}
                    handleDelete={contactDeleteHandler}
                />
            )}
        </CardLayout>
    );
};

export default ContactMsg;
