import React from 'react';
import DatePicker from 'react-datepicker';

const ReactDatePicker = ({ value, onChange, className, ...rest }) => (
    <div className='customDatePickerWidth' style={{ zIndex: 1000 }}>
        <DatePicker
            className={`form-control ${className}`}
            style={{ width: '100%', zIndex: 1000 }}
            dateFormat='dd-MM-yyyy'
            selected={value ? new Date(value) : new Date()}
            onChange={onChange}
            {...rest}
        />
    </div>
);

export default ReactDatePicker;
