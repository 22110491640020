import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import FooterFormContainer from '../../../components/Forms/Websites/Footer/FooterFormContainer';
import request from '../../../request/request';

const AddFooter = () => {
    const navigate = useNavigate();
    const addNewFooter = async (formValues) => {
        console.log(formValues);
        let imageData = null;
        if (formValues.logo) {
            const formData = new FormData();

            formData.append('logo', formValues.logo);

            imageData = await request.imageUpload('footer/website/image/upload', formData);
            console.log(imageData);
        }
        formValues.logo =
            imageData?.data?.find((file) => file?.fieldname === 'logo')?.filename || null;

        await request.create('footer', formValues, () => {
            navigate('/dashboard/website/footer');
        });
        return null;
    };
    return (
        <CardLayout title='Add Footer'>
            <FooterFormContainer apiHandler={addNewFooter} />
        </CardLayout>
    );
};

export default AddFooter;
