import NameInfoForm from '../../../components/Forms/NameInfoForm';
import CrudLayout from '../../../components/Layout/CrudLayout';

const BlogCategory = () => {
    const columns = [
        {
            name: 'Title',
            selector: (row) => row?.title,
            wrap: true,
            center: 'true',
        },
        {
            name: 'Info',
            wrap: true,
            center: true,
            selector: (row) => row?.info,
        },
        {
            name: 'Status',
            selector: (row) => row?.status,
            sortable: 'true',
            center: 'true',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (data) => <span>{data.status === 1 ? 'Active' : 'Inactive'}</span>,
        },
    ];

    const initialValues = {
        title: '',
        info: '',
        status: 1,
    };

    const config = {
        table_name: 'Blog Category',
        create_modal_title: 'Create New Category',
        update_modal_title: 'Update New Category',
        link: 'blog-category',
        getLink: 'blog-category',
        columns,
        form: <NameInfoForm firstFieldName='title' />,
        initialValues,
    };

    return <CrudLayout config={config} />;
};

export default BlogCategory;
