import React from 'react';
import {Button, Form} from 'react-bootstrap';

const ModuleForm = ({activity, OnClose, apiHandler, editData}) => {

    const formHandler = (event) => {
        event.preventDefault();
        const form = event.target;

        const name = form?.name?.value;
        const info = form?.info?.value;
        const active = form?.active?.checked;

        const activities = activity
            .filter(item => {
                const isChecked = form[item.name].checked
                return isChecked ? isChecked : null;
            }).map(item => item.id)


        const formData = {
            name,
            info,
            status: active ? 1 : 0,
            activities
        }

        apiHandler(formData);

    }

    return (
        <Form onSubmit={formHandler}>
            <Form.Group>
                <Form.Label>Name</Form.Label>
                <
                    Form.Control
                    type={'text'}
                    placeholder={'module Name'}
                    name='name'
                    defaultValue={!!editData ? editData.name : ''}
                    required
                />
            </Form.Group>

            <Form.Group className={'mt-3'}>
                <Form.Label>Info</Form.Label>
                <
                    Form.Control
                    type={'text'}
                    placeholder={'module Info'}
                    name='info'
                    defaultValue={!!editData ? editData.info : ''}
                />
            </Form.Group>

            <Form.Group className='mt-4 d-flex justify-content-end align-items-center'>

                <Form.Check
                    type={'checkbox'}
                    id={'active'}
                    label={'Active'}
                    name='active'
                    defaultChecked={(!!editData && editData.status === 1) || (!editData && true)}
                />
            </Form.Group>

            <Form.Group className='my-3'>
                <Form.Label><h6>Activity List</h6></Form.Label>
                {
                    activity.map((item) => (
                        <
                            Form.Check
                            key={item.id}
                            type={'checkbox'}
                            id={item.name}
                            label={item.name}
                            name={item.name}
                            defaultChecked={editData && editData.activities.find(d => d.id === item.id)}
                        />
                    ))
                }

            </Form.Group>


            <Form.Group className='mt-4 d-flex justify-content-end align-items-center gap-3'>
                <Button type="submit">Save Changes</Button>
                <Button type="reset" variant='secondary' onClick={OnClose}>Close</Button>
            </Form.Group>

        </Form>
    );
};

export default ModuleForm;