import axios from 'axios';
import jwt_decode from 'jwt-decode';
import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import useFetch from '../hooks/useFetch';
import request from '../request/request';

// export UserContext for access--------
export const UserContext = createContext();

const UserContextAPI = ({ children }) => {
    const [currentUser, setCurrentUser] = useState({});
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(true);

    // edit data for form

    const [editData, setEditData] = useState({});

    // console.log(currentUser);
    // console.log(permissions);

    const { result: setting } = useFetch(() => request.getAll('settings'));
    const settingData = setting?.find((item) => item.id === 1);

    useEffect(() => {
        if (settingData !== undefined) {
            document.title = `${settingData?.website_name}`;
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = `${process.env.REACT_APP_UPLOAD_URL}/${settingData?.fav_image}`;
        }
    }, [settingData]);

    const methodSignin = async (signinData) => {
        const res = await request.create('user/signin', signinData);
        window.localStorage.setItem('jwtToken', res.data.token);
        setCurrentUser(res.data.userQueryData.userQuery);
        setPermissions(res.data.userQueryData.permissions);
        return res;
    };

    const accessPerm = (moduleId, activityId) => {
        const moduleActivitys = permissions?.find(
            (moduleActivity) =>
                moduleActivity.module_id === moduleId && moduleActivity.activity_id === activityId
        );
        // console.log(moduleActivitys);
        if (moduleActivitys) {
            return true;
        }
        return false;
    };

    const modulePerm = (moduleId) => {
        const modules = permissions?.find((module) => module.module_id === moduleId);
        console.log(modules);
        if (modules) {
            return true;
        }
        return false;
    };

    const fetchAPI = async (id) => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/user/with-permissions/${id}`
            );
            // console.log(res);
            // console.log(res.data.data);
            const data = res?.data?.data;
            console.log(res.data);
            if (res.data.status === 'OK') {
                setLoading(false);
                setCurrentUser(data.userData);
                setPermissions(data?.permissions);
            } else {
                toast.error('user not found');
            }
            // console.log(data);
        } catch (error) {
            console.error(error);
            // console.error(error?.response?.data?.message);
            toast.error(error?.response?.data?.message);
        }
    };

    const smsAPI = async (mobileNumber, message) => {
        try {
            const res = await axios.post(
                `https://api.greenweb.com.bd/api.php?token=${process.env.REACT_APP_SMS_API_TOKEN}&to=${mobileNumber}&message=${message}`
            );
            if (res.status === 200) {
                return res;
            }
        } catch (error) {
            console.error(error);
            console.error(error?.response?.data?.message);
            toast.error(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        const token = window.localStorage.getItem('jwtToken');

        if (token) {
            const decoded = jwt_decode(token);
            const { id } = decoded;
            fetchAPI(id);
            // console.log(decoded);
        } else {
            console.log('No Token Found');
        }
    }, []);

    const methods = {
        currentUser,
        setCurrentUser,
        accessPerm,
        modulePerm,
        smsAPI,
        loading,
        setLoading,
        methodSignin,
        editData,
        setEditData,
        settingData,
    };
    return <UserContext.Provider value={methods}>{children}</UserContext.Provider>;
};

export default UserContextAPI;
