import React from 'react';
import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../../components/Layout/CardLayout';
import UserFormContainer from '../../../../components/Forms/Users/UserFormContainer';
import request from '../../../../request/request';
import useContextData from '../../../../hooks/useContextData';

const AddNewUser = () => {
    const { currentUser } = useContextData();

    const navigate = useNavigate();

    const createNewUserHandler = async (formValues) => {
        const { image, signature } = formValues;

        let pro_image = null;

        // image upload
        if (image) {
            console.log(typeof image);
            const formData = new FormData();
            formData.append('image', image);

            console.log(formData);

            const { data, status } = await request.create('users/user/upload-image', formData);

            if (status === 'OK') {
                pro_image = data.filename;
            }

            console.log(data, status);
        }

        const uploadData = {
            ...formValues,
            image: pro_image,
            password: '123456',
            created_by: currentUser?.id,
        };

        await request.create('user/signup', uploadData, () => {
            navigate('/dashboard/users');
        });

        console.log('final Object', uploadData);
        return null;
    };

    return (
        <CardLayout title='Add User'>
            <UserFormContainer apiHandler={createNewUserHandler} />
        </CardLayout>
    );
};

export default AddNewUser;
