import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import { formDataConverter } from '../../../utils/necessaryFunctions';
import CoursesFormContainer from '../../../components/Forms/Websites/Courses/CourseFormContainer';

const AddCourse = () => {
    const navigate = useNavigate();
    const addCourseHandler = (formValues) => {
        const formData = formDataConverter(formValues);
        return request.create('courses', formData, () => {
            navigate('/dashboard/website/courses');
        });
    };

    return (
        <CardLayout title='Add New Course'>
            <CoursesFormContainer apiHandler={addCourseHandler} />
        </CardLayout>
    );
};

export default AddCourse;
