import * as yup from "yup";
import CrudFormLayout from "../../../Layout/CrudFormLayout";
import TeamMembersAddForm from "./TeamMembersAddForm";

const TeamMembersContainer = ({ apiHandler, editData }) => {
  const teamMembersSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    title: yup.string().required("Title is required"),
    mobile: yup.string(),
    email: yup.string().email("use valid email").optional().nullable(),
    address: yup.string().optional().nullable(),
    description: yup.string().optional().nullable(),
    image: yup.mixed().optional(),
    order_no: yup.number().required("Order No. is required"),
    status: yup
      .number()
      .oneOf([0, 1], "Status must be 0 or 1")
      .required("Status is required"),
    iconData: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().optional(),
          title: yup.string().required("Title is required"),
          icon: yup.string().required("Icon is required"),
          link: yup.string().required("Link is required"),
        }),
      )
      .optional(), // Ensure iconData is required
  });

  const initialValues = {
    name: "",
    title: "",
    mobile: "",
    email: "",
    address: "",
    description: "",
    image: "",
    order_no: "",
    status: 1,
    iconData: [],
  };

  const formHandler = async (formVales) => {
    const validatedData = await teamMembersSchema.validate(formVales, {
      stripUnknown: true,
    });

    if (apiHandler) {
      return apiHandler(validatedData);
    }

    // console.log(formVales);

    return null;
  };

  return (
    <CrudFormLayout
      initialValues={editData || initialValues}
      apiHandler={formHandler}
      buttonCenter
      validationSchema={teamMembersSchema}
    >
      <TeamMembersAddForm />
    </CrudFormLayout>
  );
};

export default TeamMembersContainer;
