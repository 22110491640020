import { Col, Row, Table } from 'react-bootstrap';
import { format } from 'date-fns/format';
import TitleInfo from '../Text/TitleInfo';
import { statusData } from '../../data/status';
import isValidDate from '../../utils/isValidDate';

const BranchDetailsData = ({ detailsData }) => (
    <Row>
        <Col sm={12} lg={6}>
            <Table striped bordered hover className='mt-3'>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <TitleInfo title={`  Name`} />
                        </td>
                        <td>
                            <TitleInfo description={detailsData?.name} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='Mobile Number' />
                        </td>
                        <td>
                            <TitleInfo description={detailsData?.mobile} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='Food Court' />
                        </td>
                        <td>
                            <TitleInfo description={detailsData?.food_court?.name} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='Restaurant' />
                        </td>
                        <td>
                            <TitleInfo description={detailsData?.restaurant?.name} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='Contact Person Name' />
                        </td>
                        <td>
                            <TitleInfo description={detailsData?.contact_person} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='Contact Person Number' />
                        </td>
                        <td>
                            <TitleInfo description={detailsData?.contact_number} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='Wallet' />
                        </td>
                        <td>
                            <TitleInfo description={detailsData?.wallet} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='Commission' />
                        </td>
                        <td>
                            <TitleInfo description={`${detailsData?.commission_rate} %`} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='Credit Limit' />
                        </td>
                        <td>
                            <TitleInfo description={`${detailsData?.credit_limit} TK`} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Col>

        <Col sm={12} lg={6}>
            <Table striped bordered hover className='mt-3'>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <TitleInfo title='Country' />
                        </td>
                        <td>
                            <TitleInfo description={detailsData?.country?.name} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='State' />
                        </td>
                        <td>
                            <TitleInfo description={detailsData?.state?.name} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='City' />
                        </td>
                        <td>
                            <TitleInfo description={detailsData?.city?.name} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='Address' />
                        </td>
                        <td>
                            <TitleInfo description={detailsData?.address} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='Status' />
                        </td>
                        <td>
                            <TitleInfo
                                description={
                                    statusData.find((s) => s.id === detailsData?.status)?.title
                                }
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <TitleInfo title='Registration Date' />
                        </td>
                        <td>
                            <TitleInfo
                                description={
                                    isValidDate(detailsData?.createdAt) &&
                                    format(detailsData?.createdAt, 'MMMM dd, yyyy')
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Col>
    </Row>
);

export default BranchDetailsData;
