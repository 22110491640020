import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CardLayout from "../../../components/Layout/CardLayout";
import ReactDataTable from "../../../components/ReactDataTable/ReactDataTable";
import useFetch from "../../../hooks/useFetch";
import request from "../../../request/request";
import EditButton from "../../../components/Button/EditButton";
import DeleteButton from "../../../components/Button/DeleteButton";
import DeleteModal from "../../../components/Modal/DeleteModal";

const AboutUs = () => {
  const { result, isLoading, setRefresh } = useFetch(() =>
    request.getAll("about-us"),
  );
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState("");
  const [selectedItem, setSelectedItem] = useState("");

  const columns = [
    {
      name: "Title",
      wrap: true,
      selector: (row) => row?.title,
    },
    {
      name: "Side Img",
      wrap: true,
      width: "150px",
      cell: (row) => (
        <img
          style={{ width: "100%" }}
          src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.side_img}`}
          alt="side Img"
        />
      ),
    },
    {
      name: "Middle Image",
      wrap: true,
      width: "150px",
      cell: (row) => (
        <img
          style={{ width: "100%" }}
          src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.middle_img}`}
          alt="side Img"
        />
      ),
    },
    {
      name: "Cirle Image",
      wrap: true,
      width: "150px",
      cell: (row) => (
        <img
          style={{ width: "100%" }}
          src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.circle_img}`}
          alt="side Img"
        />
      ),
    },
    {
      name: "Description",
      wrap: true,
      cell: (row) => (
        <div dangerouslySetInnerHTML={{ __html: row?.description }} />
      ),
    },
    {
      name: "Button Title",
      center: "true",
      cell: (row) => row?.button_title,
    },
    {
      name: "Button Link",
      center: "true",
      cell: (row) => row?.button_link,
    },
    {
      name: "Status",
      center: "true",
      selector: (row) => (row?.status === 1 ? "Active" : "InActive"),
    },
    {
      name: "Action",
      center: "true",
      cell: (row) => (
        <div>
          <EditButton
            onClick={() =>
              navigate(`/dashboard/website/about-us/edit/${row?.id}`)
            }
          />
          <DeleteButton
            onClick={() => {
              setModalOpen("delete");
              setSelectedItem(row);
            }}
          />
        </div>
      ),
    },
  ];

  // delete function

  const abotUsDeleteHandler = () =>
    request.delete("about-us", selectedItem?.id, () => {
      setModalOpen("");
      setRefresh((prev) => !prev);
    });

  return (
    <CardLayout
      title="About Us List"
      buttonTitle="Add New About us"
      addButtonHandler={() => navigate("/dashboard/website/about-us/add")}
    >
      <ReactDataTable columns={columns} data={result} pending={isLoading} />

      {/* delete about us */}

      {modalOpen === "delete" && (
        <DeleteModal
          isOpen={modalOpen === "delete"}
          onClose={() => setModalOpen("")}
          handleDelete={abotUsDeleteHandler}
        />
      )}
    </CardLayout>
  );
};

export default AboutUs;
