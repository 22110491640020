// eslint-disable-next-line import/prefer-default-export
export const statusData = [
    {
        id: 0,
        title: 'Inactive',
    },
    {
        id: 1,
        title: 'Active',
    },
];
