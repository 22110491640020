import { Form, Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa6';
import { useFormikContext } from 'formik';
import ReactDataTable from '../../../ReactDataTable/ReactDataTable';
import DeleteSingleItems from '../../../Modal/DeleteSingleItems';
import ReactInput from '../../../InputList/ReactInput/ReactInput';

const CourseFeaturesForm = ({ arrayHelpers }) => {
    const { values } = useFormikContext();

    const columns = [
        {
            name: 'Title',
            cell: (_, i) => <ReactInput name={`features[${i}]`} />,
        },

        {
            name: 'Remove',
            center: 'true',
            width: '100px',
            cell: (_, i) => <DeleteSingleItems handleDelete={() => arrayHelpers.remove(i)} />,
        },
    ];

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <h5>Features List</h5>
                <Button variant='teal' onClick={() => arrayHelpers.push('')}>
                    <FaPlus />
                </Button>
            </div>
            <ReactDataTable columns={columns} data={values.features} />
        </div>
    );
};

export default CourseFeaturesForm;
