import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import request from '../../request/request';
import ReactDataTable from '../../components/ReactDataTable/ReactDataTable';
import CardLayout from '../../components/Layout/CardLayout';

const FoodListByFoodCourt = () => {
    const { foodCourtId } = useParams();

    const { result: foodList, isLoading } = useFetch(
        () => request.getAllById(`food-list/food-court`, foodCourtId),
        foodCourtId
    );

    const columns = [
        {
            name: 'Food Name',
            selector: (row) => row?.name,
            wrap: true,
            center: 'true',
        },
        {
            name: 'Food Category',
            selector: (row) => row?.food_category?.title,
            wrap: true,
            center: 'true',
        },

        {
            name: 'Image',
            wrap: true,
            width: '150px',
            cell: (row) => (
                <img
                    style={{ width: '100%' }}
                    src={`${process.env.REACT_APP_UPLOAD_URL}/food/${row?.image}`}
                    alt='side Img'
                />
            ),
        },
        {
            name: 'Description',
            wrap: true,
            center: true,
            selector: (row) => row?.description,
        },
        {
            name: 'Status',
            selector: (row) => row?.status,
            sortable: 'true',
            center: 'true',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (data) => <span>{data.status === 1 ? 'Active' : 'Inactive'}</span>,
        },
    ];
    return (
        <CardLayout title='Food List'>
            <ReactDataTable columns={columns} data={foodList} pending={isLoading} />
        </CardLayout>
    );
};

export default FoodListByFoodCourt;
