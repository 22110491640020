import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { IoPrintOutline } from 'react-icons/io5';
import { FaTrash } from 'react-icons/fa6';
import { format } from 'date-fns';
import PasswordChangeModal from '../../../components/Modal/PasswordChangeModal/PasswordChangeModal';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import OverlayDropdown from '../../../components/dropdown/OverlayDropdown';
import DeleteModal from '../../../components/Modal/DeleteModal';
import request from '../../../request/request';
import ModalLayout from '../../../components/Layout/ModalLayout';

const UserTable = ({ users, isLoading, setRefresh }) => {
    const navigate = useNavigate();

    const [selectedItem, setSelectedItem] = useState({ modalOpen: '', selectedItem: '' });

    const handlerOnDelete = async () => {
        await request.delete('users/user', selectedItem.selectedItem, () => {
            setSelectedItem({ isModalOpen: false, selectedItem: '' });
            setRefresh((prev) => !prev);
        });
    };

    // console.log(users);

    const actionItems = [
        {
            id: 1,
            label: 'View',
            icon: <IoPrintOutline color='#ffcb32' />,
            onClick: (data) => navigate(`/dashboard/profile/${data.id}`),
        },
        {
            id: 2,
            label: 'Password Change',
            icon: <IoPrintOutline color='#ffcb32' />,
            onClick: (data) => setSelectedItem({ modalOpen: 'password', selectedItem: data }),
        },
        {
            id: 3,
            label: 'Edit',
            icon: <FaEdit color='#32bfff' />,
            onClick: (data) => navigate(`/dashboard/users/edit-user/${data?.id}`),
        },

        {
            id: 4,
            label: 'Delete',
            icon: <FaTrash color='#e72e2e' />,
            onClick: (data) => setSelectedItem({ modalOpen: 'delete', selectedItem: data?.id }),
        },
    ];

    // console.log(users);

    const columns = [
        {
            name: 'Image',
            className: 'image',
            width: '80px',
            center: 'true',
            sortable: true,
            cell: (data) => (
                <div>
                    {data?.image ? (
                        <img
                            className=' img-fluid'
                            style={{ width: '100%' }}
                            src={`${process.env.REACT_APP_UPLOAD_URL}/users/${data?.image}`}
                            // src={`${process.env.REACT_APP_UPLOAD_URL}/users/${data?.image}`}
                            alt='Profile Pic'
                        />
                    ) : (
                        <img
                            className=' img-fluid'
                            style={{ width: '100%' }}
                            src='./dummy.jpg'
                            alt='Profile Pic'
                        />
                    )}
                </div>
            ),
        },
        {
            name: 'Reg No',
            center: 'true',
            wrap: true,
            sortable: true,
            cell: (data) => data?.registration_no,
        },
        {
            name: 'Name',
            center: 'true',
            wrap: true,
            cell: (data) => (
                <>
                    {data?.f_name} {data?.l_name}
                </>
            ),
        },
        {
            name: 'Role',
            center: 'true',
            wrap: true,
            cell: (data) => data?.role?.name,
        },
        {
            name: 'Mobile',
            center: 'true',
            wrap: true,
            cell: (data) => data?.mobile,
        },
        {
            name: 'Email',
            center: 'true',
            wrap: true,
            cell: (data) => data?.email,
        },
        {
            name: 'Date of Birth',
            width: '130px',
            center: 'true',
            wrap: true,
            cell: (data) =>
                !Number.isNaN(Date.parse(data?.date_of_birth)) &&
                format(new Date(data?.date_of_birth), 'MMMM dd, yyyy'),
        },
        {
            name: 'Contact Person',
            width: '150px',
            center: 'true',
            wrap: true,
            cell: (data) => data?.contact_person,
        },
        {
            name: 'NID Number',
            center: 'true',
            wrap: true,
            cell: (data) => data?.nid,
        },
        {
            name: 'Credit Limit',
            center: 'true',
            wrap: true,
            cell: (data) => <>BDT {data?.credit_limit}</>,
        },
        {
            name: 'Commission Rate',
            center: 'true',
            wrap: true,
            cell: (data) => <>{data?.commission_rate}%</>,
        },
        {
            name: 'Wallet',
            center: 'true',
            wrap: true,
            cell: (data) => data?.wallet,
        },

        {
            name: 'Status',
            className: 'status',
            center: 'true',
            width: '100px',
            sortable: true,
            cell: (data) => (data?.status === 1 ? 'Active' : 'Inactive'),
        },
        {
            key: 'action',
            name: 'Action',
            width: '120px',
            center: 'true',
            sortable: false,
            cell: (data) => <OverlayDropdown data={data} dropdownConfig={actionItems} />,
        },
    ];

    return (
        <>
            <ReactDataTable data={users} columns={columns} pending={isLoading} />

            {/* Delete Modal Body */}

            {selectedItem.modalOpen === 'delete' && (
                <DeleteModal
                    handleDelete={handlerOnDelete}
                    isOpen={selectedItem.modalOpen === 'delete'}
                    onClose={() => setSelectedItem({ isModalOpen: false, selectedItem: '' })}
                />
            )}

            {selectedItem.modalOpen === 'password' && (
                <ModalLayout
                    title='Change Password'
                    isOpen={selectedItem.modalOpen === 'password'}
                    onClose={() => setSelectedItem({ isModalOpen: false, selectedItem: '' })}
                >
                    <PasswordChangeModal
                        userData={selectedItem.selectedItem}
                        onClose={() => setSelectedItem({ isModalOpen: false, selectedItem: '' })}
                    />
                </ModalLayout>
            )}
        </>
    );
};

export default UserTable;
