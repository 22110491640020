import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { ErrorMessage, useField } from 'formik';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ReactSelect from '../../ReactSelect/ReactSelect';

const SelectFoodCourt = ({ isMulti = false, ...props }) => {
    // eslint-disable-next-line no-unused-vars
    const [field, _, helper] = useField(props);

    const { result } = useFetch(() => request.getAll(`food-court?status=active`));

    return (
        <>
            <InputGroup>
                <ReactSelect
                    data={result}
                    value={field?.value}
                    valueName='food_court_id'
                    labelName='name'
                    onChange={(val) => helper.setValue(val)}
                    isMulti={isMulti}
                    {...props}
                />
            </InputGroup>

            <ErrorMessage name={props?.name} component='p' className='error' />
        </>
    );
};

export default SelectFoodCourt;
