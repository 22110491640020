import { useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import FormLabel from '../../Text/FormLabel';
import ReactInput from '../../InputList/ReactInput/ReactInput';
import ReactSelect from '../../ReactSelect/ReactSelect';
import SelectFoodCourt from '../../InputList/SelectableInput/SelectFoodCourt';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import StatusCheckField from '../StatusCheckField';
import ErrorMsg from '../../Text/ErrorMsg';

const convertToString = (value) => {
    if (Array.isArray(value)) {
        return value.join(',');
    }
    return value;
};

const CouponForm = () => {
    const { values, setFieldValue } = useFormikContext();
    const branchUrl = `branch-list?food_court_ids=${convertToString(values.food_court_ids) || ''}`;
    // console.log(branchUrl);
    // console.log(values.food_court_ids);
    const couponTypeData = [
        {
            label: 'Fixed',
            value: 'fixed',
        },
        {
            label: 'Percentage',
            value: 'percentage',
        },
    ];

    const { result: foodCourtList, isLoading: foodCourtDataLoading } = useFetch(() =>
        request.getAll('food-court')
    );

    // get branch data based on food court
    const { result: branchList, isLoading: branchDataLoading } = useFetch(
        () => request.getAll(branchUrl),
        branchUrl
    );
    return (
        <Row>
            <Col sm={12} className='mt-3'>
                <FormLabel title='Coupon Code' required />
                <ReactInput name='coupon_code' />
            </Col>

            <Col sm={12} className='mt-3'>
                <FormLabel title='Coupon Type' required />
                <ReactSelect
                    data={couponTypeData}
                    labelName='label'
                    valueName='value'
                    value={values.coupon_type}
                    onChange={(value) => setFieldValue('coupon_type', value)}
                />
                <ErrorMsg name='coupon_type' />
            </Col>

            <Col sm={12} className='mt-3'>
                <FormLabel title='Coupon Value' required />
                <ReactInput name='value' />
            </Col>

            <Col sm={12} className='mt-3'>
                <FormLabel title='Food Court' isLoading={foodCourtDataLoading} />
                <ReactSelect
                    data={foodCourtList}
                    valueName='food_court_id'
                    labelName='name'
                    value={values.food_court_ids}
                    defaultValue={values.default_food_court_data}
                    onChange={(value) => setFieldValue('food_court_ids', value)}
                    isMulti
                />
                <ErrorMsg name='food_court_ids' />
            </Col>

            <Col sm={12} className='mt-3'>
                <FormLabel title='Branch List' isLoading={branchDataLoading} />
                <ReactSelect
                    data={branchList}
                    labelName='name'
                    valueName='branch_id'
                    value={values.branch_ids}
                    defaultValue={values.default_branch_data}
                    onChange={(value) => setFieldValue('branch_ids', value)}
                    isMulti
                />
                <ErrorMsg name='branch_ids' />
            </Col>

            <Col sm={12} className='mt-3'>
                <FormLabel title='Details' />
                <ReactInput name='description' as='textarea' rows={3} />
            </Col>
            <Col sm={12} className='mt-3'>
                <StatusCheckField />
            </Col>
        </Row>
    );
};

export default CouponForm;
