import CrudFormLayout from '../../../Layout/CrudFormLayout';
import CeoMessageForm from './CeoMessageForm';

const CeoMessageFormContainer = ({ apiHandler, editData }) => {
    const initialValues = {
        name: '',
        title: '',
        images: '',
        message: '',
        status: 1,
    };

    const formHandler = (formVales) => {
        if (apiHandler) {
            return apiHandler(formVales);
        }

        console.log(formVales);

        return null;
    };

    return (
        <CrudFormLayout
            initialValues={editData || initialValues}
            apiHandler={formHandler}
            buttonCenter
        >
            <CeoMessageForm />
        </CrudFormLayout>
    );
};

export default CeoMessageFormContainer;
