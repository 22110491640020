import React from 'react';
import { Button } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa6';

const DetailsButton = ({ onClick }) => (
    <Button className='me-3 mt-2' onClick={onClick} variant='info' size='sm'>
        <FaEye />
    </Button>
);

export default DetailsButton;
