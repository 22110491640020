import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import ContactAddFromContainer from '../../../components/Forms/Websites/Contact/ContactAddFromContainer';
import request from '../../../request/request';

const AddContact = () => {
    const navigate = useNavigate();

    const addContact = (formValues) =>
        request.create('contact', formValues, () => {
            navigate('/dashboard/website/contact');
        });
    return (
        <CardLayout title='Add Contact'>
            <ContactAddFromContainer apiHandler={addContact} />
        </CardLayout>
    );
};

export default AddContact;
