import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import request from '../../request/request';
import useFetch from '../../hooks/useFetch';
import ReactDataTable from '../ReactDataTable/ReactDataTable';

function calculateTotalBranchAmount(data) {
    return data.reduce((total, order) => {
        // Remove any leading or trailing spaces from branchAmount and check for a sign
        const branchAmountString = order.branchAmount.replace(/\s/g, '');
        const branchAmount = parseFloat(branchAmountString);

        // Add or subtract based on the sign in branchAmount
        if (branchAmountString.startsWith('-')) {
            return total - Math.abs(branchAmount);
        }
        if (branchAmountString.startsWith('+')) {
            return total + Math.abs(branchAmount);
        }
        return total + branchAmount; // Default to adding if no sign is present
    }, 0);
}

const BranchTransection = () => {
    const { id: branchId } = useParams();

    const { result, isLoading, error } = useFetch(() =>
        request.getAllById('transection/branch', branchId)
    );

    const column = [
        {
            name: 'OrderId',
            selector: (row) => row.orderId,
            wrap: true,
            center: true,
        },

        {
            name: 'Total Amount',
            selector: (row) => row.totalPrice,
            wrap: true,
            center: true,
        },
        {
            name: 'Refund Amount',
            selector: (row) => row.refundAmount,
            wrap: true,
            center: true,
        },

        {
            name: 'Received Amount',
            selector: (row) => row.recivedAmount,
            wrap: true,
            center: true,
        },
        {
            name: 'Payment Method',
            selector: (row) => row.paymentMethod,
            wrap: true,
            center: true,
        },
        {
            name: 'Branch Amount',
            selector: (row) => row.branchAmount,
            wrap: true,
            center: true,
        },
    ];

    return (
        <section>
            <ReactDataTable columns={column} data={result} pending={isLoading} />

            <div className='text-end mt-3'>
                <h4>Total Branch Amount: {calculateTotalBranchAmount(result)} TK</h4>
            </div>
        </section>
    );
};
export default BranchTransection;
