import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserTable from '../UserTable';
import CardLayout from '../../../../components/Layout/CardLayout';
import useFetch from '../../../../hooks/useFetch';
import request from '../../../../request/request';

const UserByRoles = () => {
    const { role_id } = useParams();
    const navigate = useNavigate();

    const {
        result: users,
        isLoading,
        setRefresh,
    } = useFetch(() => request.getAllById('users/role', role_id), role_id);
    const { result: role } = useFetch(() => request.getOne('roles/role', role_id), role_id);

    return (
        <div>
            <CardLayout
                title={`${role?.name || 'User'} List`}
                buttonTitle={`Add New ${role?.name}`}
                addButtonHandler={() => navigate(`/dashboard/user/roles/add/${role_id}`)}
            >
                <UserTable users={users} isLoading={isLoading} setRefresh={setRefresh} />
            </CardLayout>
        </div>
    );
};
export default UserByRoles;
