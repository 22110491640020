import { TailSpin } from 'react-loader-spinner';

const Loader = ({ isCenter, style }) => (
    <div
        className={`d-flex justify-content-center mt-5 ${isCenter && 'align-items-center'} ${style} `}
        style={{ height: '100vh' }}
    >
        <TailSpin
            height='80'
            width='80'
            color='#0e918a'
            ariaLabel='tail-spin-loading'
            radius='1'
            wrapperStyle={{}}
            wrapperClass=''
            visible
        />
    </div>
);

export default Loader;

