import { useNavigate, useParams } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import TeamMembersContainer from '../../../components/Forms/Websites/TeamMembers/TeamMembersContainer';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import LoaderLayout from '../../../components/Loader/LoaderLayout';
import MissionVisionMottoFormContainer from '../../../components/Forms/Websites/MissionVissionMotto/MissionVisionMottoFormContainer';

const EditMissionVisionMotto = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { result, isLoading } = useFetch(() => request.getOne('mission-vision-motto', id), id);

    const updateMember = async (formValues) => {
        try {
            return request.updateOne('mission-vision-motto', result.id, formValues, () => {
                navigate('/dashboard/website/mission-vision-motto');
            });
        } catch (err) {
            return err;
        }
    };

    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Edit Mission, Vision & Motto'>
                <MissionVisionMottoFormContainer apiHandler={updateMember} editData={result} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default EditMissionVisionMotto;
