import React from 'react';
import { Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import CrudFormLayout from '../../Layout/CrudFormLayout';
import UserBasicInfoForms from './UserBasicInfoForms';
import UserAdditionalInfoForm from './UserAdditionalInfoForm';
import asyncFuncHandler from '../../../utils/asyncFuncHandler';

const UserFormContainer = ({ apiHandler, editData }) => {
    const { role_id } = useParams();

    const numberTransform = (value) => (Number.isNaN(value) ? 0 : value);
    const stringTransform = (value) => (Number.isNaN(value) ? null : value);

    const userSchema = Yup.object().shape({
        role_id: Yup.number().required('Role is Required'),
        f_name: Yup.string().required('Name is Required'),
        l_name: Yup.string().optional().nullable(),
        company_name: Yup.string(),
        email: Yup.string().email('Invalid email').required('Email is Required'),
        mobile: Yup.string().required('Mobile is Required'),
        address_1: Yup.string(),
        date_of_birth: Yup.date().nullable().optional(),
        nid: Yup.string().nullable().transform(stringTransform),
        credit_limit: Yup.number().typeError('Not a valid number').nullable(),
        commission_rate: Yup.number().typeError('Not a valid number').nullable(),
        remarks: Yup.string().nullable().transform(stringTransform),
        contact_person: Yup.string().nullable().transform(stringTransform),
        user_details: Yup.object().shape({
            country_id: Yup.number().nullable().transform(numberTransform),
            state_id: Yup.number().nullable().transform(numberTransform),
            city_id: Yup.number().nullable().transform(numberTransform),
            postal_code: Yup.string(),
            TIN_no: Yup.string(),
            BIN_No: Yup.string(),
            group_name: Yup.string(),
        }),
        image: Yup.mixed(),
    });

    const initialValues = {
        role_id: editData ? editData?.role_id : +role_id || '',
        f_name: editData?.f_name || '',
        company_name: editData?.company_name || '',
        email: editData?.email || '',
        mobile: editData?.mobile || '',
        image: editData?.image || '',
        date_of_birth: editData?.date_of_birth || new Date(),
        address_1: editData?.address_1 || '',
        nid: editData?.nid || '',
        credit_limit: editData?.credit_limit || 0,
        commission_rate: editData?.commission_rate || 0,
        remarks: editData?.remarks || '',
        contact_person: editData?.contact_person || '',
        user_details: {
            country_id: editData?.user_detail?.country_id || 0,
            state_id: editData?.user_detail?.state_id || 0,
            city_id: editData?.user_detail?.city_id || 0,
            postal_code: editData?.user_detail?.postal_code || '',
        },
    };

    const handleFormSubmit = async (formData) => {
        await asyncFuncHandler(async () => {
            const validateData = await userSchema.validate(formData, { stripUnknown: true });
            if (apiHandler) {
                return apiHandler(validateData);
            }
            return validateData;
        }, true);
    };

    return (
        <CrudFormLayout
            initialValues={initialValues}
            buttonCenter
            apiHandler={handleFormSubmit}
            validationSchema={userSchema}
        >
            <BaseUserForm />
        </CrudFormLayout>
    );
};

export default UserFormContainer;

const BaseUserForm = () => (
    <Row>
        <Col sm={12} md={6}>
            <UserBasicInfoForms />
        </Col>
        <Col sm={12} md={6}>
            <UserAdditionalInfoForm />
        </Col>
    </Row>
);
