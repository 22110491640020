import { useNavigate } from 'react-router-dom';
import request from '../../request/request';
import CardLayout from '../../components/Layout/CardLayout';
import FoodCourtAndRestaurantFormContainer from '../../components/Forms/FoodCourtAndRestaurant/FoodCourtAndRestaurantFormContainer';

const AddFoodCourt = () => {
    const navigate = useNavigate();
    const addFoodHandler = (values) =>
        request.create('food-court', values, () => {
            navigate('/dashboard/food-court');
        });
    return (
        <CardLayout title='Add Food Court'>
            <FoodCourtAndRestaurantFormContainer apiHandler={addFoodHandler} form='foodCourt' />
        </CardLayout>
    );
};

export default AddFoodCourt;
