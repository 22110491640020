import { Col, Form, Row } from "react-bootstrap";
import { FieldArray, useFormikContext } from "formik";
import ReactInput from "../../../InputList/ReactInput/ReactInput";
import FormLabel from "../../../Text/FormLabel";
import IconListAdd from "../Footer/IconListAdd";
import StatusCheck from "../../StatusCheck";
import ReactTextEditor from "../../../Editors/ReactTextEditor";

const TeamMembersAddForm = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <div>
      <Row>
        <Col sm={12} md={6} className="mt-3">
          <Form.Group>
            <FormLabel title="Name" />
            <ReactInput name="name" />
          </Form.Group>
        </Col>

        <Col sm={12} md={6} className="mt-3">
          <Form.Group>
            <FormLabel title="Title" />
            <ReactInput name="title" />
          </Form.Group>
        </Col>

        <Col sm={12} md={6} className="mt-3">
          <Form.Group>
            <FormLabel title="Mobile No" />
            <ReactInput name="mobile" />
          </Form.Group>
        </Col>

        <Col sm={12} md={6} className="mt-3">
          <Form.Group>
            <FormLabel title="Email" />
            <ReactInput name="email" type="email" />
          </Form.Group>
        </Col>

        <Col sm={12} md={6} className="mt-3">
          <Form.Group>
            <FormLabel title="Address" />
            <ReactInput name="address" as="textarea" rows={2} />
          </Form.Group>
        </Col>

        <Col sm={12} md={6} className="mt-3">
          <Form.Group>
            <FormLabel title="Image" />
            <ReactInput name="image" type="file" accept="image/*" />
          </Form.Group>
        </Col>

        <Col sm={12} md={6} className="mt-3">
          <Form.Group>
            <FormLabel title="Order No" />
            <ReactInput name="order_no" />
          </Form.Group>
        </Col>
      </Row>

      <Col sm={12} md={12} className="mt-3">
        <Form.Group>
          <FormLabel title="Description" />
          <ReactTextEditor
            defaultData={values?.description}
            setEditorData={(val) => setFieldValue("description", val)}
          />
        </Form.Group>
      </Col>

      <Row className="mt-3">
        <Col sm={12} className="mt-3">
          <FieldArray
            name="iconData"
            render={(arrayHelpers) => (
              <IconListAdd arrayHelpers={arrayHelpers} />
            )}
          />
        </Col>

        <StatusCheck />
      </Row>
    </div>
  );
};

export default TeamMembersAddForm;
