import { useNavigate } from 'react-router-dom';
import TeamMembersContainer from '../../../components/Forms/Websites/TeamMembers/TeamMembersContainer';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';

const AddTeamMembers = () => {
    const navigate = useNavigate();

    const addNewTeamMember = async (formValues) => {
        console.log(formValues);
        let imageData = null;
        if (formValues.image) {
            const formData = new FormData();

            formData.append('image', formValues.image);

            imageData = await request.imageUpload('footer/website/image/upload', formData);
            console.log(imageData);
        }
        formValues.image =
            imageData?.data?.find((file) => file?.fieldname === 'image')?.filename || null;

        await request.create('team-members', formValues, () => {
            navigate('/dashboard/website/team-members');
        });
        return null;
    };

    return (
        <CardLayout title='Add New Team Member'>
            <TeamMembersContainer apiHandler={addNewTeamMember} />
        </CardLayout>
    );
};

export default AddTeamMembers;
