import CrudFormLayout from '../../../Layout/CrudFormLayout';
import ContactFroms from './ContactFroms';

const ContactAddFromContainer = ({ apiHandler, editData }) => {
    console.log('editData', editData);
    const initialValues = {
        location: editData?.location || '',
        email_address: editData?.email_address || '',
        phone_numbers: editData?.phone_numbers || '',
        google_map_link: editData?.google_map_link || '',
        status: editData?.status === 0 ? 0 : 1,
    };

    const formHanler = (formValues) => {
        if (apiHandler) return apiHandler(formValues);
        return null;
    };
    return (
        <CrudFormLayout initialValues={initialValues} apiHandler={formHanler} buttonCenter>
            <ContactFroms />
        </CrudFormLayout>
    );
};

export default ContactAddFromContainer;
