import * as yup from 'yup';
import { Row } from 'react-bootstrap';
import { FieldArray } from 'formik';
import CrudFormLayout from '../../../Layout/CrudFormLayout';
import PagesForm from './PagesForm';
import PageLeftSideContentForm from './PageLeftSideContentForm';
import StatusCheck from '../../StatusCheck';

const PagesFormContainer = ({ apiHandler, editData }) => {
    const pageSchema = yup.object().shape({
        title: yup.string().required('Title is required'),
        url: yup.string().required('Url is required'),
        page_banner: yup.mixed().optional(),
        content: yup.string().required('Content is required'),
        left_menu: yup
            .array()
            .of(
                yup.object().shape({
                    name: yup.string().required('Name is required'),
                    url: yup.string().required('Url is required'),
                })
            )
            .optional(),
        status: yup.number().default(1),
    });

    const initialValues = {
        title: editData?.title || '',
        url: editData?.url || '',
        page_banner: editData?.page_banner || '',
        content: editData?.content || '',
        status: editData ? editData.status : 1,
        left_menu:
            editData?.left_menu ||
            [
                // {
                //     name: '',
                //     url: '',
                // },
            ],
    };

    const formDataHandler = async (formValue) => {
        const validatedData = await pageSchema.validate(formValue, {
            stripUnknown: true,
        });
        if (apiHandler) {
            apiHandler(validatedData);
        }
        return validatedData;
    };
    return (
        <CrudFormLayout
            initialValues={initialValues}
            validationSchema={pageSchema}
            buttonCenter
            apiHandler={formDataHandler}
        >
            <PagesForm />

            <Row className='mt-3'>
                <FieldArray
                    name='left_menu'
                    render={(arrayHelpers) => (
                        <PageLeftSideContentForm
                            remove={arrayHelpers.remove}
                            push={arrayHelpers.push}
                        />
                    )}
                />
            </Row>
            <Row>
                <StatusCheck />
            </Row>
        </CrudFormLayout>
    );
};

export default PagesFormContainer;
